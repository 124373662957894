import { useEffect, useState } from 'react';

import api from 'lib/api';

const useArtwork = (id) => {
	const [artwork, setArtWork] = useState(null);

	useEffect(() => {
		if (!id) return;

		(async () => {
			try {
				const {
					response: {
						data: { data },
					},
				} = await api.get(`/artworks/${id}/`);

				const { response } = await api.get(
					`/artworks/${id}/order-exists/`,
				);

				setArtWork({
					...data,
					isOrderExists: response.data.data.isOrderExists,
				});
			} catch (e) {
				setArtWork(null);
			}
		})();
	}, [id]);

	return artwork;
};
export default useArtwork;
