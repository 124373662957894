import { useEffect, useState } from 'react';
// import { sortBy } from 'lodash';

import API from 'lib/api';

const useArtist = (id) => {
	const [artist, setArtist] = useState(null);

	useEffect(() => {
		if (!id) return;

		(async () => {
			try {
				const {
					response: {
						data: { data },
					},
				} = await API.get(`/artists/${id}/`);
				setArtist(data);
			} catch (e) {
				setArtist(null);
			}
		})();
	}, []);

	return artist;
};
export default useArtist;
