import { createAction, handleActions } from 'redux-actions';
import { takeLatest, call } from 'redux-saga/effects';

// import { createActionTypes, createRequestSaga } from '../lib/redux';
// import * as AuthService from '../service/auth';
import * as storage from '../lib/storage';

export const UNMOUNT = 'auth/UNMOUNT';
export const TIMEOUT_LOGOUT = 'auth/TIMEOUT_LOGOUT';
// export const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] =
// 	createActionTypes('auth/LOGIN');
// export const [USER, USER_SUCCESS, USER_FAILURE] =
// 	createActionTypes('auth/USER');
// export const login = createAction(LOGIN, (payload) => payload);
// export const getUser = createAction(USER, (payload) => payload);
export const unmount = createAction(UNMOUNT);
export const timeoutLogout = createAction(TIMEOUT_LOGOUT);
// const loginSaga = createRequestSaga(LOGIN, AuthService.login);
// const userSaga = createRequestSaga(USER, AuthService.getUser);

function* timeoutLogoutSaga() {
	yield call(storage.logout);
}

export function* authSaga() {
	// yield takeLatest(LOGIN, loginSaga);
	// yield takeLatest(USER, userSaga);
	yield takeLatest(TIMEOUT_LOGOUT, timeoutLogoutSaga);
}

const initialState = {
	user: undefined,
	data: undefined,
	error: undefined,
};

const auth = handleActions(
	{
		// [LOGIN_SUCCESS]: (state, { payload: payload }) => ({
		// 	...state,
		// 	...payload,
		// 	error: null,
		// }),
		// [LOGIN_FAILURE]: (state, { error }) => ({
		// 	...state,
		// 	error: error,
		// }),
		// [USER_SUCCESS]: (state, { payload: payload }) => ({
		// 	...state,
		// 	...payload,
		// 	error: null,
		// }),
		// [USER_FAILURE]: (state, { error }) => ({
		// 	...state,
		// 	error: error,
		// }),
		[UNMOUNT]: () => initialState,
		[TIMEOUT_LOGOUT]: (state) => initialState,
	},
	initialState, // 초기값
);

export default auth;
