import React from 'react';
import styled from 'styled-components';
import { Link as _Link } from 'react-router-dom';
import LOGO from 'assets/images/piecial_logo.png';

const Footer = () => {
	return (
		<Container>
			<FooterContainer>
				<LogoSVG />
				<LinkContainer>
					<Link to='/about'>ABOUT</Link>
					<Link to='/notice'>NOTICE</Link>
					<Link to='/terms'>이용약관</Link>
					<Link to='/policy'>개인정보처리방침</Link>
				</LinkContainer>

				<PCInfo>
					<p>
						고객센터 : 오전 10시 ~ 오후 5시(점심시간,주말,공휴일
						제외) | piecial@impactstation.kr | 02-6385-8104
					</p>
					<p>
						임팩트스테이션 주식회사 | 대표자 : 송상훈 |
						사업자등록번호 : 683-86-00599
						<br />
						통신판매업 신고 : 019-서울서대문-0717 | 주소 :
						서울특별시 중구 청계천로 100, 9층 960호
					</p>
				</PCInfo>
				<MobileInfo>
					<p>
						고객센터 : 오전 10시 ~ 오후 5시(점심시간,주말,공휴일
						제외)
						<br />
						piecial@impactstation.kr | 02-6385-8104
					</p>
					<p>
						임팩트스테이션 주식회사 | 대표자 : 송상훈
						<br />
						사업자등록번호 : 683-86-00599
						<br />
						통신판매업 신고 : 019-서울서대문-0717
						<br />
						주소 : 서울특별시 중구 청계천로 100, 9층 960호
					</p>
				</MobileInfo>
				<CopyRight>
					Copyright impactstation All Rights Reserved.
				</CopyRight>
			</FooterContainer>
		</Container>
	);
};
const Container = styled.footer`
	height: 368px;
	padding: 47px 0;

	${(p) => p.theme.device.md} {
		width: 100%;
		height: 407px;
		padding-top: 24px;
		padding-bottom: 30px;
	}
`;
const FooterContainer = styled.div`
	width: 1200px;
	margin: 0 auto;
	${(props) => props.theme.device.md} {
		width: 100%;
		padding: 0 16px;
	}
`;
const PCInfo = styled.div`
	line-height: 21px;
	font-weight: 400;
	font-size: 18px;
	letter-spacing: -0.36px;
	color: #666666;

	& > p + p {
		margin-top: 38px;
	}
	${(props) => props.theme.device.md} {
		width: 100%;
		display: none;
	}
`;
const MobileInfo = styled.div`
	display: none;
	line-height: 19px;
	font-weight: 300;
	font-size: 13px;
	letter-spacing: -0.13px;

	& > p + p {
		margin-top: 20px;
	}
	${(props) => props.theme.device.md} {
		display: block;
		width: 100%;
	}
`;

const LogoSVG = styled.div`
	width: 133px;
	height: 31px;
	background-image: url(${LOGO});
	background-size: 133px 31px;
	background-repeat: no-repeat;

	${(p) => p.theme.device.md} {
		width: 115px;
		height: 26px;
		background-size: 115px 26px;
	}
`;
const LinkContainer = styled.div`
	margin-top: 33px;
	margin-bottom: 38px;
	/* a {
		color: #333;
		margin-right: 25px;
		text-decoration: none;
	}
	a:last-child {
		margin-right: 0;
	}
	a:visited {
		color: #333;
	} */

	${(p) => p.theme.device.md} {
		margin-top: 25px;
		margin-bottom: 23px;
	}
`;
const Link = styled(_Link)`
	line-height: 25px;
	font-weight: 500;
	font-size: 21px;
	letter-spacing: -0.21px;
	color: #000000;
	& + & {
		margin-left: 40px;
	}

	${(props) => props.theme.device.md} {
		line-height: 16px;
		font-weight: 600;
		font-size: 13px;
		letter-spacing: -0.13px;

		& + & {
			margin-left: 23px;
		}
	}
`;
const CopyRight = styled.div`
	margin-top: 23px;
	line-height: 21px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: -0.36px;
	color: #999999;

	${(p) => p.theme.device.md} {
		margin-top: 20px;
		line-height: 16px;
		font-weight: 300;
		font-size: 13px;
		letter-spacing: -0.13px;
	}
`;
export default Footer;
