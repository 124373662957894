import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Spin } from 'antd';

const IndicatorWraper = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 9999;
`;

const Dimm = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #333333;
	opacity: 0.35;
`;

const Indicator = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -16px 0 0 -16px;
`;

const Loading = () => {
	const { isLoading } = useSelector(({ loading }) => ({
		isLoading: loading.isLoading,
	}));
	return (
		<>
			{isLoading && (
				<IndicatorWraper>
					<Dimm />
					<Indicator>
						<Spin />
					</Indicator>
				</IndicatorWraper>
			)}
		</>
	);
};

export default Loading;
