import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Modal } from 'antd';

import API from 'lib/api';
import * as storage from 'lib/storage';
import { numberWithCommas } from 'lib/util';
import dayjs from 'dayjs';

import Artwork from './Artwork';
import { Section } from 'components/Checkout';
import { useEffect, useState } from 'react';

const Success = () => {
	const history = useHistory();
	const search = useLocation().search;
	const impUid = new URLSearchParams(search).get('imp_uid');
	const payment = storage.getPayment();
	const {
		name,
		paymentMethod,
		discountedPrice,
		artworkInfo: work,
		orderLines,
	} = payment;
	const [showVbankInfo, setShowVbankInfo] = useState(false);

	// const vbankDate = localStorage.getItem('vbank_date');
	// const vbankHolder = localStorage.getItem('vbank_holder');
	// const vbankName = localStorage.getItem('vbank_name');
	// const vbankNum = localStorage.getItem('vbank_num');

	const [vbankDate, setVbankDate] = useState(
		localStorage.getItem('vbank_date'),
	);
	const [vbankHolder, setVbankHolder] = useState(
		localStorage.getItem('vbank_holder'),
	);
	const [vbankName, setVbankName] = useState(
		localStorage.getItem('vbank_name'),
	);
	const [vbankNum, setVbankNum] = useState(localStorage.getItem('vbank_num'));

	useEffect(() => {
		if (impUid) {
			(async () => {
				try {
					const {
						response: {
							data: {
								vbankName,
								vbankHolder,
								vbankNum,
								vbankDate,
							},
						},
					} = await API.get(`/orders/${impUid}/`);
					setVbankDate(vbankDate);
					setVbankHolder(vbankHolder);
					setVbankName(vbankName);
					setVbankNum(vbankNum);

					setShowVbankInfo(true);
				} catch (e) {
					console.log(impUid + e);
				}
			})();
		} else {
			if (!!localStorage.getItem('vbank_date')) {
				setShowVbankInfo(true);
			}
		}
	}, []);

	return (
		<SuccessContainer>
			<TitleContainer>결제완료</TitleContainer>
			<Description>
				{name}님,
				<br />
				결제가 완료되었습니다.
			</Description>

			<Artwork work={work} />

			<Section label='구매자 정보' style={{ borderBottom: 'none' }}>
				<ColumnContainer>
					<InfoItem>
						<span className='label'>결제일시</span>
						<span className='bold'>
							{dayjs().format('YYYY.MM.DD HH:mm:ss')}
						</span>
					</InfoItem>
					<InfoItem>
						<span className='label'>결제수단</span>
						<span className='bold'>
							{paymentMethod === 'card' ? '신용카드' : '계좌이체'}
						</span>
					</InfoItem>
					<PCPaymentHistory>
						<InfoItem>
							<span className='label'>결제금액</span>
							<span className='bold'>작품(할인 포함)</span>
						</InfoItem>
						<div className='price-row'>
							<div>{orderLines.amount}개</div>
							<div className='price'>
								{numberWithCommas(discountedPrice)}원
							</div>
						</div>
					</PCPaymentHistory>
				</ColumnContainer>
			</Section>

			<MobilePaymentHistory>
				<Section label='결제 내역'>
					<div className='type'>작품(할인 포함)</div>
					<div className='price-row'>
						<div>{orderLines.amount}개</div>
						<div className='price'>
							{numberWithCommas(discountedPrice)}원
						</div>
					</div>
				</Section>
			</MobilePaymentHistory>

			<Notice>
				* 결제 완료 후 작품 수령까지 일부 시간이 발생할 수 있습니다.
			</Notice>
			<ButtonContainer>
				<button onClick={() => history.push('/mypage')}>
					구매 내역
				</button>
			</ButtonContainer>
			{/* </ColumnContainer> */}

			<VbankInfoModal
				width={1200}
				visible={paymentMethod === 'vbank' && showVbankInfo}
			>
				<div className='title'>주문 완료</div>
				<p>
					아래 가상계좌로 입금해 주시면 정상적으로 결제 완료 처리가
					됩니다.
				</p>
				<hr />
				<label>계좌 정보</label>
				<div>
					- {vbankName} {vbankNum}
					<br /> - 예금주 : {vbankHolder}
				</div>
				<label>결제 금액</label>
				<div>{numberWithCommas(discountedPrice)}원</div>
				<label>입금 기간</label>
				<div>{vbankDate} 까지</div>

				<ButtonContainer>
					<button
						onClick={() => {
							localStorage.removeItem('vbank_date');
							localStorage.removeItem('vbank_holder');
							localStorage.removeItem('vbank_name');
							localStorage.removeItem('vbank_num');
							setShowVbankInfo(false);
						}}
					>
						닫기
					</button>
				</ButtonContainer>
			</VbankInfoModal>
		</SuccessContainer>
	);
};
const SuccessContainer = styled.div`
	width: 1200px;
	margin-top: 40px;
	${(props) => props.theme.device.md} {
		width: 100%;
		padding: 0 16px;
		margin-top: 0;
	}
`;
const TitleContainer = styled.div`
	font: normal normal 400 22px/26px Pretendard;
	letter-spacing: -0.11px;
	color: #666666;
	${(props) => props.theme.device.md} {
		display: none;
	}
`;
const Description = styled.div`
	font: normal normal bold 37px/56px Pretendard;
	letter-spacing: -0.37px;
	color: #111111;
	margin-bottom: 85px;
	${(props) => props.theme.device.md} {
		font: normal normal bold 24px/35px Pretendard;
		letter-spacing: -0.24px;
		color: #1a1a1a;
		margin-bottom: 37px;
		margin-top: 35px;
	}
`;
const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
const InfoItem = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: ${(props) =>
		props.justify ? 'space-between' : 'flex-start'};
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
	span {
		font-size: 15px;
	}
	span.label {
		font: normal normal 500 20px/24px Pretendard;
		letter-spacing: -0.2px;
		color: #111111;
	}
	span.bold {
		font: normal normal 400 18px/24px Pretendard;
		letter-spacing: 0px;
		color: #666666;
		margin-left: 32px;
	}
	${(props) => props.theme.device.md} {
		margin-bottom: 10px;

		span.label {
			font: normal normal 500 16px/19px Pretendard;
			letter-spacing: -0.16px;
			color: #bebebe;
		}
		span.bold {
			font: normal normal 500 16px/19px Pretendard;
			letter-spacing: -0.16px;
			color: #1a1a1a;
		}
	}
`;
const Notice = styled.div`
	display: none;
	${(props) => props.theme.device.md} {
		display: block;
		font: normal normal 500 14px/17px Pretendard;
		letter-spacing: -0.14px;
		color: #777777;
		margin-bottom: 24px;
	}
`;
// 결제하기 버튼
const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 50px 0;

	${(props) => props.theme.device.md} {
		margin: 24px 0;
		padding-bottom: 126px;
	}

	& > button {
		width: 384px;
		height: 72px;
		background: #242424 0% 0% no-repeat padding-box;
		border-radius: 10px;
		font: normal normal 600 22px/26px Pretendard;
		letter-spacing: -0.55px;
		color: #ffffff;

		${(props) => props.theme.device.md} {
			width: 366px;
			height: 47px;
			background: #1a1a1a 0% 0% no-repeat padding-box;
			border-radius: 10px;
			font: normal normal 400 17px/20px Pretendard;
			letter-spacing: -0.17px;
			color: #ffffff;
		}
	}
`;
const PCPaymentHistory = styled.div`
	display: block;
	& > .price-row {
		display: flex;
		justify-content: space-between;
		border-top: 1px dashed #bebebe;
		padding-top: 48px;
		margin-top: 50px;
		font: normal normal 500 22px/26px Pretendard;
		letter-spacing: -0.44px;
		color: #111111;

		& > .price {
			font: normal normal bold 24px/29px Pretendard;
			letter-spacing: -0.48px;
			color: #111111;
		}
	}
	${(props) => props.theme.device.md} {
		display: none;
	}
`;
const MobilePaymentHistory = styled.div`
	display: none;
	${(props) => props.theme.device.md} {
		display: block;

		& > section > div {
			margin-top: 25px;
			border: 1px solid #bebebe;
			border-radius: 10px;
			padding: 26px 24px;
		}

		.price-row {
			display: flex;
			justify-content: flex-end;
			font: normal normal 500 15px/24px Pretendard;
			letter-spacing: -0.15px;
			color: #777777;

			.price {
				font: normal normal bold 20px/24px Pretendard;
				letter-spacing: -0.2px;
				color: #1a1a1a;
				margin-left: 11px;
			}
		}
	}
`;
const VbankInfoModal = styled(Modal)`
	.ant-modal-content {
		width: 1200px;
		height: 894px;
		background: #ffffff;
		border-radius: 10px;

		${(props) => props.theme.device.md} {
			width: 366px;
			height: 536px;
		}

		.ant-modal-close,
		.ant-modal-header,
		.ant-modal-footer {
			display: none;
		}
		.ant-modal-body {
			padding: 80px;
			${(props) => props.theme.device.md} {
				padding: 42px 28px;
			}

			div.title {
				font: normal normal bold 44px/53px Pretendard;
				letter-spacing: -0.88px;
				color: #111111;
				margin-bottom: 24px;

				${(props) => props.theme.device.md} {
					font: normal normal bold 24px/29px Pretendard;
					letter-spacing: -0.24px;
					color: #1a1a1a;
					margin-bottom: 12px;
				}
			}
			p {
				font: normal normal 400 24px/29px Pretendard;
				letter-spacing: -0.48px;
				color: #000000;

				${(props) => props.theme.device.md} {
					font: normal normal 400 14px/24px Pretendard;
					letter-spacing: -0.14px;
					color: #1a1a1a;
				}
			}
			hr {
				margin: 56px 0;
				color: #bebebe;

				${(props) => props.theme.device.md} {
					margin: 28px 0;
				}
			}

			label {
				display: block;
				font: normal normal 600 28px/34px Pretendard;
				letter-spacing: -0.28px;
				color: #000000;
				margin-bottom: 20px;

				${(props) => props.theme.device.md} {
					font: normal normal 600 17px/20px Pretendard;
					letter-spacing: -0.17px;
					color: #000000;
					margin-bottom: 12px;
				}
			}

			div {
				font: normal normal 400 20px/34px Pretendard;
				letter-spacing: -0.2px;
				color: #777777;
				margin-bottom: 48px;
				${(props) => props.theme.device.md} {
					font: normal normal 400 14px/24px Pretendard;
					letter-spacing: -0.14px;
					color: #777777;
					margin-bottom: 29px;
				}
			}

			button {
				width: 316px;
			}
		}
	}
`;
export default Success;
