import styled from 'styled-components';
import { Section } from 'components/Checkout';

const Buyer = ({ user }) => (
	<Section label='구매자 정보'>
		<Item>
			<label>이름</label>
			<span>{user.name}</span>
		</Item>
		<Item>
			<label>닉네임</label>
			<span>{user.nickname}</span>
		</Item>
		<Item>
			<label>이메일</label>
			<span>{user.username}</span>
		</Item>
		<Item>
			<label>휴대전화</label>
			<span>{user.phoneNumber}</span>
		</Item>
	</Section>
);
export default Buyer;

const Item = styled.div`
	line-height: 24px;
	font-size: 18px;
	letter-spacing: 0px;
	color: #666666;

	& > label {
		display: inline-block;
		width: 110px;
		font-weight: 500;
		font-size: 20px;
		letter-spacing: -0.2px;
		color: #111111;
	}

	& + & {
		margin-top: 25px;
	}

	${(p) => p.theme.device.md} {
		line-height: 19px;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: -0.16px;
		color: #1a1a1a;
		padding-left: 8px;
		& > label {
			width: 95px;
			font-size: 16px;
			letter-spacing: -0.16px;
			color: #bebebe;
		}
		& + & {
			margin-top: 15px;
		}
	}
`;
