import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import List from 'components/MyPage/ArtworkList';
import NoHistory from 'components/MyPage/NoHistory';
import Nav from 'components/MyPage/Nav';
// import useFetch from 'lib/hooks/useFetch';
import api from 'lib/api';
import { numberWithCommas } from 'lib/util';
import { useCallback } from 'react';

const SalesHistory = () => {
	const [histories, setHistories] = useState([]);

	const fetchSellerOrders = useCallback(async () => {
		const {
			response: {
				data: { data },
			},
		} = await api.get(`/seller-orders/`);
		setHistories(data);
	}, []);

	useEffect(() => {
		fetchSellerOrders();
	}, [fetchSellerOrders]);

	return (
		<Nav>
			<List>
				{histories?.length > 0 ? (
					histories.map(
						({
							status,
							artworkInfo: work,
							discountedPrice,
							paymentMethod,
							orderLines,
						}) => {
							return (
								<List.Artwork>
									{/* <MobileWorkStatus>{d.status}</MobileWorkStatus> */}
									<List.Thumbnail>
										<List.Status
											$isActive={/완료/.test(status)}
										>
											{status}
										</List.Status>
										<List.Thumbnail.Image
											src={work.thumbnail}
											alt={work.name}
										/>
									</List.Thumbnail>

									<List.Description>
										<List.Description.Header>
											<List.Description.Header.Category>
												{work.category}
											</List.Description.Header.Category>
											<List.Description.Header.Title>
												{work.name}
											</List.Description.Header.Title>
										</List.Description.Header>

										<List.Description.Body>
											<List.Description.Body.AuthorBox>
												<List.Description.OwnerRow>
													<List.Description.Label>
														Owned by
													</List.Description.Label>
													{work.ownerNickname}
												</List.Description.OwnerRow>
												<List.Description.OwnerRow>
													<List.Description.Label>
														Created by
													</List.Description.Label>
													{work.artistName}
												</List.Description.OwnerRow>
											</List.Description.Body.AuthorBox>

											<List.Description.Body.PaymentBox>
												<List.Description.Row>
													<List.Description.Label>
														결제일시
													</List.Description.Label>
													{orderLines.paymentedAt}
												</List.Description.Row>
												<List.Description.Row>
													<List.Description.Label>
														결제수단
													</List.Description.Label>
													{paymentMethod === 'card'
														? '카드'
														: '계좌이체'}
												</List.Description.Row>
												<List.Description.Row>
													<List.Description.Label>
														결제 상품
													</List.Description.Label>
													{`${
														orderLines.selected
													} X ${
														orderLines.amount || 1
													}개`}
												</List.Description.Row>
											</List.Description.Body.PaymentBox>
										</List.Description.Body>

										<List.Description.MobileFooter>
											<List.Description.Row className='sell-type'>
												{orderLines.selected}
											</List.Description.Row>
											<List.Description.Row className='amount-price'>
												<span className='amount'>
													{orderLines.amount || 1}개
												</span>
												<div className='price'>
													{numberWithCommas(
														discountedPrice,
													)}
													원
												</div>
											</List.Description.Row>
										</List.Description.MobileFooter>
									</List.Description>
								</List.Artwork>
							);
						},
					)
				) : (
					<NoHistory content='아직 판매한 작품이 없군요.' />
				)}
			</List>

			<Divider />
			<TotalPrice>
				<label>총 판매 금액</label>
				<span>
					{histories.length > 0
						? histories
								.reduce((a, b) => a + b.discountedPrice, 0)
								.toLocaleString()
						: 0}
					원
				</span>
			</TotalPrice>
		</Nav>
	);
};

export default SalesHistory;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	border-top: 1px dashed #bebebe;
`;
const TotalPrice = styled.div`
	width: 100%;
	margin-top: 47px;
	text-align: right;
	line-height: 41px;
	font-weight: bold;
	font-size: 34px;
	letter-spacing: -0.85px;
	color: #111111;
	margin-bottom: 252px;

	label {
		font-weight: 500;
		font-size: 22px;
		letter-spacing: -0.55px;
		color: #777777;
		margin-right: 59px;
	}

	${(props) => props.theme.device.md} {
		display: flex;
		flex-direction: column;
		height: 111px;
		background: #f8f9fa;
		border-radius: 10px;
		line-height: 27px;
		font-size: 23px;
		letter-spacing: -0.23px;
		color: #1a1a1a;
		padding: 29px 24px;
		label {
			margin-right: 0px;
			line-height: 18px;
			font-weight: 600;
			font-size: 15px;
			letter-spacing: -0.15px;
			color: #1a1a1a;
			margin-bottom: 8px;
		}
	}
`;
