import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Pagination as _Pagination } from 'antd';

import { Wrapper } from 'components/Notice/StyledComps';
import { Header, NoticeRow, SearchBar } from 'components/Notice';
import useNotices from 'hooks/useNotices';

const Notice = () => {
	const history = useHistory();
	const [searchType, setSearchType] = useState('');
	const [search, setSearch] = useState('');
	const pageSize = 20;
	const [page, setPage] = useState({
		current: 1,
		totalPage: 0,
		minIndex: 0,
		maxIndex: 0,
	});

	const notices = useNotices(searchType, search);

	const moveToDetail = (id) => history.push(`/notice/detail?id=${id}`);

	const handlePagination = (page) => {
		setPage({
			...page,
			current: page,
			minIndex: (page - 1) * pageSize,
			maxIndex: page * pageSize,
		});
	};

	return (
		<Wrapper>
			<Header />
			<PCSearchBarWrapper>
				<SearchBar
					search={search}
					handleSearch={(searchType, search) => {
						setSearchType(searchType);
						setSearch(search);
					}}
				/>
			</PCSearchBarWrapper>

			{notices.length > 0 && (
				<>
					<NoticeContainer>
						{notices.map((notice) => (
							<NoticeRow
								key={notice.id}
								{...notice}
								handleClick={moveToDetail}
							/>
						))}
					</NoticeContainer>

					<PaginationContainer>
						<Pagination
							onChange={handlePagination}
							defaultCurrent={page}
							pageSize={20}
							total={notices.length}
						/>
					</PaginationContainer>
				</>
			)}

			<MobileSearchBarWrapper>
				<SearchBar
					search={search}
					handleSearch={(searchType, search) => {
						setSearchType(searchType);
						setSearch(search);
					}}
				/>
			</MobileSearchBarWrapper>
		</Wrapper>
	);
};
export default Notice;

const NoticeContainer = styled.section`
	margin-top: 25px;
`;
const PaginationContainer = styled.div`
	width: 100%;
	display: flex;
	margin: 60px 0;
	margin-bottom: 253px;
	justify-content: center;
	align-items: center;

	${(p) => p.theme.device.md} {
		margin-bottom: 45px;
	}
`;
const Pagination = styled(_Pagination)`
	.ant-pagination-disabled .ant-pagination-item-link,
	.ant-pagination-item {
		border: none;
	}

	.ant-pagination-item {
		font: normal normal 500 22px/32px Pretendard;
		letter-spacing: -0.55px;
		color: #bebebe;

		${(p) => p.theme.device.md} {
			font: normal normal 400 16px/32px Pretendard;
			letter-spacing: -0.16px;
			color: #777777;
		}
	}

	.ant-pagination-item-active a {
		color: #000000;
		font: normal normal 600 22px/32px Pretendard;
	}
`;

const MobileSearchBarWrapper = styled.div`
	display: none;
	${(p) => p.theme.device.md} {
		display: block;
		margin-bottom: 128px;
	}
`;
const PCSearchBarWrapper = styled.div`
	display: block;
	${(p) => p.theme.device.md} {
		display: none;
	}
`;
