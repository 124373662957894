import { useHistory } from 'react-router-dom';
import { Drawer as _Drawer } from 'antd';
import styled from 'styled-components';
import { ReactComponent as _CloseSVG } from 'assets/svg/close.svg';
import { isAuth } from 'lib/storage';

const useSideMenu = (closeSideMenu) => {
	const userExist = isAuth();
	const history = useHistory();

	return {
		userExist,
		goAbout: () => {
			history.push('/about');
			closeSideMenu();
		},
		goCreate: () => {
			userExist ? history.push('/registration') : history.push('/login');
			closeSideMenu();
		},
		goNotice: () => {
			history.push('/notice');
			closeSideMenu();
		},
		goLogin: () => {
			history.push('login');
			closeSideMenu();
		},
	};
};

const MobileSideMenu = ({ isShow, closeSideMenu }) => {
	const { userExist, goAbout, goCreate, goNotice, goLogin } =
		useSideMenu(closeSideMenu);

	return (
		<Drawer
			placement='right'
			onClose={closeSideMenu}
			visible={isShow}
			closable={false}
		>
			<CloseSVG onClick={closeSideMenu} />
			<MenuLink onClick={goCreate}>Create</MenuLink>
			<MenuLink onClick={goAbout}>About</MenuLink>
			<MenuLink onClick={goNotice}>Notice</MenuLink>
			{!userExist && <MenuLink onClick={goLogin}>Login</MenuLink>}
		</Drawer>
	);
};
export default MobileSideMenu;

const Drawer = styled(_Drawer)`
	.ant-drawer-content-wrapper {
		width: 100% !important;
		position: relative;

		.ant-drawer-content {
			background-color: #000000a6;
			/* mix-blend-mode: multiply; */

			.ant-drawer-body {
				padding-top: 297px;
				text-align: center;
			}
		}
	}
`;
const CloseSVG = styled(_CloseSVG)`
	position: absolute;
	top: 65px;
	right: 24px;
`;
const MenuLink = styled.div`
	line-height: 24px;
	font-weight: 400;
	font-size: 20px;
	letter-spacing: -0.2px;
	color: #bebebe;

	& + & {
		margin-top: 69px;
	}
`;
