import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import { createActionTypes, createRequestSaga } from '../lib/redux';
import * as ListService from '../service/list';

const [LIST, LIST_SUCCESS, LIST_FAILURE] = createActionTypes('list/LIST');
export const getList = createAction(LIST, (payload) => payload);
const listSaga = createRequestSaga(LIST, ListService.getArtWorks);

export function* workListSaga() {
	yield takeLatest(LIST, listSaga);
}

const initialState = {
	list: null,
	error: null,
};

const List = handleActions(
	{
		[LIST_SUCCESS]: (state, { payload }) => ({
			...state,
			list: payload,
			error: null,
		}),
		[LIST_FAILURE]: (state, { error }) => ({
			...state,
			error,
		}),
	},
	initialState,
);

export default List;
