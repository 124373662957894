import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const Paginator = ({ pageSize = 21, totalPage, handlePageChange }) => (
	<PageContainer>
		<ReactPaginate
			pageCount={Math.ceil(totalPage / pageSize)}
			pageRangeDisplayed={4}
			marginPagesDisplayed={2}
			breakLabel={'...'}
			previousLabel={'<'}
			nextLabel={'>'}
			onPageChange={handlePageChange}
			containerClassName={'pagination-ul'}
			activeClassName={'currentPage'}
			previousClassName={'pageLabel-btn'}
			nextClassName={'pageLabel-btn'}
		/>
	</PageContainer>
);

export default Paginator;
const PageContainer = styled.div`
	margin: 0 auto;
	width: 100%;
	ul {
		text-align: center;
		margin: 0 auto;
		display: block;
		padding-left: 15px;
		padding-right: 15px;
		li {
			font-size: 15px;
			padding: 8px 16px;
			display: inline-block;
			&.currentPage {
				font-weight: bold;
			}
			&.disabled {
				cursor: not-allowed;
				color: #999;
			}
		}
	}
`;
