import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { isAuth } from 'lib/storage';
import ArtMenu from './ArtMenu';

import { ReactComponent as UserSVG } from 'assets/svg/user.svg';

const Nav = ({ isMypage }) => {
	const history = useHistory();
	const userExist = isAuth();

	const handleRoute = (path) =>
		userExist ? history.push(path) : history.push('/login');

	return (
		<Wrapper isMypage={isMypage}>
			<ArtMenu handleRoute={(path) => history.push(path)} />
			<Menu onClick={() => history.push('/collections')}>
				Collections
			</Menu>
			<Menu onClick={() => history.push('/gallery')}>Gallery</Menu>
			<Menu onClick={() => handleRoute('/registration')}>Create</Menu>
			{userExist ? (
				<MyPageButton onClick={() => handleRoute('/mypage')}>
					<UserSVG />
				</MyPageButton>
			) : (
				<LoginButton onClick={() => handleRoute('/login')}>
					Login
				</LoginButton>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	font-size: 16px;
	letter-spacing: -0.1px;
	color: #777777;
	display: flex;
	align-items: center;
	span {
		margin-right: 35px;
		cursor: pointer;

		&.active {
			letter-spacing: -0.16px;
			color: #1a1a1a;
			opacity: 1;
		}
	}

	${(props) => props.theme.device.md} {
		display: none;
	}
`;
const Menu = styled.span``;
const LoginButton = styled.button`
	width: 134px;
	height: 50px;
	background: #1a1a1a 0% 0% no-repeat padding-box;
	border-radius: 43px;

	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0px;
	color: #f9f9f9;
`;
const MyPageButton = styled.button`
	position: relative;
	background-color: #1a1a1a;
	width: 38px;
	height: 38px;
	border-radius: 50px;

	& > svg {
		position: absolute;
		bottom: 2px;
		left: 7px;
	}
`;

export default Nav;
