export const numberWithCommas = (x) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getDiscountRate = (artwork) => {
	if (
		artwork &&
		artwork.hasOwnProperty('price') &&
		artwork.hasOwnProperty('discountPrice')
	) {
		const { price, discountPrice } = artwork;

		return discountPrice > 0
			? 100 - ((price - discountPrice) / price) * 100
			: 0;
	}
	return 0;
};
