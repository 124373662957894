import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setResistration } from '../../modules/resistration';

const Agree = ({ handleNext, id }) => {
	const dispatch = useDispatch();
	const [agreement, setAgreement] = useState({
		contentAgreement: false,
		nftAgreement: false,
	});
	const handleChange = (e) => {
		const { name, checked } = e.target;
		if (checked) {
			setError('');
		}
		setAgreement({
			...agreement,
			[name]: checked,
		});
	};
	const [error, setError] = useState('');
	const handleClick = () => {
		if (!agreement.contentAgreement || !agreement.nftAgreement) {
			setError('일부 항목 입력이 유효하지 않습니다.');
			return;
		}
		handleNext(id);
		dispatch(
			setResistration({
				...agreement,
			}),
		);
	};
	return (
		<Wrapper>
			<Container direction='column'>
				<h2>저작권 이용 및 콘텐츠 게시에 대한 안내</h2>
				<Content>
					① 본인은 제3자의 지적재산권, 인권, 개인정보 등 타인의 권리를
					침해하지 않습니다.
					<br />
					② 본인은 본인의 개인정보를 활용하는 경우 본인의 개인정보가
					제3자에게 공개, 활용, 제공 등이 될 수 있음을 인지하며 이에
					동의합니다.
					<br />
					③ 본인은 이용약관 및 운영정책에 반하는 NFT를 발행하지 않으며
					발행하는 NFT에 대한 모든 책임은 본인에게 있음을 확인하고
					동의합니다.
					<br />④ 본인은 NFT에 부적절한 이미지나 영상이 포함될 경우
					고지 없이 삭제될 수 있음을 인지하며 이에 동의합니다.
				</Content>

				<Checkbox>
					<input
						type='checkbox'
						id='copyright'
						name='contentAgreement'
						checked={agreement.contentAgreement}
						onChange={handleChange}
					/>
					<label htmlFor='copyright'>
						내용을 읽었으며, 이에 동의합니다.
					</label>
				</Checkbox>
			</Container>

			<Container direction='column'>
				<h2>NFT 발행을 위한 정보 수집 안내</h2>
				<Content>
					- 수집 항목 : NFT 이름, 설명, 파일(이미지 등), 아티스트 소개
					<br />
					- 수집 목적 : NFT의 발행 및 관리
					<br />
					- 보유 및 이용 기간 : 영구 (규정 위반, 요청 시 파기함)
					<br />
					<br />
					NFT 발행을 위해 해당 정보를 수집 및 이용할 수 있습니다.
				</Content>

				<Checkbox>
					<input
						type='checkbox'
						id='information'
						name='nftAgreement'
						checked={agreement.nftAgreement}
						onChange={handleChange}
					/>
					<label htmlFor='information'>
						내용을 읽었으며, 이에 동의합니다.
					</label>
				</Checkbox>
			</Container>

			<ButtonContainer>
				<Button onClick={handleClick}>다음으로</Button>
				<Error>{error}</Error>
			</ButtonContainer>
		</Wrapper>
	);
};

const Wrapper = styled.div``;
const Error = styled.span`
	font: normal normal 400 18px/21px Pretendard;
	letter-spacing: 0px;
	color: #fe7668;
	${(props) => props.theme.device.md} {
		font: normal normal 500 14px/17px Pretendard;
		letter-spacing: -0.14px;
	}
`;
const Container = styled.div`
	display: flex;
	flex-direction: ${(props) => props.direction};
	h2 {
		font: normal normal bold 30px/36px Pretendard;
		letter-spacing: -0.3px;
		color: #111111;

		${(props) => props.theme.device.md} {
			font: normal normal bold 20px/24px Pretendard;
			letter-spacing: -0.2px;
			color: #1a1a1a;
		}
	}
	div {
		margin-bottom: 20px;
		input {
			margin-right: 10px;
		}

		${(props) => props.theme.device.md} {
			margin-bottom: 16px;
		}
	}

	& + & {
		margin-top: 60px;
		${(props) => props.theme.device.md} {
			margin-top: 49px;
		}
	}
`;
const Content = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 0.5px solid #bebebe;
	border-radius: 10px;
	padding: 31px 32px;
	font: normal normal 400 16px/30px Pretendard;
	letter-spacing: -0.16px;
	color: #000000;
	margin-top: 22px;

	${(props) => props.theme.device.md} {
		margin-top: 16px;
		padding: 18px 13px;
		font: normal normal 400 14px/24px Pretendard;
		letter-spacing: -0.28px;
		color: #1a1a1a;
	}
`;
const Checkbox = styled.div`
	display: flex;

	& > input[type='checkbox'] {
		width: 18px;
		height: 18px;
		& + label {
			font: normal normal 400 16px/18px Pretendard;
			letter-spacing: -0.16px;
			color: #333333;
			position: relative;

			${(props) => props.theme.device.md} {
				font: normal normal 500 16px/19px Pretendard;
				letter-spacing: -0.16px;
				color: #1a1a1a;
			}
		}
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	${(props) => props.theme.device.md} {
		margin-top: 50px;
	}
`;
const Button = styled.button`
	width: 367px;
	height: 72px;
	background: #242424 0% 0% no-repeat padding-box;
	border-radius: 10px;
	font: normal normal 500 22px/26px Pretendard;
	letter-spacing: -0.55px;
	color: #ffffff;
	cursor: pointer;
	margin-bottom: 22px;

	${(props) => props.theme.device.md} {
		width: 100%;
		height: 47px;
		background: #1a1a1a 0% 0% no-repeat padding-box;
		border-radius: 10px;
		font: normal normal 400 17px/20px Pretendard;
		letter-spacing: -0.17px;
		margin-bottom: 12px;
	}
`;
export default Agree;
