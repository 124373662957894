import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as FilterSVG } from 'assets/svg/filter.svg';

const FilterSelector = ({ onChange }) => {
	const [showSortOptions, setShowSortOptions] = useState(false);
	const [priceStart, setPriceStart] = useState(null);
	const [priceEnd, setPriceEnd] = useState(null);

	return (
		<Wrapper>
			<Toggler onClick={() => setShowSortOptions(!showSortOptions)}>
				<FilterSVG />
			</Toggler>

			{showSortOptions && (
				<MenuWrapper>
					<Section>
						<Label>가격 필터</Label>
						<DefaultFilters>
							<DefaultFilter
								onClick={() => {
									setPriceStart(0);
									setPriceEnd(100000);
								}}
							>
								0 - 100,000
							</DefaultFilter>
							<DefaultFilter
								onClick={() => {
									setPriceStart(100000);
									setPriceEnd(200000);
								}}
							>
								100,000 - 200,000
							</DefaultFilter>
							<DefaultFilter
								onClick={() => {
									setPriceStart(200000);
									setPriceEnd(300000);
								}}
							>
								200,000 - 300,000
							</DefaultFilter>
							<DefaultFilter
								onClick={() => {
									setPriceStart(300000);
									setPriceEnd(null);
								}}
							>
								300,000~
							</DefaultFilter>
						</DefaultFilters>
					</Section>
					<Section>
						<Label>직접 입력</Label>
						<PriceRange>
							<Input
								placeholder='최소 금액'
								value={priceStart}
								onChange={(e) => setPriceStart(e.target.value)}
							/>
							<Input
								placeholder='최대 금액'
								value={priceEnd}
								onChange={(e) => setPriceEnd(e.target.value)}
							/>
							<Won>원</Won>
						</PriceRange>
					</Section>

					<FilterButton
						onClick={() => {
							const numberReg = /^\d*$/;

							if (
								!numberReg.test(priceStart) ||
								!numberReg.test(priceEnd)
							) {
								alert('숫자만 입력 가능합니다');
								return;
							}
							onChange(priceStart, priceEnd);
							setShowSortOptions(false);
						}}
					>
						적용
					</FilterButton>
				</MenuWrapper>
			)}
		</Wrapper>
	);
};

export default FilterSelector;

const Wrapper = styled.div`
	position: relative;
	border-left: 1px solid #bebebe;
	padding-left: 25px;

	${(p) => p.theme.device.md} {
		position: static;
		padding-left: 14px;
	}
`;
const Toggler = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;

	& > svg {
		width: 29px;
		height: 14px;
	}

	${(p) => p.theme.device.md} {
		& > svg {
			width: 17px;
			height: 9px;
		}
	}
`;
const MenuWrapper = styled.div`
	z-index: 999;
	right: 0;
	top: 37px;
	position: absolute;
	width: 594px;
	height: 598px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 99px #8e8e8e98;
	border-radius: 10px;
	padding: 40px 38px;

	&:last-child {
		padding-top: 57px;
	}

	${(p) => p.theme.device.md} {
		width: 100%;
		height: auto;
		/* height: 467px; */
		right: 0;
		bottom: 0;
		top: unset;
		padding: 28px 24px;
	}
`;
const Label = styled.div`
	font: normal normal 600 22px/22px Pretendard;
	letter-spacing: -0.22px;
	color: #111111;

	${(p) => p.theme.device.md} {
		width: 100%;
		font: normal normal bold 18px/21px Pretendard;
		letter-spacing: -0.18px;
		color: #1a1a1a;
		border-bottom: 1px solid #bebebe;
		padding-bottom: 23px;
	}
`;
const Section = styled.div`
	& + & {
		margin-top: 55px;
		border-top: 1px solid #bebebe;
		padding-top: 56px;

		${(p) => p.theme.device.md} {
			border-top: none;
			margin-top: 40px;
			padding-top: 0px;
		}
	}
`;
const DefaultFilters = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 28px;
	margin-top: 37px;
	/* margin-bottom: 55px; */
	${(p) => p.theme.device.md} {
		gap: 10px;
		margin-top: 24px;
		margin-bottom: 40px;
	}

	div {
		width: 245px;
		height: 50px;
		border: 1px solid #bebebe;
		border-radius: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		font: normal normal 500 18px/24px Pretendard;
		letter-spacing: -0.18px;
		color: #000000;
		text-align: center;

		${(p) => p.theme.device.md} {
			width: calc((100% - 10px) / 2);
			height: 33px;
			border-radius: 20px;
			line-height: 33px;
			font: normal normal 500 14px/24px Pretendard;
			letter-spacing: -0.14px;
			color: #777777;
		}

		${(p) => p.theme.device.sm} {
			font-size: 10px;
			width: calc((100% - 10px) / 2);
		}
	}
`;
const DefaultFilter = styled.div`
	cursor: pointer;
`;
const PriceRange = styled.div`
	position: relative;
	margin-top: 29px;
	display: flex;
	justify-content: space-between;
	${(p) => p.theme.device.md} {
		margin-top: 24px;
	}
`;
const Won = styled.div`
	position: absolute;
	top: 16px;
	right: 25px;
	font: normal normal 600 19px/19px Pretendard;
	letter-spacing: -0.19px;
	color: #111111;

	${(p) => p.theme.device.md} {
		font: normal normal 500 18px/39px Pretendard;
		letter-spacing: -0.18px;
		color: #1a1a1a;
		position: static;
	}
`;

const Input = styled.input`
	width: 249px;
	height: 52px;
	background: #f8f9fa 0% 0% no-repeat padding-box;
	border-radius: 26px;
	font: normal normal normal 18px/18px Pretendard;
	letter-spacing: -0.18px;
	padding: 15px 18px;

	${(p) => p.theme.device.md} {
		width: calc(100% / 2);
		height: 39px;
		border-radius: 20px;
		font: normal normal normal 13px/13px Pretendard;
		letter-spacing: -0.13px;

		& + & {
			::before {
				content: '-';
				width: 10px;
				margin: 0 8px;
				position: static;
			}
		}
	}
`;
const FilterButton = styled.button`
	width: 100%;
	height: 60px;
	background: #1a1a1a 0% 0% no-repeat padding-box;
	border-radius: 10px;
	font: normal normal 600 22px/22px Pretendard;
	letter-spacing: 0px;
	color: #f9f9f9;
	margin-top: 56px;

	${(p) => p.theme.device.md} {
		height: 47px;
		font: normal normal normal 17px/17px Pretendard;
		letter-spacing: -0.17px;
		color: #ffffff;
		margin-top: 60px;
	}
`;
