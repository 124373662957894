import React from 'react';
import styled from 'styled-components';
import { UnderlineTitle } from '../../components/common';
import NoHistory from '../../components/MyPage/NoHistory';
import Nav from '../../components/MyPage/Nav';

const Likes = () => {
	const history = true;
	const dummy = [
		// {
		// 	type: 'Art',
		// 	title: '작품명작품명작품명작품명작품명작품명작품명작품명작품명작품명작품명작품명',
		// 	owned: '판매자명',
		// 	created: '아티스트명',
		// 	work: { type: '동영상', material: 'H.264, 1080p, 1분 30초' },
		// 	price: 200000,
		// 	discountPrice: 100000,
		// },
		// {
		// 	type: 'Art',
		// 	title: '작품명작품명작품명작품명작품명작품명작품명작품명작품명작품명작품명작품명',
		// 	owned: '판매자명',
		// 	created: '아티스트명',
		// 	work: { type: '사진', material: '4000x6000px, PNG' },
		// 	price: null,
		// 	discountPrice: 100000,
		// },
	];
	return (
		<>
			<Nav>
				<UnderlineTitle title='Likes' />
				{history ? (
					dummy.map((d, i) => {
						return (
							<History key={i}>
								<Thumbnail>작품썸네일</Thumbnail>
								<Container>
									<Type>{d.type}</Type>
									<Title>{d.title}</Title>
									<Owned>
										<span>Owned by</span>
										{d.owned}
									</Owned>
									<Created>
										<span>Created by</span>
										{d.created}
									</Created>
									<WorkInfo>
										<span>{d.work.type}</span>
										{d.work.material}
									</WorkInfo>
									<PriceContainer>
										{d.price && (
											<>
												<Price>
													{d.price.toLocaleString()}원
												</Price>
												&nbsp;→&nbsp;
											</>
										)}
										<DiscountPrice>
											{d.discountPrice.toLocaleString()}원
										</DiscountPrice>
									</PriceContainer>
								</Container>
							</History>
						);
					})
				) : (
					<NoHistory content='아직 좋아요를 누른 콘텐츠가 없군요.' />
				)}
			</Nav>
		</>
	);
};

const History = styled.div`
	display: flex;
	margin-top: 20px;
	${(props) => props.theme.device.md} {
		flex-direction: column;
		padding: 0 16px;
		margin-top: 0;
	}
`;
const Thumbnail = styled.div`
	min-width: 433px;
	height: 279px;
	background-color: #ebebeb;
	margin-right: 30px;
	text-align: center;
	line-height: 297px;
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	${(props) => props.theme.device.md} {
		min-width: 100%;
		height: 210px;
		line-height: 210px;
		margin-right: 0;
	}
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;
const Type = styled.div`
	color: #6b98f2;
	font-size: 25px;
	font-weight: bold;
	margin-bottom: 20px;
	${(props) => props.theme.device.md} {
		font-size: 18px;
	}
`;
const Title = styled.div`
	color: #000;
	font-size: 25px;
	font-weight: bold;
	margin-bottom: 10px;
	${(props) => props.theme.device.md} {
		font-size: 18px;
	}
`;
const Owned = styled.div`
	color: #666;
	padding-top: 5px;
	font-size: 15px;
	span {
		display: inline-block;
		width: 20%;
	}
	${(props) => props.theme.device.md} {
		font-size: 15px;
		span {
			width: 30%;
		}
	}
`;
const Created = styled(Owned)``;
const WorkInfo = styled(Owned)`
	margin-top: 20px;
`;
const PriceContainer = styled.div`
	font-size: 25px;
	margin-top: 20px;
	margin-bottom: 40px;
	${(props) => props.theme.device.md} {
		font-size: 20px;
	}
`;
const Price = styled.span`
	text-decoration: line-through;
`;
const DiscountPrice = styled.span``;
export default Likes;
