import { useState } from 'react';
import styled from 'styled-components';
import { Menu as _Menu } from 'antd';

import { ReactComponent as ArrowSVG } from 'assets/svg/chevron-left.svg';
import { ReactComponent as CheckSVG } from 'assets/svg/check.svg';
import { ReactComponent as SearchSVG } from 'assets/svg/searchMini.svg';

const SEARCH_TYPE_OPTIONS = [
	{ label: '제목', value: 'title' },
	{ label: '닉네임', value: 'nickname' },
	{ label: '내용', value: 'body' },
];

const SearchBar = ({ search, handleSearch }) => {
	const [showSearchType, setShowSearchType] = useState(false);
	const [searchType, setSearchType] = useState(SEARCH_TYPE_OPTIONS[0]);
	const [value, setValue] = useState(search);

	return (
		<Wrapper>
			<Toggler onClick={() => setShowSearchType(!showSearchType)}>
				{searchType?.label}
				<ArrowSVG />
			</Toggler>

			{showSearchType && (
				<Menu>
					{SEARCH_TYPE_OPTIONS.map((op, i) => (
						<MenuItem
							key={i}
							$active={op.value === searchType}
							onClick={() => {
								setSearchType(op);
								setShowSearchType(false);
							}}
						>
							{op.value === searchType && <CheckSVG />}
							{op.label}
						</MenuItem>
					))}
				</Menu>
			)}

			<Input
				type='text'
				placeholder='검색어를 입력하세요.'
				value={value}
				onChange={(e) => setValue(e.target.value)}
				onKeyPress={(e) => {
					if (e.key === 'Enter') {
						handleSearch(searchType.value, value);
					}
				}}
			/>
			<SearchButton onClick={() => handleSearch(searchType.value, value)}>
				<SearchSVG />
			</SearchButton>
		</Wrapper>
	);
};
export default SearchBar;

const Wrapper = styled.section`
	position: relative;
	height: 55px;
	margin-top: 16px;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	${(p) => p.theme.device.md} {
		height: 42px;
	}
`;
const Input = styled.input`
	width: 384px;
	height: 55px;
	border: 1px solid #dddddd;
	border-radius: 30px;
	padding-left: 115px;

	font-size: 16px;
	letter-spacing: -0.16px;
	color: #777777;

	${(props) => props.theme.device.md} {
		width: calc(100% - 74px);
		border: none;
		height: 42px;
		background: #f8f9fa 0% 0% no-repeat padding-box;
		border-radius: 26px;
		padding-left: 17px;
		font: normal normal 400 14px/17px Pretendard;
		letter-spacing: -0.14px;
	}
`;
const SearchButton = styled.button`
	position: absolute;
	right: 26px;
	border: none;
	background-color: unset;
	${(props) => props.theme.device.md} {
		width: 16px;
		height: 16px;
		top: 13px;
		right: 21px;

		& > svg {
			width: 16px;
			height: 16px;
		}
	}
`;

const Toggler = styled.div`
	position: absolute;
	right: 287px;
	display: flex;
	align-items: center;
	cursor: pointer;
	line-height: 21px;
	font: normal normal bold 18px/19px Pretendard;
	letter-spacing: -0.18px;
	color: #1a1a1a;

	& > svg {
		width: 15px;
		height: 7px;
		margin-left: 10px;
	}

	${(p) => p.theme.device.md} {
		line-height: 16px;
		font: normal normal bold 13px/19px Pretendard;
		letter-spacing: -0.13px;
		right: unset;
		left: 24px;

		& > svg {
			width: 8px;
			height: 4px;
			margin-left: 5px;
		}
	}
`;
const Menu = styled(_Menu)`
	z-index: 999;
	right: 0;
	top: 37px;
	position: absolute;
	width: 297px;
	background: #4d4d4d;
	box-shadow: 0px 0px 99px #31313126;
	mix-blend-mode: multiply;
	border-radius: 10px;
	padding: 30px 35px 38px 35px;
`;
const MenuItem = styled(_Menu.Item)`
	height: 21px;
	font-weight: ${(p) => (p.$active ? 600 : 0)};
	font-size: 18px;
	letter-spacing: -0.18px;
	color: #ffffff;
	margin: 0;
	padding: 0;

	svg {
		margin-right: 10px;
	}

	&:hover {
		font-weight: 600;
		background-color: unset;
	}
`;
