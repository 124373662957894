import * as storage from './storage';
import API from './api';
import dayjs from 'dayjs';

export function onClickPayment(data) {
	/* 가맹점 식별하기 */
	const { IMP } = window;
	IMP.init('imp79620411');

	/* 결제 창 호출하기 */
	IMP.request_pay(
		{
			...data,
			m_redirect_url:
				data.pay_method === 'vbank'
					? window.location.origin + `/checkout/success/`
					: data.m_redirect_url,
		},
		callback,
	);
}

/* 콜백 함수 정의하기 */
export const callback = (response) => {
	console.log(response);
	const { success, pay_method, merchant_uid, imp_uid, error_msg } = response;
	const work = storage.getArtWorkInfo();
	const option = storage.getOption();

	let paymentInfo = { merchant_uid, imp_uid };
	if (pay_method === 'vbank') {
		const { vbank_date, vbank_holder, vbank_name, vbank_num } = response;
		paymentInfo = {
			...paymentInfo,
			vbank_date,
			vbank_holder,
			vbank_name,
			vbank_num,
		};
	}

	if (success) {
		const data = {
			name: response.buyer_name,
			originalPrice: option.totalPrice,
			originalFile: work.originalFile,
			discountedPrice: response.paid_amount,
			discountInfo: {
				originalPrice: option.totalPrice,
				discountPrice: work.discountPrice || 0,
				boucherDiscountPrice: work.boucherDiscount?.discountPrice || 0,
			},
			artworkId: storage.getArtWorkId(),
			artworkInfo: storage.getArtWorkInfo(),
			fullAddress: response.buyer_addr,
			paymentMethod:
				response.pay_method === 'point' ? 'card' : response.pay_method,
			paymentInfo,
			orderLines: {
				...option,
				paymentedAt: dayjs().format('YYYY.MM.DD hh:mm:ss'),
			},
			agreement: storage.getAgreement(),
		};
		API.post('/orders/', data)
			.then((resp) => {
				const { data } = resp.response.data;
				localStorage.setItem('vbank_date', data.vbankDate);
				localStorage.setItem('vbank_holder', data.vbankHolder);
				localStorage.setItem('vbank_name', data.vbankName);
				localStorage.setItem('vbank_num', data.vbankNum);
				window.location.href = '/checkout/success';
			})
			.then(() => {
				localStorage.removeItem('artworkInfo');
				localStorage.removeItem('artworkId');
				localStorage.removeItem('agreement');
				localStorage.removeItem('option');
			})
			.catch(() => {
				alert('결제에 실패했습니다. 고객센터에 문의바랍니다.');
				// window.location.href = '/';
			});
	} else {
		alert(`결제 실패: ${error_msg}`);
	}
};
