import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';

import api from 'lib/api';
import useUser from 'hooks/useUser';
import { ReactComponent as HomeSVG } from 'assets/svg/artist-home.svg';
import { ReactComponent as InstgramSVG } from 'assets/svg/artist-instagram.svg';
import { ReactComponent as ShowcaseSVG } from 'assets/svg/artist-showcase.svg';

const convertUrl = (url) => (url.startsWith('http') ? url : 'http://' + url);

const ArtistSection = () => {
	const history = useHistory();
	const { username } = useUser() || { username: '' };
	const { id } = useParams();

	const [artist, setArtist] = useState(null);

	useEffect(() => {
		if (id < 0) return;
		(async () => {
			try {
				const {
					response: {
						data: { data },
					},
				} = await api.get(`/artists/${id}/`);
				setArtist(data);
			} catch (e) {
				setArtist(null);
			}
		})();
		window.scrollTo(0, 0);
	}, [id]);

	return (
		<Wrapper>
			<Thumbnail $thumbnail={artist?.profileImage} />
			<Name>{artist?.name}</Name>
			{(artist?.homepage || artist?.instagram || artist?.galleryLink) && (
				<Links>
					{artist?.homepage !== '' && (
						<SVGLink
							href={convertUrl(artist?.homepage) || undefined}
							target='_blank'
						>
							<HomeSVG />
						</SVGLink>
					)}
					{artist?.instagram !== '' && (
						<SVGLink
							href={convertUrl(artist?.instagram) || undefined}
							target='_blank'
						>
							<InstgramSVG />
						</SVGLink>
					)}
					{artist?.galleryLink !== '' && (
						<SVGLink
							href={convertUrl(artist?.galleryLink) || undefined}
							target='_blank'
						>
							<ShowcaseSVG />
						</SVGLink>
					)}
				</Links>
			)}

			<Description>
				{artist?.description.split('\n').map((row, i) => (
					<span key={i}>
						{row}
						<br />
					</span>
				))}
			</Description>

			{artist?.username === username && (
				<UpdateArtworkButton
					onClick={() =>
						history.push(`/registration?artistId=${artist.id}`)
					}
				>
					수정하기
				</UpdateArtworkButton>
			)}
		</Wrapper>
	);
};
export default ArtistSection;

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const Thumbnail = styled.div`
	width: 244px;
	height: 244px;
	border-radius: 50%;
	background: #bebebe 0% 0% no-repeat padding-box;
	background-image: url(${(p) => p.$thumbnail});
	background-size: cover;
	background-position: center center;

	${(p) => p.theme.device.md} {
		width: 126px;
		height: 126px;
	}
`;
const Name = styled.div`
	font: normal normal bold 30px/36px Pretendard;
	letter-spacing: -0.3px;
	color: #1a1a1a;
	margin-top: 30px;

	${(p) => p.theme.device.md} {
		font: normal normal 600 20px/24px Pretendard;
		letter-spacing: -0.4px;
		margin-top: 15px;
	}
`;
const Links = styled.section`
	display: flex;
	height: 52px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #ebebeb;
	border-radius: 26px;
	padding: 13px 0;
	margin-top: 23px;

	${(p) => p.theme.device.md} {
		height: 40px;
		padding: 10px 6px;
		margin-top: 16px;
	}
`;
const SVGLink = styled.a`
	padding: 0 26px;
	& + & {
		border-left: solid 1px #ebebeb;
	}

	${(p) => p.theme.device.md} {
		padding: 0 17px;
		& > svg {
			width: 17px;
			height: 20px;
		}
	}
`;
const Description = styled.div`
	font: normal normal 400 17px/28px Pretendard;
	letter-spacing: -0.17px;
	color: #242424;
	margin-top: 42px;
	width: 558px;

	${(p) => p.theme.device.md} {
		width: 282px;
		font: normal normal 400 13px/20px Pretendard;
		letter-spacing: -0.13px;
		color: #1a1a1a;
		margin-top: 30px;
	}
`;
const UpdateArtworkButton = styled.div`
	width: 558px;
	font: normal normal 400 18px/21px Pretendard;
	letter-spacing: 0px;
	color: #6e6e6e;
	text-decoration: underline;
	text-align: right;
	cursor: pointer;

	${(p) => p.theme.device.md} {
		width: 70px;
		height: 23px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 0.5px solid #bebebe;
		border-radius: 3px;
		font: normal normal 400 13px/16px Pretendard;
		letter-spacing: -0.39px;
		text-decoration: none;
		color: #777777;
		text-align: center;
		margin-left: 212px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
