import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Drawer as _Drawer } from 'antd';
import styled from 'styled-components';

import LogoImg from 'assets/images/logo_211203.png';
import { ReactComponent as GoBackSVG } from 'assets/svg/goBack.svg';
import { ReactComponent as SearchSVG } from 'assets/svg/search_mobile.svg';
import { ReactComponent as HambergerSVG } from 'assets/svg/hamberger.svg';
import { ReactComponent as _CloseSVG } from 'assets/svg/close.svg';

import MobileSideMenu from './MobileSideMenu';
import MobileNav from './MobileNav';
import MobileSearch from './MobileSearch';

const useMobileHeader = () => {
	const history = useHistory();
	const [showSideMenu, setShowSideMenu] = useState(false);
	const [showSearch, setShowSearch] = useState(false);

	return {
		showBackButton: /detail|changepassword/.test(history.location.pathname),
		showSideMenu,
		showSearch,
		handler: {
			openSideMenu: () => setShowSideMenu(true),
			closeSideMenu: () => setShowSideMenu(false),
			toggleShowSearch: () => setShowSearch(!showSearch),
			goBack: () => history.goBack(),
		},
	};
};

const MobileHeader = () => {
	const history = useHistory();
	const {
		showBackButton,
		showSideMenu,
		showSearch,
		handler: { openSideMenu, closeSideMenu, toggleShowSearch, goBack },
	} = useMobileHeader();

	const [query, setQuery] = useState('');
	const onSearch = (query) => {
		if (query) {
			setQuery(query);
			toggleShowSearch();
			// setQueryBuffer('');
		}
	};

	useEffect(() => {
		if (!query) return;

		history.push(`/listing?search=${query}`);
	}, [query]);

	return (
		<Wrapper>
			{showBackButton ? (
				<GoBackButton onClick={goBack} />
			) : (
				<Logo src={LogoImg} />
			)}

			<Actions>
				<SearchSVG onClick={toggleShowSearch} />
				<HambergerSVG onClick={openSideMenu} />
			</Actions>

			<MobileSideMenu
				isShow={showSideMenu}
				closeSideMenu={closeSideMenu}
			/>

			<MobileSearch isShow={showSearch} onSearch={onSearch} />

			{/* <Drawer
				placement='right'
				onClose={closeSideMenu}
				visible={showSideMenu}
				size={'100%'}
				closable={false}
			>
				검색
			</Drawer> */}

			<MobileNav />
		</Wrapper>
	);
};
export default MobileHeader;

const Wrapper = styled.article`
	display: none;

	${(props) => props.theme.device.md} {
		display: flex;
		width: 100%;
		height: 108px;
		padding: 55px 24px 15px 24px;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #bebebe;
		background: #ffffff 0% 0% no-repeat padding-box;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 9999;
	}
`;
const Logo = styled.img`
	width: 115px;
`;
const GoBackButton = styled(GoBackSVG)``;
const Actions = styled.div`
	display: 100%;
	display: flex;
	align-items: center;
	gap: 16px;
`;
