import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { UserInput } from 'components/common';
import SocialLogin from 'components/SocialLogin';
import * as storage from 'lib/storage';
import useFetch from 'lib/hooks/useFetch';

const SignIn = () => {
	const history = useHistory();
	const [userInfo, setUserInfo] = useState({
		username: '',
		password: '',
	});
	const { username, password } = userInfo;
	const handleChange = (e) => {
		const { name, value } = e.target;
		setUserInfo({
			...userInfo,
			[name]: value,
		});
	};

	const { /*executeFetch, data, error,*/ isLoading } = useFetch(
		'/user/token/',
		'post',
		{
			username,
			password,
		},
		{ immediate: false },
	);
	// TODO: env 처리
	const baseURL = process.env.REACT_APP_BASE_URL;
	const handleLogin = () => {
		try {
			axios
				.post(`${baseURL}/user/token/`, {
					username,
					password,
				})
				.then((res) => {
					if (res?.data === 400) {
						alert('정확한 정보를 입력해주세요.');
						return;
					}
					if (res.data.data.key) {
						const token = res.data.data.key;
						storage.login(token);
						storage.setNickname(res.data.data.user.nickname);
						storage.setEmail(res.data.data.user.username);
						history.push('/');
					}
				})
				.catch(() => {
					alert('정확한 정보를 입력해주세요.');
				});
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<Wrapper>
			<Title>로그인</Title>

			<FieldBox>
				<UserInput
					text='이메일'
					name='username'
					placeholder='example@domain.com'
					handlechange={handleChange}
				/>
				<UserInput
					type='password'
					text='비밀번호'
					name='password'
					placeholder='*******'
					handlechange={handleChange}
				/>
			</FieldBox>

			<LoginOptionBox>
				<AutoLogin>
					<input name='autoLogin' id='autoLogin' type='checkbox' />
					<label htmlFor='autoLogin'>자동 로그인</label>
				</AutoLogin>
				<ForgotPassword>
					<Link to='/find/password'>비밀번호를 잊으셨나요?</Link>
				</ForgotPassword>
			</LoginOptionBox>

			<SignUpBox>
				<Link to='/signup'>회원가입</Link>
			</SignUpBox>

			<ActionBox>
				<LoginButton isLoading={isLoading} onClick={handleLogin}>
					로그인
				</LoginButton>
				<SocialLoginTitle>SNS 계정으로 로그인하기</SocialLoginTitle>
				<SocialLogin />
			</ActionBox>
		</Wrapper>
	);
};

const Wrapper = styled.section`
	width: 590px;
	padding: 118px 0 252px 0;
	color: #333;
	display: flex;
	flex-direction: column;

	${(props) => props.theme.device.md} {
		width: 100%;
		padding: 0 16px;
	}
`;
const Title = styled.h1`
	text-align: center;
	font-size: 37px;
	font-weight: bold;
	letter-spacing: 0px;
	color: #111111;
	line-height: 45px;
	margin: 0;
	margin-bottom: 68px;

	${(props) => props.theme.device.md} {
		margin-top: 35px;
	}
`;
const FieldBox = styled.div`
	gap: 29px;
	display: flex;
	flex-direction: column;
	${(props) => props.theme.device.md} {
		width: 100%;
	}
`;
const LoginOptionBox = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 27px;
	height: 19px;
`;
const ActionBox = styled.div`
	margin-top: 103px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const SocialLoginTitle = styled.div`
	margin-top: 40px;
	margin-bottom: 24px;
	font-weight: 600;
	font-size: 20px;

	letter-spacing: -0.4px;
	color: #000000;
	line-height: 24px;

	${(props) => props.theme.device.md} {
		font: normal normal 600 17px/20px Pretendard;
		letter-spacing: -0.34px;
		color: #000000;
	}
`;

const LoginButton = styled.button`
	background: ${(props) => (props.isLoading ? '#ddd' : '#242424')};
	border-radius: 10px;
	width: 367px;
	height: 72px;
	border: none;
	font-size: 22px;
	font-weight: 600;
	letter-spacing: -0.55px;
	color: #ffffff;

	${(props) => props.theme.device.md} {
		width: 100%;
		height: 47px;
		background: #1a1a1a;
		border-radius: 10px;
		font: normal normal 400 17px/20px Pretendard;
		letter-spacing: -0.17px;
	}
`;
const AutoLogin = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	label {
		font-size: 16px;
	}
	input {
		margin-right: 10px;
	}
`;
const ForgotPassword = styled.div`
	a {
		color: #333;
		text-decoration: underline;

		font-size: 16px;
		letter-spacing: -0.16px;
	}
`;
const SignUpBox = styled.div`
	text-align: right;
	height: 19px;
	margin-top: 15px;
	a {
		font-weight: 600;
		font-size: 16px;
		letter-spacing: -0.16px;
		color: #4568dc;
	}
`;
export default SignIn;
