import { useEffect, useState } from 'react';

import api from 'lib/api';

const useCurations = () => {
	const [curations, setCurations] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const {
					response: {
						data: { data },
					},
				} = await api.get(`/curations/`);
				setCurations(data);
			} catch (e) {
				setCurations([]);
			}
		})();
	}, []);

	return curations;
};
export default useCurations;
