import { useMemo } from 'react';
import { Dropdown, Menu as _Menu } from 'antd';

import styled from 'styled-components';

import useCategory from 'hooks/useCategory';

const ArtMenu = ({ handleRoute }) => {
	const categories = useCategory();
	// const [categories, setCategories] = useState([]);

	// // TODO: memoize 적용?? https://www.npmjs.com/package/memoize
	// useEffect(() => {
	// 	(async () => {
	// 		try {
	// 			const { response } = await api.get('/artwork-categories/');
	// 			setCategories(sortBy(response.data.data, ['id']));
	// 		} catch (e) {
	// 			setCategories([]);
	// 		}
	// 	})();
	// }, []);

	const allCategory = useMemo(
		() => categories.find((c) => c.code === 'all'),
		[categories],
	);

	const menu = (
		<Menu>
			{allCategory && (
				<MenuItem
					onClick={() =>
						handleRoute({
							pathname: '/listing',
							search: `?cat=${allCategory.code}`,
						})
					}
				>
					{allCategory.name}
				</MenuItem>
			)}
			{categories
				.filter((c) => c.code !== 'all')
				.map((category) => (
					<MenuItem
						key={category.id}
						// $active={true}
						onClick={() =>
							handleRoute({
								pathname: '/listing',
								search: `?cat=${category.code}`,
							})
						}
					>
						{category.name}
					</MenuItem>
				))}
		</Menu>
	);
	return (
		<Dropdown overlay={menu} trigger={['click']}>
			<span>Art</span>
		</Dropdown>
	);
};

const Menu = styled(_Menu)`
	width: 297px;
	box-shadow: 0px 0px 99px #31313126;
	border-radius: 10px;
	padding: 14px 12px;
	margin-top: 20px;
`;
const MenuItem = styled(_Menu.Item)`
	height: 67px;
	background: ${(p) => (p.$active ? '#ebebeb' : 'none')};
	border-radius: 10px;
	font-weight: ${(p) => (p.$active ? 600 : 'medium')};
	font-size: 20px;
	letter-spacing: -0.2px;
	color: ${(p) => (p.$active ? '#1A1A1A' : '#bebebe')};
`;

export default ArtMenu;
