import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as _ArrowSVG } from 'assets/svg/chevron-left.svg';
import { useMemo } from 'react';

const OPTIONS = [
	{
		label: '작품 (할인포함)',
		value: '작품 (할인포함)',
		description: '상품 설명',
	},
	{
		label: '작품 (할인포함) + 리워드A',
		value: '작품 (할인포함) + 리워드A',
		description:
			'리워드 A(오프라인 전시회 초대권 2매)가 포함된 구성입니다.',
	},
];
const DEFAULT_LABEL = '작품 (할인 포함)';
const PurchaseTypeSelector = ({
	value,
	rewardA,
	rewardB,
	rewardC,
	price,
	onChange,
}) => {
	const [showOptions, setShowOptions] = useState(false);
	const [selected, setSelected] = useState(null);

	const options = useMemo(() => {
		const ops = [
			{
				label: DEFAULT_LABEL,
				value: 'default',
				description: '리워드 포함되지 않는 구성입니다.',
			},
		];
		if (rewardA) {
			ops.push({
				label: `${DEFAULT_LABEL} + 리워드A`,
				value: 'rewardA',
				description: rewardA,
			});
		}
		if (rewardB) {
			ops.push({
				label: `${DEFAULT_LABEL} + 리워드B`,
				value: 'rewardB',
				description: rewardB,
			});
		}
		if (rewardC) {
			ops.push({
				label: `${DEFAULT_LABEL} + 리워드C`,
				value: 'rewardC',
				description: rewardC,
			});
		}

		return ops;
	}, [rewardA, rewardB, rewardC]);

	useEffect(() => onChange(selected), [selected, onChange]);

	return (
		<Wrapper>
			{showOptions ? (
				<BorderWrapper>
					{options.map((op, i) => (
						<Option
							key={i}
							{...op}
							price={price}
							selected={selected && op.value === selected.value}
							onClick={() => {
								setSelected(op);
								setShowOptions(false);
							}}
						/>
					))}
				</BorderWrapper>
			) : (
				<BorderWrapper onClick={() => setShowOptions(!showOptions)}>
					<span>{!selected ? '구성' : selected.label}</span>
				</BorderWrapper>
			)}

			<ArrowSVG
				$isOpened={showOptions}
				onClick={() => setShowOptions(!showOptions)}
			/>
		</Wrapper>
	);
};

export default PurchaseTypeSelector;

const Option = ({ label, value, description, price, selected, onClick }) => (
	<OptionWrapper data-value={value} selected={selected} onClick={onClick}>
		<label>{label}</label>
		<div>{description}</div>
		<div className='price'>총 {price.toLocaleString()}원</div>
	</OptionWrapper>
);

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: 62px;
	z-index: 999;

	${(props) => props.theme.device.md} {
		height: 43px;
	}
`;
const BorderWrapper = styled.div`
	border: 1px solid #bebebe;
	border-radius: 10px;
	font-weight: 500;
	background: #ffffff;
	font: normal normal 500 18px/62px Pretendard;
	letter-spacing: -0.18px;
	color: #777777;

	& > span {
		padding: 0 35px;
	}

	${(props) => props.theme.device.md} {
		font: normal normal 500 14px/43px Pretendard;
		letter-spacing: -0.14px;
		color: #1a1a1a;

		& > span {
			padding: 0 12px;
		}
	}
`;
const OptionWrapper = styled.div`
	line-height: 18px;
	font-size: 15px;
	letter-spacing: -0.15px;
	color: #1a1a1a;
	padding: 25px 35px 33px 35px;
	cursor: pointer;

	label {
		cursor: pointer;
		display: inline-block;
		line-height: 21px;
		font-weight: 600;
		font-size: 18px;
		letter-spacing: -0.18px;
		color: ${(p) => (p.selected ? '#4568DC' : '#1A1A1A')};
		margin-bottom: 25px;
	}
	& > .price {
		line-height: 20px;
		text-align: left;
		font-weight: bold;
		font-size: 17px;
		letter-spacing: -0.17px;
		margin-top: 15px;
	}

	& + & {
		border-top: 1px solid #bebebe;
	}

	${(props) => props.theme.device.md} {
		font: normal normal 400 14px/17px Pretendard;
		letter-spacing: -0.14px;
		color: #777777;
		label {
			font: normal normal 600 16px/19px Pretendard;
			letter-spacing: -0.16px;
			color: #1a1a1a;
			margin-bottom: 20px;
		}
		& > .price {
			font: normal normal 600 16px/19px Pretendard;
			letter-spacing: -0.16px;
			color: #1a1a1a;
			margin-top: 19px;
		}
	}
`;
const ArrowSVG = styled(_ArrowSVG)`
	position: absolute;
	cursor: pointer;
	top: 26px;
	right: 34px;
	width: 20px;
	height: 10px;
	transform: ${(p) => (p.$isOpened ? '' : 'rotate(180deg)')};

	${(props) => props.theme.device.md} {
		width: 12px;
		height: 6px;
		top: 19px;
		right: 16px;
	}
`;
