import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { UserInput, FullButton } from 'components/common';
import SelectArrowSVG from 'assets/svg/selectArrow';
import axios from 'axios';
// import useFetch from '../../lib/hooks/useFetch';
// TODO: env 처리
const baseURL = process.env.REACT_APP_BASE_URL;
const SignUp = () => {
	const history = useHistory();
	const [signupInput, setSignupInput] = useState({
		email: '',
		name: '',
		nickname: '',
		phoneNumber: '',
		klip: '',
		password: '',
		checkPassword: '',
		path: '',
		necessary: false,
		selection: false,
		additionalInfo: '',
	});
	const {
		email,
		name,
		password,
		checkPassword,
		nickname,
		phoneNumber,
		klip,
		necessary,
		selection,
		// additional,
	} = signupInput;
	const [errorMSG, setErrorMSG] = useState('');
	const [checkExist, setCheckExist] = useState(false);
	const handleExist = async () => {
		const reqNickname = encodeURIComponent(encodeURIComponent(nickname));

		if (reqNickname === '') {
			alert('닉네임을 입력하세요.');
			return;
		}

		await axios
			.get(baseURL + '/user/nickname?value=' + reqNickname, {})
			.then((res) => {
				if (res.data.data.isExist) {
					alert('이미 등록된 닉네임입니다.');
					setSignupInput({
						...signupInput,
						nickname: '',
					});
				} else {
					setCheckExist(true);
					alert('사용가능한 닉네임입니다.');
				}
			})
			.catch((err) => {
				setErrorMSG('일부 힝목 입력이 유효하지 않습니다.');
			});
	};
	const [checkedPassword, setCheckedPassword] = useState('');

	const handlechange = (e) => {
		setErrorMSG('');
		let pattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
		const { name, value, maxLength } = e.target;
		// handle email input
		if (name === 'email') {
			if (pattern.test(value)) {
				alert('한글은 입력할 수 없습니다.');
				setSignupInput({
					...signupInput,
					email: '',
				});
				return;
			}
		}

		// # handle phoneNumber input
		if (name === 'phoneNumber') {
			// if (pattern.test(value)) {
			// 	setSignupInput({
			// 		...signupInput,
			// 		phoneNumber: value.slice(0, 0),
			// 	});
			// 	return;
			// }
			if (value > maxLength) {
				setSignupInput({
					...signupInput,
					phoneNumber: value
						.replace(/[^0-9.]/g, '')
						.replace(/(\..*)\./g, '$1')
						.slice(0, maxLength),
				});
				return;
			}
		}
		// # handle check-password input
		if (name === 'checkPassword') {
			if (password !== value) {
				setCheckedPassword('비밀번호가 일치하지 않습니다.');
			} else {
				setCheckedPassword('');
			}
		}
		setSignupInput({
			...signupInput,
			[name]: name === 'necessary' ? !necessary : value,
		});
	};
	const handleClick = () => {
		let pattern = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$');
		let regExp = /^(?=.*[a-zA-z])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/;

		if (!pattern.test(email)) {
			alert('이메일 형식이 유효하지 않습니다.');
			return;
		} else {
			axios
				.get(`${baseURL}/user/username/?value=${email}`, {})
				.then((res) => {
					if (res.data.data.isExist) {
						alert('동일한 이메일이 존재합니다.');
						setSignupInput({
							...signupInput,
							email: '',
						});
						return;
					}
				})
				.catch((err) => {
					console.log(err);
					setErrorMSG('일부 힝목 입력이 유효하지 않습니다.');
				});
		}
		if (!checkExist) {
			alert('닉네임 중복확인이 필요합니다.');
			return;
		}
		if (phoneNumber === '') {
			alert('필수 항목을 입력해주세요.');
			return;
		}
		if (!regExp.test(password)) {
			alert('비밀번호는 영문+특수기호 8자리 이상으로 구성하여야 합니다.');
			return;
		}
		if (password !== checkPassword) {
			alert('비밀번호가 일치하지 않습니다');
			return;
		}
		if (klip.length > 0) {
			alert(
				'정확하지 않은 kaikas 주소를 입력하신 경우, 전송이 진행되지 않습니다.',
			);
		}

		if (necessary) {
			axios
				.post(`${baseURL}/user/`, {
					username: email,
					name,
					password,
					nickname,
					phoneNumber,
					klipInfo: klip,
					agreement: { necessary, selection },
				})
				.then((res) => {
					history.push('/login');
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			console.log(necessary);
			setErrorMSG('일부 항목 입력이 유효하지 않습니다.');
		}
	};
	return (
		<SignUpContainer>
			<Title>회원가입</Title>
			<UserInput
				value={email}
				handlechange={handlechange}
				name='email'
				text='이메일'
				placeholder='example@domain.com'
				isRequired={true}
			/>
			<UserInput
				handlechange={handlechange}
				text='이름(실명)'
				name='name'
				placeholder='반드시 실명을 입력해주세요'
				isRequired={true}
			/>
			<UserInput
				value={nickname}
				// isLoading={nicknameLoading}
				handlechange={handlechange}
				buttonOnclick={handleExist}
				text='닉네임'
				name='nickname'
				placeholder='닉네임을 입력해주세요'
				button='중복확인'
				isRequired={true}
			/>
			<UserInput
				value={phoneNumber}
				handlechange={handlechange}
				name='phoneNumber'
				text='휴대전화 번호'
				type='number'
				maxLength={11}
				placeholder='- 제외한 숫자만 입력'
				isRequired={true}
			/>
			<UserInput
				name='klip'
				handlechange={handlechange}
				text='전자지갑 주소 (선택)'
				placeholder='Kaikas 전자지갑주소를 등록해주세요'
				// button='등록하기'
			/>
			<UserInput
				handlechange={handlechange}
				name='password'
				text='비밀번호(영문자, 특수문자 조합 8자리 이상)'
				type='password'
				placeholder='*******'
				isRequired={true}
			/>
			<UserInput
				handlechange={handlechange}
				text='비밀번호 확인'
				name='checkPassword'
				type='password'
				placeholder='*******'
				isRequired={true}
			/>
			{/* {checkedPassword && checkedPassword} */}
			<Label>Piecial을 어떻게 알게 되셨나요?</Label>
			<SelectContainer>
				<select
					name='path'
					onChange={(e) => {
						setSignupInput({
							...signupInput,
							additionalInfo: {
								[name]: e.target.value,
							},
						});
					}}
				>
					<option value=''></option>
					<option value='인스타그램'>인스타그램</option>
					<option value='페이스북'>페이스북</option>
					<option value='카카오톡 오픈채팅방'>
						카카오톡 오픈채팅방
					</option>
					<option value='공모전 및 대외활동 사이트'>
						공모전 및 대외활동 사이트
					</option>
					<option value='커뮤니티'>커뮤니티</option>
					<option value='지인'>지인</option>
					<option value='기타'>기타</option>
				</select>
			</SelectContainer>
			<CenterSection>
				<CheckBoxContainer>
					<CheckBox>
						<input
							type='checkbox'
							id='terms'
							name='necessary'
							onChange={handlechange}
						/>
						<label htmlFor='terms'>
							이용약관 및 개인정보처리방침 동의 (필수)
						</label>
					</CheckBox>
					<CheckBox>
						<input
							type='checkbox'
							id='marketing'
							name='selection'
							onChange={handlechange}
						/>
						<label htmlFor='marketing'>
							마케팅 정보 수신 동의 (선택)
						</label>
					</CheckBox>
				</CheckBoxContainer>
			</CenterSection>
			<CenterSection>
				<JoinButton onClick={handleClick}>가입하기</JoinButton>
				<Error>{errorMSG}</Error>
			</CenterSection>
		</SignUpContainer>
	);
};
export default SignUp;

const Title = styled.div`
	text-align: center;
	font: normal normal bold 37px/45px Pretendard;
	letter-spacing: -0.37px;
	color: #111111;
	${(p) => p.theme.device.md} {
		font: normal normal bold 22px/26px Pretendard;
		letter-spacing: -0.22px;
		color: #1a1a1a;
	}
`;
const Error = styled.div`
	color: #fe7668;
	text-align: center;
`;
const SignUpContainer = styled.div`
	width: 700px;
	padding-top: 80px;
	padding-bottom: 253px;
	${(props) => props.theme.device.md} {
		width: 100%;
		padding: 43px 24px 128px 24px;
	}
`;
const SelectContainer = styled.div`
	position: relative;
	select {
		width: 100%;
		height: 55px;
		border: 1px solid #bebebe;
		border-radius: 10px;
		font: normal normal 400 16px/19px Pretendard;
		letter-spacing: -0.16px;
		padding: 16px 20px;

		${(props) => props.theme.device.md} {
			height: 41px;
			padding: 12px;
			font: normal normal 400 13px/16px Pretendard;
			letter-spacing: -0.13px;
		}
	}
`;
const CheckBoxContainer = styled.div`
	margin-top: 147px;
	text-align: center;
	${(props) => props.theme.device.md} {
		margin-top: 75px;
	}
`;
const CheckBox = styled.div`
	display: flex;
	align-items: center;
	font: normal normal 500 17px/20px Pretendard;
	letter-spacing: -0.17px;
	color: #333333;
	input {
		width: 16px;
		height: 16px;
		margin-right: 13px;
	}

	& + & {
		margin-top: 20px;
		${(props) => props.theme.device.md} {
			margin-top: 15px;
		}
	}
`;
const Label = styled.div`
	font: normal normal 600 24px/29px Pretendard;
	letter-spacing: -0.48px;
	color: #000000;
	margin-top: 40px;
	margin-bottom: 15px;

	${(props) => props.theme.device.md} {
		font: normal normal bold 17px/20px Pretendard;
		letter-spacing: -0.17px;
		color: #1a1a1a;
		margin-top: 30px;
	}
`;
const JoinButton = styled.button`
	margin-top: 33px;
	width: 367px;
	height: 72px;
	background: #242424 0% 0% no-repeat padding-box;
	border-radius: 10px;
	font: normal normal 600 22px/26px Pretendard;
	letter-spacing: -0.55px;
	color: #ffffff;
`;
const CenterSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
