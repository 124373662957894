import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={33.819}
			height={31}
			viewBox='0 0 33.819 31'
			{...props}
		>
			<path
				data-name='\uD328\uC2A4 423'
				d='M16.91 0C7.571 0 0 5.93 0 13.244c0 4.729 3.165 8.879 7.926 11.221-2.365 8.1-3.754 8.628 5.98 1.814a21.632 21.632 0 003 .209c9.338 0 16.91-5.93 16.91-13.244S26.248 0 16.91 0z'
				fill='#3e2723'
			/>
		</svg>
	);
}

export default SvgComponent;
