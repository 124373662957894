import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import api from 'lib/api';
import { Thumbnail as _Thumbnail } from 'components/common/ArtworkComps';
import { ReactComponent as ArrowSVG } from 'assets/svg/chevron-left.svg';

const RecommendNFT = ({ artworkId }) => {
	const history = useHistory();
	const [nfts, setNfts] = useState([]);

	const getRecommendNFT = useCallback(async () => {
		if (!artworkId) return;

		const {
			response: {
				data: { data },
			},
		} = await api.get(`/artworks/${artworkId}/recommends/`);
		setNfts(data);
	}, [artworkId]);

	useEffect(() => {
		(async () => await getRecommendNFT())();
	}, [getRecommendNFT]);

	if (nfts.length === 0) return <div />;

	return (
		<Wrapper>
			<Title>추천 NFT</Title>
			<PCSwiper>
				<Swiper
					spaceBetween={18}
					slidesPerView={4}
					modules={[Navigation]}
					navigation={{
						nextEl: '.recommend-nft-next',
						prevEl: '.recommend-nft-prev',
					}}
				>
					{nfts.map((nft) => (
						<SwiperSlide key={nft.id}>
							<Thumbnail
								$thumbnail={nft.thumbnail}
								onClick={() =>
									history.push(`/products/detail/${nft.id}`)
								}
							/>
						</SwiperSlide>
					))}
				</Swiper>
				<ArrowSVG className='recommend-nft-prev' />
				<ArrowSVG className='recommend-nft-next' />
			</PCSwiper>
			<MobileSwiper>
				<Swiper
					spaceBetween={7}
					slidesPerView={3}
					pagination={{
						dynamicBullets: true,
					}}
					modules={[Pagination]}
					style={{ width: '100%', height: '117px' }}
				>
					{nfts.map((nft) => (
						<SwiperSlide key={nft.id}>
							<Thumbnail
								$thumbnail={nft.thumbnail}
								onClick={() =>
									history.push(`/products/detail/${nft.id}`)
								}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</MobileSwiper>
		</Wrapper>
	);
};
export default RecommendNFT;

const Wrapper = styled.div`
	margin-top: 118px;
	${(p) => p.theme.device.md} {
		margin-top: 65px;
	}
`;
const Title = styled.div`
	font: normal normal 600 22px/26px Pretendard;
	letter-spacing: -0.22px;
	color: #111111;
	margin-bottom: 27px;
	${(p) => p.theme.device.md} {
		font: normal normal 600 17px/20px Pretendard;
		letter-spacing: -0.17px;
		color: #1a1a1a;
		margin-bottom: 16px;
	}
`;
const Thumbnail = styled(_Thumbnail)`
	cursor: pointer;
	width: 287px;
	height: 287px;
	${(p) => p.theme.device.md} {
		width: 100%;
		height: 100%;
	}
`;
const PCSwiper = styled.div`
	display: block;
	position: relative;

	& > svg {
		position: absolute;
		top: 132px;
		cursor: pointer;

		&.recommend-nft-prev {
			transform: rotate(90deg);
			left: -37px;
		}
		&.recommend-nft-next {
			transform: rotate(270deg);
			right: -37px;
		}
	}
	${(p) => p.theme.device.md} {
		display: none;

		& > svg {
			display: none;
		}
	}
`;
const MobileSwiper = styled.div`
	display: none;
	${(p) => p.theme.device.md} {
		display: block;
	}
`;
