import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import loading from './loading';
import auth, { authSaga } from './auth';
import detail from './detail';
import resistration from './resistration';
import list, { workListSaga } from './list';

const rootReducer = combineReducers({
	auth,
	detail,
	list,
	resistration,
	loading,
});

export function* rootSaga() {
	// all 은 배열 안의 여러 사가를 동시에 실행시켜줍니다.
	yield all([authSaga(), workListSaga()]);
}

export default rootReducer;
