// import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import useCategory from 'hooks/useCategory';
// import { setResistration } from 'modules/resistration';
// import { startLoading, finishLoading } from 'modules/loading';

import CategoryField from './CategoryField';
import { useEffect } from 'react';

/**
 * react-hook-form의 useForm객체를 전달받아 사용합니다.
 * https://react-hook-form.com/get-started
 */
const WorkInfo = ({
	register,
	handleSubmit,
	formState: { errors },
	watch,
	updateMode,
	isOrderExists,
	handleNext,
}) => {
	const dispatch = useDispatch();

	const categories = useCategory();
	const { nftValue, thumbnail } = watch();

	const [nftPreview, setNftPreview] = useState('');
	const [thumbnailPreview, setThumbnailPreview] = useState('');

	// const isImageForamt = (file) => {
	// 	console.log(file);
	// 	return true;
	// };

	// const uploadImage = async (e) => {
	// 	const file = e.target.files[0];
	// 	const isLt50M = file?.size / 1024 / 1024 > 50;

	// 	if (isLt50M) {
	// 		alert('파일 사이즈가 50MB 이상입니다.');
	// 		dispatch(finishLoading('FINISH'));
	// 		return false;
	// 	}

	// 	if (file) {
	// 		const nftFormData = new FormData();
	// 		nftFormData.append('file', file);

	// 		await axios.post(NFT_URL, nftFormData).then((response) => {
	// 			setValue('nftValue', response.data.fileUrl);
	// 		});
	// 	}
	// };

	// const uploadThumbnail = async (e) => {
	// 	const file = e.target.files[0];
	// 	const isLt50M = file?.size / 1024 / 1024 > 2;
	// 	const isJpgOrPng = /jpeg|png|jpg/.test(file?.type);

	// 	if (file && !isJpgOrPng) {
	// 		alert('대표썸네일은 JPG / PNG 형식만 가능합니다.');
	// 		dispatch(finishLoading('FINISH'));
	// 		return false;
	// 	}
	// 	if (isLt50M) {
	// 		alert('대표썸네일은 2MB 이내의 파일을 등록해주세요.');
	// 		dispatch(finishLoading('FINISH'));
	// 		return false;
	// 	}

	// 	if (file) {
	// 		const thumbnailformData = new FormData();
	// 		thumbnailformData.append('file', file);

	// 		await axios
	// 			.post(THUMBNAIL_URL, thumbnailformData)
	// 			.then((response) => {
	// 				setValue('thumbnail', response.data.fileUrl);
	// 			});
	// 	}
	// };

	useEffect(() => {
		if (nftValue && nftValue.length > 0) {
			setNftPreview(URL.createObjectURL(nftValue[0]));

			if (typeof nftValue === 'string') {
				setNftPreview(nftValue);
			} else {
				setNftPreview(URL.createObjectURL(nftValue[0]));
			}
		}
	}, [nftValue]);

	useEffect(() => {
		if (thumbnail && thumbnail.length > 0) {
			if (typeof thumbnail === 'string') {
				setThumbnailPreview(thumbnail);
			} else {
				setThumbnailPreview(URL.createObjectURL(thumbnail[0]));
			}
		}
	}, [thumbnail]);

	return (
		<Wrapper>
			<form id='workinfo-form' onSubmit={handleSubmit(handleNext)}>
				<InputRow>
					<label>
						신규 NFT 등록<span>*</span>
					</label>
					<Thumbnail>
						<div className='preview'>
							{nftPreview && <img src={nftPreview} alt='' />}
						</div>
						{!updateMode && (
							<>
								<input
									type='file'
									id='upload'
									{...register('nftValue', {
										required: true,
										validate: {
											isSizeLimit: (v) => {
												const isValid =
													v[0].size / 1024 / 1024 <
													50;
												if (!isValid) {
													alert(
														'파일 사이즈가 50MB 이상입니다.',
													);
												}
												return isValid;
											},
										},
									})}
									// onChange={uploadImage}
									style={{ display: 'none' }}
									disabled={updateMode}
								/>
								<label
									className='upload-button'
									htmlFor='upload'
								>
									파일 선택
								</label>
								<div className='file-info'>
									{nftPreview
										? nftValue[0].name
										: '선택된 파일 없음'}{' '}
									(50MB 이하의 이미지, 영상, 음원 파일 등록
									가능)
								</div>
							</>
						)}
					</Thumbnail>
				</InputRow>

				<InputRow>
					<label>
						카테고리<span>*</span>
					</label>
					<CategoryWrapper>
						{categories.map((category) => (
							<CategoryField
								key={category.id}
								label={category.name}
								value={category.code}
								disabled={updateMode}
								{...register('category', {
									required: !updateMode,
								})}
							/>
						))}
					</CategoryWrapper>
				</InputRow>

				<InputRow>
					<label>
						작품명<span>*</span>
					</label>
					<Input
						placeholder='작품명을 입력해 주세요.'
						{...register('name', { required: true })}
						disabled={updateMode}
					/>
				</InputRow>

				<InputRow>
					<label>
						금액(원)<span>*</span>
					</label>
					<Input
						$small={true}
						placeholder='숫자를 입력해 주세요.'
						disabled={updateMode && isOrderExists}
						{...register('price', {
							required: true,
						})}
						onKeyPress={(e) => {
							if (!/^[0-9]+$/.test(e.key)) {
								alert('금액은 숫자만 입력 할 수 있습니다.');
								e.preventDefault();
							}
						}}
					/>
				</InputRow>

				<InputRow>
					<label>
						발행 수량(개)<span>*</span>
					</label>
					<Input
						$small={true}
						placeholder='숫자를 입력해 주세요.'
						disabled={updateMode && isOrderExists}
						{...register('amount', {
							required: true,
							// validate: (value) =>
							// 	!/^[0-9]+$/.test(value) &&
							// 	alert(
							// 		'발행 수량은 숫자만 입력 할 수 있습니다.',
							// 	),
						})}
						onKeyPress={(e) => {
							if (!/^[0-9]+$/.test(e.key)) {
								alert(
									'발행 수량은 숫자만 입력 할 수 있습니다.',
								);
								e.preventDefault();
							}
						}}
					/>
				</InputRow>

				<InputRow>
					<label>
						작품 설명 (Description)<span>*</span>
					</label>
					<Textarea
						placeholder='NFT 메타 데이터에 등록되는 정보로 작품의 형태, 수량 등 세부사항을 기입해 주세요.'
						disabled={updateMode && isOrderExists}
						{...register('description', { required: true })}
					/>
				</InputRow>

				<InputRow>
					<label>추가 작품 설명</label>
					<Textarea
						placeholder='메타 데이터 외 작품의 추가 설명을 작성해 주세요.'
						{...register('additionalDesc')}
					/>
				</InputRow>

				<InputRow>
					<label>
						대표 썸네일<span>*</span>
					</label>
					<Thumbnail>
						<div className='preview'>
							{thumbnailPreview && (
								<img src={thumbnailPreview} alt='' />
							)}
						</div>
						{!updateMode && (
							<>
								<input
									type='file'
									id='thumbnail'
									{...register('thumbnail', {
										required: true,
										validate: {
											isJpgOrPng: (v) => {
												const isValid =
													/jpeg|png|jpg|gif/.test(
														v[0].type,
													);
												if (!isValid) {
													alert(
														'대표썸네일은 JPG / PNG / GIF 형식만 가능합니다.',
													);
												}
												return isValid;
											},
											isSizeLimit: (v) => {
												const isValid =
													v[0].size / 1024 / 1024 < 2;
												if (!isValid) {
													alert(
														'대표썸네일은 2MB 이내의 파일을 등록해주세요.',
													);
												}
												return isValid;
											},
										},
									})}
									// onChange={uploadThumbnail}
									style={{ display: 'none' }}
									disabled={updateMode}
								/>
								<label
									className='upload-button'
									htmlFor='thumbnail'
								>
									파일 선택
								</label>

								<div className='file-info'>
									{thumbnailPreview
										? thumbnail[0].name
										: '선택된 파일 없음'}{' '}
									(이미지 용량: 2MB 이하 | 형식: JPG, PNG, GIF
									| 이미지 비율 1:1 권장)
								</div>
							</>
						)}
					</Thumbnail>
				</InputRow>

				<Actions>
					{/* <ActionButton
					// loading={loading}
					// onClick={loading ? null : handleClick}
					>
						이전으로
					</ActionButton> */}

					<ActionButton type='submit' $isActive={true}>
						다음으로
					</ActionButton>
				</Actions>
				{Object.keys(errors).length > 0 && (
					<Error>일부 항목 입력이 유효하지 않습니다.</Error>
				)}

				{/* <Title>신규 NFT 등록</Title>
			<Container>
				<input
					type='file'
					id='upload'
					onChange={uploadImage}
					style={{ display: 'none' }}
				/>
				{uploadedName && uploadedName}
				<ResistrationButton for='upload'>파일 선택</ResistrationButton>
			</Container> */}
				{/* TODO: NFT 관련 */}
				{/* {!newNFTStatus && (
					<>
						<Title>기존 NFT 등록</Title>
						<Container>
							<ResistrationButton
								name='existingNft'
								multiple={true}
								// beforeUpload={beforeUpload}
								customRequest={dummyRequest}
								onChange={handleExistNft}
							>
								<div className='button'>가져오기</div>
							</ResistrationButton>
							{!existingNft && (
								<FileStatus>가져온 NFT 없음</FileStatus>
							)}
						</Container>
					</>
							)} */}

				{/* <Title>
				대표 썸네일<span>*</span>
			</Title>
			<Thumbnail>
				<Preview>
					{preview ? <img src={preview} alt='' /> : '대표 썸네일'}
				</Preview>
				<input
					type='file'
					id='thumbnail'
					onChange={uploadThumbnail}
					style={{ display: 'none' }}
				/>
				<ResistrationButton for='thumbnail'>
					파일 선택
				</ResistrationButton>
			</Thumbnail>
			{thumbnailName && thumbnailName} */}
			</form>
		</Wrapper>
	);
};

const Wrapper = styled.div``;

const InputRow = styled.div`
	& > label {
		font: normal normal 600 24px/29px Pretendard;
		letter-spacing: -0.48px;
		color: #000000;
		margin-bottom: 15px;
		display: block;

		${(props) => props.theme.device.md} {
			font: normal normal bold 17px/20px Pretendard;
			letter-spacing: -0.17px;
			color: #1a1a1a;
		}

		& > span {
			color: #6b98f2;
		}
	}

	& + & {
		margin-top: 40px;
	}
`;
const CategoryWrapper = styled.div`
	display: flex;
	gap: 23px;
	flex-wrap: wrap;

	${(props) => props.theme.device.md} {
		gap: 8px;
	}
`;
const Input = styled.input`
	width: ${(p) => (p.$small ? `407px` : '100%')};
	height: 55px;
	border: 1px solid #bebebe;
	border-radius: 10px;
	padding: 18px 20px;
	font: normal normal normal 16px/19px Pretendard;
	letter-spacing: -0.16px;

	&::placeholder {
		color: #bebebe;
	}

	${(props) => props.theme.device.md} {
		width: 100%;
		height: 41px;
		font: normal normal normal 13px/16px Pretendard;
		letter-spacing: -0.13px;
	}
`;
const Textarea = styled.textarea`
	width: 100%;
	height: 355px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #bebebe;
	border-radius: 10px;
	padding: 18px 20px;
	font: normal normal normal 16px/19px Pretendard;
	letter-spacing: -0.16px;

	${(props) => props.theme.device.md} {
		height: 366px;
		font: normal normal normal 13px/16px Pretendard;
		letter-spacing: -0.13px;
	}
`;
const Thumbnail = styled.div`
	width: 100%;
	position: relative;

	& > .upload-button {
		position: absolute;
		top: 123px;
		left: 82px;
		width: 119px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #242424 0% 0% no-repeat padding-box;
		border-radius: 4px;
		font: normal normal 500 16px/19px Pretendard;
		letter-spacing: -0.4px;
		color: #ffffff;
	}
	& > .preview {
		width: 282px;
		height: 282px;
		border: 1px solid #bebebe;
		border-radius: 10px;

		& > img {
			width: 100%;
			height: 100%;
		}
	}
	& > .file-info {
		margin-top: 18px;
		font: normal normal 400 16px/19px Pretendard;
		letter-spacing: -0.16px;
		color: #777777;

		${(props) => props.theme.device.md} {
			font: normal normal 500 14px/17px Pretendard;
			letter-spacing: -0.14px;
		}
	}

	${(props) => props.theme.device.md} {
		flex-direction: column;

		& > .upload-button {
			width: 88px;
			height: 25px;
			font: normal normal 500 14px/17px Pretendard;
			letter-spacing: -0.28px;
			top: 83px;
			left: 51px;
		}
		& > .preview {
			width: 190px;
			height: 190px;
		}
		& > .file-info {
			font: normal normal 500 14px/17px Pretendard;
			letter-spacing: -0.14px;
		}
	}
`;
const Actions = styled.div`
	margin-top: 145px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;

	${(props) => props.theme.device.md} {
		flex-direction: column;
		justify-content: center;
		margin-bottom: 20px;
	}
`;
const ActionButton = styled.button`
	width: 367px;
	height: 72px;
	background: ${(p) => (p.$isActive ? '#242424' : '#ffffff')};
	border-radius: 10px;
	font: normal normal 600 22px/48px Pretendard;
	letter-spacing: -0.55px;
	border: ${(p) => (p.$isActive ? 'none' : '1px solid #777777')};
	color: ${(p) => (p.$isActive ? '#ffffff' : '#000000')};

	${(props) => props.theme.device.md} {
		height: 47px;
		background: ${(p) => (p.$isActive ? '#1A1A1A' : '#ffffff')};
		font: normal normal 400 17px/20px Pretendard;
		letter-spacing: -0.17px;
	}
`;
const Error = styled.div`
	margin-top: 22px;
	text-align: center;
	font: normal normal 400 18px/21px Pretendard;
	letter-spacing: 0px;
	color: #fe7668;
	${(props) => props.theme.device.md} {
		margin-top: 12px;
		font: normal normal 500 14px/19px Pretendard;
		letter-spacing: -0.14px;
	}
`;
export default WorkInfo;
