import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import API from 'lib/api';
import { startLoading, finishLoading } from 'modules/loading';

const useNotices = (searchType, search) => {
	const dispatch = useDispatch();
	const [noticeData, setNoticeData] = useState([]);

	useEffect(() => {
		dispatch(startLoading('START'));
		API.get(`/forums/notice-articles/`, {
			search_type: searchType,
			search,
			per_page: 3000,
		}).then((res) => {
			// setPage({
			// 	...page,
			// 	totalPage: res.length / pageSize,
			// 	minIndex: 0,
			// 	maxIndex: pageSize,
			// });
			dispatch(finishLoading('FINISH'));
			setNoticeData(res.response.data.results);
		});
	}, [dispatch, searchType, search]);

	return noticeData;
};
export default useNotices;
