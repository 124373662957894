import React from 'react';
import styled from 'styled-components';

// TODO: 삭제할 것?

const UnderlineTitle = ({ title, addContent }) => {
	return (
		<TitleContainer>
			<Title>
				{title}
				{addContent && addContent}
			</Title>
		</TitleContainer>
	);
};
const TitleContainer = styled.div`
	${(props) => props.theme.device.md} {
		padding: 0 16px;
	}
`;
const Title = styled.h2`
	display: flex;
	justify-content: space-between;
	width: 100%;
	font-size: 18px;
	padding-bottom: 20px;
	border-bottom: 1px solid #000;
`;
export default UnderlineTitle;
