import { call, put } from 'redux-saga/effects';
import { startLoading, finishLoading } from '../modules/loading';
// import { TIMEOUT_LOGOUT } from '../modules/auth';

// Create Request Action Types
export const createActionTypes = (type) => {
	const SUCCESS = `${type}_SUCCESS`;
	const FAILURE = `${type}_FAILURE`;

	return [type, SUCCESS, FAILURE];
};

export const createRequestSaga = (type, request) => {
	const SUCCESS = `${type}_SUCCESS`;
	const FAILURE = `${type}_FAILURE`;

	return function* (action) {
		yield put(startLoading(type));
		const { response } = yield call(request, action.payload);

		if (response) {
			yield put({ type: SUCCESS, payload: response.data });
		} else if (!response || !response.data.message) {
			yield put({
				type: FAILURE,
				error: {
					message:
						'서버에 일시적인 오류가 발생했습니다. 나중에 다시 시도해주세요.',
				},
			});
		} else {
			yield put({ type: FAILURE, error: response.data });
		}
		yield put(finishLoading(type));
	};
};
