import { useState } from 'react';
import styled from 'styled-components';

import api from 'lib/api';

const FindPassword = () => {
	const [email, setEmail] = useState('');

	const sendChangePasswordMail = async () => {
		if (email === '') {
			alert('이메일을 입력해 주세요.');
			return;
		}

		try {
			await api.post('/password_reset/', {
				email,
			});
			alert('비밀번호 변경 이메일이 전송되었습니다.');
		} catch (e) {
			alert('계정의 이메일 주소를 입력하세요.');
		}
	};

	return (
		<FindPasswordContainer>
			<Title>새로운 비밀번호를 설정합니다</Title>
			<p>
				계정의 이메일 주소를 입력하시면
				<br />
				비밀번호를 재설정 할 수 있는 링크를 이메일로 보내드립니다.
			</p>
			<InputTitle>이메일</InputTitle>
			<Input
				placeholder='example@domain.com'
				value={email}
				onChange={(e) => setEmail(e.target.value)}
			/>
			<ButtonContainer>
				<Button onClick={sendChangePasswordMail}>메일 발송</Button>
			</ButtonContainer>
		</FindPasswordContainer>
	);
};
const FindPasswordContainer = styled.div`
	padding: 118px 0 252px 0;
	p {
		font: normal normal 400 18px/28px Pretendard;
		letter-spacing: -0.09px;
		color: #777777;
		text-align: center;
	}

	${(p) => p.theme.device.md} {
		padding: 35px 24px 128px 24px;
		p {
			font: normal normal 500 16px/25px Pretendard;
			letter-spacing: -0.16px;
			color: #777777;
			text-align: left;
		}
	}
`;
const Title = styled.div`
	font: normal normal bold 37px/45px Pretendard;
	letter-spacing: 0px;
	color: #111111;
	margin-bottom: 26px;
	text-align: center;
	${(p) => p.theme.device.md} {
		font: normal normal bold 24px/34px Pretendard;
		letter-spacing: -0.24px;
		color: #1a1a1a;
		text-align: left;
		width: 171px;
	}
`;
const Input = styled.input`
	width: 569px;
	height: 55px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 0.5px solid #bebebe;
	border-radius: 10px;
	padding: 18px 20px;

	${(p) => p.theme.device.md} {
		width: 100%;
		height: 41px;
		border: 1px solid #bebebe;
		border-radius: 10px;
		font: normal normal 400 13px/16px Pretendard;
		letter-spacing: -0.13px;
		padding: 12px 17px;
	}
`;
const InputTitle = styled.div`
	font: normal normal 600 24px/29px Pretendard;
	letter-spacing: -0.24px;
	color: #000000;
	margin-top: 99px;
	margin-bottom: 15px;

	${(p) => p.theme.device.md} {
		font: normal normal bold 17px/20px Pretendard;
		letter-spacing: -0.17px;
		color: #1a1a1a;
		margin-top: 45px;
	}
`;
const ButtonContainer = styled.div`
	margin-top: 146px;
	display: flex;
	justify-content: center;

	${(p) => p.theme.device.md} {
		margin-top: 45px;
	}
`;
const Button = styled.button`
	width: 367px;
	height: 72px;
	background: #242424 0% 0% no-repeat padding-box;
	border-radius: 10px;
	font: normal normal 600 22px/26px Pretendard;
	letter-spacing: -0.55px;
	color: #ffffff;

	${(p) => p.theme.device.md} {
		width: 100%;
		height: 47px;
		background: #1a1a1a 0% 0% no-repeat padding-box;
		border-radius: 10px;
		font: normal normal 400 17px/20px Pretendard;
		letter-spacing: -0.17px;
		color: #ffffff;
	}
`;
export default FindPassword;
