import axios from 'axios';
import * as storage from './storage';

const HOST = '';
// const multipartHeaders = { headers: { 'Content-Type': 'multipart/form-data' } };

const getAuthHeader = () => {
	const isAuth = storage.isAuth();
	let token = JSON.parse(localStorage.getItem('token'));

	return isAuth ? `Impact ${token}` : null;
};

export const Axios = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

Axios.interceptors.request.use(
	(config) => {
		config.headers['Authorization'] = getAuthHeader();

		if (config?.body) {
			config.data = config.body;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

Axios.interceptors.response.use(
	(response) => {
		return Promise.resolve({ response: response });
	},

	(error) => {
		return Promise.reject(error);
	},
);

class API {
	static auth(url, payload) {
		const apiUrl = `${HOST}${url}`;
		const headers = {
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		};
		return Axios.post(apiUrl, payload, headers);
	}

	static get(url, payload) {
		const apiUrl = `${HOST}${url}`;
		return Axios.get(apiUrl, { params: payload });
	}

	static post(url, payload) {
		const apiUrl = `${HOST}${url}`;
		return Axios.post(apiUrl, payload);
	}

	static patch(url, payload) {
		const apiUrl = `${HOST}${url}`;
		return Axios.patch(apiUrl, payload);
	}

	static put(url, payload) {
		const apiUrl = `${HOST}${url}`;
		return Axios.put(apiUrl, payload);
	}

	static delete(url, payload) {
		const apiUrl = `${HOST}${url}`;
		return Axios.delete(apiUrl, { params: payload });
	}
}

export default API;
