import styled from 'styled-components';
import { Section } from 'components/Checkout';

const PaymentInfo = ({ purchaseType, quantity, price }) => (
	<Section label='결제 정보'>
		<Wrapper>
			<PurchaseType>{purchaseType}</PurchaseType>
			<InfoItem justify>
				<div className='label'>{Number(quantity)}개</div>
				<div className='bold'>
					{(quantity * price).toLocaleString()}원
				</div>
			</InfoItem>
			<TotalPrice>
				<div>최종 결제 금액</div>
				<div className='price'>
					{(quantity * price).toLocaleString()}원
				</div>
			</TotalPrice>
		</Wrapper>
	</Section>
);
export default PaymentInfo;

const Wrapper = styled.div`
	width: 100%;
	${(p) => p.theme.device.md} {
		border: 1px solid #bebebe;
		border-radius: 10px;
		padding: 26px 24px;
	}
`;
const PurchaseType = styled.div`
	font: normal normal 400 20px/24px Pretendard;
	letter-spacing: -0.4px;
	color: #666666;
	margin-bottom: 27px;

	${(p) => p.theme.device.md} {
		font: normal normal 600 15px/18px Pretendard;
		letter-spacing: -0.15px;
		color: #1a1a1a;
		margin-bottom: 16px;
	}
`;

const InfoItem = styled.div`
	flex-basis: 100%;
	display: flex;
	justify-content: space-between;

	& > .label {
		font: normal normal 500 24px/29px Pretendard;
		letter-spacing: -0.48px;
		color: #111111;
	}
	& > .bold {
		font: normal normal bold 24px/29px Pretendard;
		letter-spacing: -0.48px;
		color: #111111;
	}

	${(p) => p.theme.device.md} {
		justify-content: flex-end;
		font: normal normal 600 15px/18px Pretendard;
		letter-spacing: -0.15px;
		color: #1a1a1a;

		& > .label {
			font: normal normal 500 15px/24px Pretendard;
			letter-spacing: -0.15px;
			color: #777777;
		}
		& > .bold {
			font: normal normal bold 20px/24px Pretendard;
			letter-spacing: -0.2px;
			color: #1a1a1a;
			margin-left: 11px;
		}
	}
`;
const TotalPrice = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px dashed #bebebe;
	font: normal normal bold 30px/36px Pretendard;
	letter-spacing: -0.75px;
	color: #111111;
	padding-top: 47px;
	margin-top: 26px;

	& > .price {
		font: normal normal bold 34px/41px Pretendard;
		letter-spacing: -0.85px;
		color: #111111;
	}

	${(p) => p.theme.device.md} {
		justify-content: flex-end;
		border-top: 1px solid #bebebe;
		font: normal normal 500 15px/27px Pretendard;
		letter-spacing: -0.15px;
		color: #777777;
		padding-top: 32px;
		margin-top: 30px;

		& > .price {
			font: normal normal bold 23px/27px Pretendard;
			letter-spacing: -0.23px;
			color: #1a1a1a;
			margin-left: 12px;
		}
	}
`;
