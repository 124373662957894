import styled from 'styled-components';

const Section = ({ label, style, children }) => (
	<Wrapper style={style}>
		<h2>{label}</h2>
		<div>{children}</div>
	</Wrapper>
);

export default Section;

const Wrapper = styled.section`
	display: flex;
	padding: 48px 0 62px 0;
	border-bottom: 1px solid #bebebe;

	& > h2 {
		font: normal normal bold 24px/29px Pretendard;
		letter-spacing: -0.12px;
		color: #000000;
		width: 204px;
	}

	& > div {
		width: 100%;
	}

	${(p) => p.theme.device.md} {
		display: block;
		padding: 0;
		border-bottom: none;
		margin-bottom: 46px;

		& > h2 {
			width: 100%;
			font: normal normal 500 16px/19px Pretendard;
			letter-spacing: -0.16px;
			color: #1a1a1a;
			padding-bottom: 16px;
			border-bottom: 1px solid #bebebe;
		}

		& > div {
			padding-top: 20px;
		}
	}
`;
