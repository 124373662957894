import React from 'react';
import styled from 'styled-components';

// TODO: 삭제?
const Category = ({ categoryList, currentCategory, setcurrentCategory }) => {
	const handleClick = (value) => {
		setcurrentCategory(value);
	};
	return (
		<CategoryContainer>
			<ListCategory>
				{categoryList.map((item, index) => {
					return (
						<List
							active={item.name === currentCategory}
							key={index}
							onClick={() => handleClick(item.code)}
						>
							{item.name}
						</List>
					);
				})}
			</ListCategory>
		</CategoryContainer>
	);
};
const CategoryContainer = styled.div`
	width: 1200px;
	margin: 0 auto 10px;
	display: flex;
	justify-content: space-between;
	a {
		font-size: 20px;
		color: #333;
	}
	${(props) => props.theme.device.md} {
		width: 100%;
		padding: 0 16px;
		margin: 0;
		overflow: hidden;
	}
`;
const ListCategory = styled.ul`
	display: flex;
`;
const List = styled.li`
	color: ${(props) => (props.active ? '#6B98F2' : '#333')};
	font-weight: 600;
	font-size: 20px;
	cursor: pointer;
	margin-right: 50px;
	&:last-child {
		margin-right: 0;
	}
	${(props) => props.theme.device.md} {
		white-space: nowrap;
		margin-right: 24px;
		font-size: 15px;
	}
`;
export default Category;
