import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';

import { Wrapper } from 'components/Notice/StyledComps';
import { Header } from 'components/Notice';
import useNotice from 'hooks/useNotice';

import { ReactComponent as ViewSVG } from 'assets/svg/eye.svg';

const NoticeDetail = () => {
	const history = useHistory();
	const currContent = useLocation().search;
	const id = new URLSearchParams(currContent).get('id');

	const noticeDetail = useNotice(id);
	const handleClick = () => history.push(`/notice`);

	const { title, created, nickname, viewCount, body, bodyMobile } =
		noticeDetail || {};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Wrapper>
			<Header />

			<Body>
				<Title>{title}</Title>
				<InfoRow>
					<Nickname>{nickname}</Nickname>
					<DateViewCountRow>
						<Date>{moment(created).format('YY.MM.DD')}</Date>
						<ViewCount>
							<ViewSVG />
							{viewCount}
						</ViewCount>
					</DateViewCountRow>
				</InfoRow>

				<MobileTitle>{title}</MobileTitle>
				<Content
					dangerouslySetInnerHTML={{
						__html: body,
					}}
				/>
				<ContentMobile
					dangerouslySetInnerHTML={{
						__html: bodyMobile,
					}}
				/>
			</Body>

			<Footer>
				<GoListButton onClick={handleClick}>목록으로</GoListButton>
			</Footer>
		</Wrapper>
	);
};
export default NoticeDetail;

const Body = styled.div`
	margin-top: 96px;

	${(p) => p.theme.device.md} {
		margin-top: 27px;
	}
`;
const Title = styled.div`
	height: 26px;
	font-weight: bold;
	font-size: 22px;
	letter-spacing: -0.22px;
	color: #000000;
	margin-bottom: 16px;
	${(p) => p.theme.device.md} {
		display: none;
	}
`;
const MobileTitle = styled.div`
	display: none;
	${(p) => p.theme.device.md} {
		display: block;
		font: normal normal bold 22px/26px Pretendard;
		letter-spacing: -0.22px;
		color: #1a1a1a;
		margin-bottom: 28px;
	}
`;
const InfoRow = styled.div`
	display: flex;
	margin-bottom: 50px;

	${(props) => props.theme.device.md} {
		display: block;
		padding-bottom: 30px;
		border-bottom: 1px solid #bebebe;
	}
`;
const DateViewCountRow = styled.div`
	display: flex;
	${(props) => props.theme.device.md} {
		margin-top: 8px;
	}
`;
const Nickname = styled.div`
	line-height: 19px;
	font-weight: medium;
	font-size: 16px;
	letter-spacing: -0.16px;
	color: #000000;
	padding-right: 36px;
	border-right: 1px solid #bebebe;

	${(props) => props.theme.device.md} {
		line-height: 16px;
		font-weight: 600;
		font-size: 13px;
		letter-spacing: 0px;
		color: #1a1a1a;
	}
`;
const Date = styled.span`
	line-height: 19px;
	font-size: 16px;
	letter-spacing: 0px;
	color: #777777;
	padding: 0 36px;
	border-right: 1px solid #bebebe;

	${(props) => props.theme.device.md} {
		line-height: 16px;
		font-size: 13px;
		padding: 0;
		padding-right: 8px;
		border-right: none;
	}
`;
const ViewCount = styled(Date)`
	border-right: none;
	& > svg {
		margin-right: 5px;
	}

	${(props) => props.theme.device.md} {
		padding-left: 12px;
	}
`;
const Content = styled.div`
	font-size: 16px;
	letter-spacing: -0.16px;
	color: #111111;
	line-height: 30px;
	margin-bottom: 146px;

	${(props) => props.theme.device.md} {
		display: none;
	}
`;
const ContentMobile = styled.div`
	display: none;
	${(props) => props.theme.device.md} {
		display: block;
		font: normal normal 400 16px/24px Pretendard;
		letter-spacing: 0px;
		color: #1a1a1a;
		margin-bottom: 128px;
	}
`;
const Footer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 252px;
`;
const GoListButton = styled.button`
	width: 367px;
	height: 72px;
	background: #242424 0% 0% no-repeat padding-box;
	border-radius: 10px;
	font-weight: 600;
	font-size: 22px;
	letter-spacing: -0.55px;
	color: #ffffff;

	${(props) => props.theme.device.md} {
		display: none;
	}
`;
