import React, { useState, useEffect } from 'react';
import { Carousel } from 'antd';
import styled from 'styled-components';

import api from 'lib/api';

const BannerSpinner = () => {
	const [banners, setBanners] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const { response } = await api.get('/main-banners/');
				setBanners(response.data.data);
			} catch (e) {
				setBanners([]);
			}
		})();
	}, []);

	return (
		<Wrapper>
			<Carousel autoplay>
				{banners.map((item, idx) => (
					<BannerImage
						key={idx}
						image={item.image}
						imageMobile={item.imageMobile}
						onClick={() => window.open('https://' + item.link)}
					/>
				))}
			</Carousel>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100%;
	height: 700px;
	${(props) => props.theme.device.md} {
		height: 323px;
	}
`;
const BannerImage = styled.div`
	width: 100%;
	height: 700px;
	background-size: cover;

	background-image: ${({ image }) => `url(${image})`};

	${(props) => props.theme.device.md} {
		background-image: ${({ imageMobile }) => `url(${imageMobile})`};
		height: 323px;
	}
`;
export default BannerSpinner;
