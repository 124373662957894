import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import api from 'lib/api';
import { Thumbnail as _Thumbnail } from 'components/common/ArtworkComps';
import { ReactComponent as ArrowSVG } from 'assets/svg/chevron-left.svg';

import 'swiper/swiper.min.css';

const ArtistNews = ({ artistId }) => {
	const history = useHistory();
	const [news, setnews] = useState([]);

	const fetchNews = useCallback(async () => {
		if (!artistId) return;

		const {
			response: {
				data: { data },
			},
		} = await api.get(`/artists/${artistId}/news/`);
		setnews(data);
	}, [artistId]);

	useEffect(() => {
		(async () => await fetchNews())();
	}, [fetchNews]);

	if (news.length === 0) return <div />;

	return (
		<Wrapper>
			<Title>아티스트 뉴스</Title>
			<PCSwiper>
				<Swiper
					spaceBetween={18}
					slidesPerView={4}
					modules={[Navigation]}
					navigation={{
						nextEl: '.artwork-news-next',
						prevEl: '.artwork-news-prev',
					}}
				>
					{news.map((newsItem) => (
						<SwiperSlide key={newsItem.id}>
							<News
								onClick={() =>
									window.open(newsItem.link, '_blank')
								}
							>
								<div className='title'>{newsItem.title}</div>
								<div className='description'>
									{newsItem.description}
								</div>
							</News>
						</SwiperSlide>
					))}
				</Swiper>
				<ArrowSVG className='artwork-news-prev' />
				<ArrowSVG className='artwork-news-next' />
			</PCSwiper>
			<MobileSwiper>
				<Swiper
					spaceBetween={7}
					slidesPerView={3}
					pagination={true}
					modules={[Pagination]}
				>
					{news.map((newsItem) => (
						<SwiperSlide key={newsItem.id}>
							<News
								onClick={() =>
									window.open(newsItem.link, '_blank')
								}
							>
								<div className='title'>{newsItem.title}</div>
								<div className='description'>
									{newsItem.description}
								</div>
							</News>
						</SwiperSlide>
					))}
				</Swiper>
			</MobileSwiper>
		</Wrapper>
	);
};
export default ArtistNews;

const Wrapper = styled.div`
	margin-top: 118px;
	margin-bottom: 260px;
	${(p) => p.theme.device.md} {
		margin-top: 55px;
		margin-bottom: 128px;
	}
`;
const Title = styled.div`
	font: normal normal 600 22px/26px Pretendard;
	letter-spacing: -0.22px;
	color: #111111;
	margin-bottom: 27px;
	${(p) => p.theme.device.md} {
		font: normal normal 600 17px/20px Pretendard;
		letter-spacing: -0.17px;
		color: #1a1a1a;
		margin-bottom: 16px;
	}
`;
const News = styled.div`
	width: 287px;
	height: 364px;
	padding: 36px 33px;
	background: #f8f9fa 0% 0% no-repeat padding-box;
	cursor: pointer;
	border-radius: 15px;

	& > .title {
		font: normal normal 600 18px/22px Pretendard;
		letter-spacing: -0.18px;
		color: #1a1a1a;
		margin-bottom: 31px;
	}
	& > .description {
		font: normal normal 400 15px/26px Pretendard;
		letter-spacing: -0.15px;
		color: #242424;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 36px;
			display: block;
			background-color: #f8f9fa;
		}
	}
	${(p) => p.theme.device.md} {
		width: 117px;
		height: 149px;
		padding: 16px;
		border-radius: 10px;

		& > .title {
			font: normal normal 500 15px/18px Pretendard;
			letter-spacing: -0.15px;
			color: #1a1a1a;
			margin-bottom: 12px;
		}
		& > .description {
			font: normal normal 400 13px/16px Pretendard;
			letter-spacing: -0.13px;
			color: #777777;
			padding-bottom: 16px;
			height: 87px;
			overflow: hidden;

			&::after {
				height: 16px;
			}
		}
	}
`;
const PCSwiper = styled.div`
	display: block;
	position: relative;

	& > svg {
		position: absolute;
		top: 132px;
		cursor: pointer;

		&.artwork-news-prev {
			transform: rotate(90deg);
			left: -37px;
		}
		&.artwork-news-next {
			transform: rotate(270deg);
			right: -37px;
		}
	}
	${(p) => p.theme.device.md} {
		display: none;

		& > svg {
			display: none;
		}
	}
`;
const MobileSwiper = styled.div`
	display: none;
	${(p) => p.theme.device.md} {
		display: block;
	}
`;
