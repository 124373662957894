import styled from 'styled-components';

import { ReactComponent as SearchSVG } from 'assets/svg/search-mini.svg';

const MobileSearchField = ({ value, onChange }) => {
	return (
		<Wrapper>
			<SearchSVG />
			<Input
				value={value}
				placeholder='Search'
				onChange={(e) => onChange(e.target.value)}
			/>
		</Wrapper>
	);
};
export default MobileSearchField;

const Wrapper = styled.div`
	display: none;
	position: relative;

	& > svg {
		position: absolute;
		top: 13px;
		left: 16px;
	}

	${(p) => p.theme.device.md} {
		display: block;
		/* margin-top: 49px; */
		margin-bottom: 16px;
	}
`;
const Input = styled.input`
	width: 100%;

	height: 43px;
	background: #f8f8f8 0% 0% no-repeat padding-box;
	border-radius: 10px;

	font: normal normal medium 14px/17px Pretendard;
	letter-spacing: -0.14px;
	color: #bebebe;
	padding: 13px 44px;
`;
