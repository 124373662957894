import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import API from 'lib/api';
import { startLoading, finishLoading } from 'modules/loading';

const BASE_URL = '/forums/notice-articles';

const useNotice = (id) => {
	const dispatch = useDispatch();
	const [noticeDetail, setNoticeDetail] = useState({});

	useEffect(() => {
		if (!id || id === undefined) return;

		dispatch(startLoading('START'));

		API.get(`${BASE_URL}/${id}`).then((response) => {
			setNoticeDetail(response.response.data.data);
		});
		API.post(`${BASE_URL}/${id}/viewed/`);

		dispatch(finishLoading('FINISH'));
	}, [id, dispatch]);

	return noticeDetail;
};
export default useNotice;
