import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { CenterLayout, SubTitleRow } from 'components/common/LayoutComps';
import OrderBySelector from 'components/common/OrderBySelector';
import {
	CurationList,
	Artwork,
	ArtworkTitle,
	Thumbnail,
} from 'components/common/ArtworkComps';
import api from 'lib/api';

const ORDER_BY_OPTIONS = [
	{
		label: '추천 순',
		value: 'recommend',
	},
	{
		label: '최신 순',
		value: 'recent',
	},
	{
		label: '오래된 순',
		value: 'old',
	},
	{
		label: '인기 순',
		value: 'viewcount',
	},
	// {
	// 	label: '인기 낮은 순',
	// 	value: 'viewcountdesc',
	// },
];

const Gallery = () => {
	const [orderBy, setOrderBy] = useState({
		label: '추천 순',
		value: 'recommend',
	});
	const [galleries, setGalleries] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const {
					response: {
						data: { data },
					},
				} = await api.get(`/gallery/?order_by=${orderBy.value}`);
				setGalleries(data);
				// setTotalPage(count);
			} catch (e) {
				setGalleries([]);
				// setTotalPage(0);
			}
		})();
		window.scrollTo(0, 0);
	}, [orderBy.value]);

	return (
		<Wrapper>
			<SubTitleRow>
				<div>갤러리</div>
				<RightSection>
					<OrderBySelector
						orderByOptions={ORDER_BY_OPTIONS}
						orderBy={orderBy}
						onChange={(orderby) => setOrderBy(orderby)}
					/>
				</RightSection>
			</SubTitleRow>

			<CurationList>
				{galleries.map((gallery) => (
					<GalleryItem key={gallery.id} {...gallery} />
				))}
			</CurationList>
		</Wrapper>
	);
};
export default Gallery;

const Wrapper = styled(CenterLayout)`
	margin-bottom: 253px;
	${(p) => p.theme.device.md} {
		margin-bottom: 0px;
		padding: 40px 24px 128px 24px;
	}
`;
const RightSection = styled.div`
	display: flex;
	align-items: center;
	height: 21px;

	${(p) => p.theme.device.md} {
		height: 28px;
		padding: 6px 16px;
	}
`;

const GalleryItem = ({ id, thumbnail, name }) => {
	const history = useHistory();
	return (
		<Artwork onClick={() => history.push(`/gallery/detail/${id}`)}>
			<Thumbnail $thumbnail={thumbnail} />
			<ArtworkTitle>{name}</ArtworkTitle>
		</Artwork>
	);
};
