import { Select as _Select } from 'antd';
import styled from 'styled-components';

const PurchaseQuantity = ({
	title,
	value,
	price = 0,
	maxQuantity = 0,
	onChange,
}) => (
	<Wrapper>
		<Label>{title || '작품 (할인포함)'}</Label>
		<QuantitySelector>
			수량
			<Select value={value} onChange={(value) => onChange(value)}>
				{[...Array(maxQuantity).keys()].map((idx) => (
					<Select.Option key={idx} value={idx + 1}>
						{idx + 1}
					</Select.Option>
				))}
			</Select>
			개
		</QuantitySelector>
		<Divider />
		<Price>
			<label>결재 금액</label>
			<span>{(price * value).toLocaleString()}원</span>
		</Price>
	</Wrapper>
);

export default PurchaseQuantity;

const Wrapper = styled.div`
	width: 100%;
	background: #ffffff;
	border: 1px solid #bebebe;
	border-radius: 10px;
	padding: 35px 34px 26px 34px;
	margin-top: 25px;

	${(props) => props.theme.device.md} {
		padding: 26px 20px 30px 20px;
	}
`;
const Label = styled.div`
	height: 24px;
	line-height: 24px;
	font-weight: 600;
	font-size: 20px;
	letter-spacing: -0.2px;
	color: #1a1a1a;
	margin-bottom: 38px;

	${(props) => props.theme.device.md} {
		font: normal normal 600 15px/19px Pretendard;
		letter-spacing: -0.15px;
		margin-bottom: 26px;
	}
`;
const QuantitySelector = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font: normal normal 600 18px/21px Pretendard;
	letter-spacing: -0.18px;
	color: #1a1a1a;

	${(props) => props.theme.device.md} {
		font: normal normal 500 15px/18px Pretendard;
		letter-spacing: -0.15px;
	}
`;
const Select = styled(_Select)`
	margin: 0 10px;

	.ant-select-selector {
		padding: 0 16px !important;
		width: 175px !important;
		height: 43px !important;
		border: 1px solid #bebebe !important;
		border-radius: 10px !important;
		align-items: center;

		${(props) => props.theme.device.md} {
			width: 98px !important;
			height: 27px !important;
			padding: 0 10px !important;
		}
	}
`;
const Divider = styled.div`
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #bebebe;
	margin: 34px 0;
`;
const Price = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;

	line-height: 41px;
	font-weight: bold;
	font-size: 34px;
	letter-spacing: -0.34px;
	color: #1a1a1a;

	& > label {
		line-height: 21px;
		font-weight: 500;
		font-size: 18px;
		letter-spacing: -0.18px;
		color: #777777;
		margin-right: 28px;
	}

	${(props) => props.theme.device.md} {
		font: normal normal bold 23px/27px Pretendard;
		letter-spacing: -0.23px;

		& > label {
			font: normal normal 500 15px/18px Pretendard;
			letter-spacing: -0.15px;
			margin-right: 13px;
		}
	}
`;
