import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
import axios from 'axios';

import {
	Agree,
	ArtistInfo,
	ResistrationLayout,
	SaleInfo,
	WorkInfo,
} from 'components/Resistration';

import api from 'lib/api';
import useArtwork from 'hooks/useArtwork';
import useUser from 'hooks/useUser';

const NFT_URL = 'https://d3gpc3al7cvjm4.cloudfront.net/file-upload/original';
const THUMBNAIL_URL =
	'https://d3gpc3al7cvjm4.cloudfront.net/file-upload/thumbnail';
const STEPS = [
	{ step: 'artistinfo', nextStep: 'agree', label: '아티스트 정보' },
	{ step: 'agree', nextStep: 'workinfo', label: '정보 이용 동의' },
	{ step: 'workinfo', nextStep: 'saleinfo', label: '작품 정보' },
	{ step: 'saleinfo', nextStep: null, label: '판매 정보' },
];
const SKIP_ARTIST_STEPS = STEPS.filter((s) => s.step !== 'artistinfo');

/**
 * 각 단계별 폼 섹션은 "react-hook-form"을 전달하여 사용합니다.
 */
const Registration = () => {
	const history = useHistory();
	const { search } = useLocation();
	const parsed = queryString.parse(search);
	const artworkId = parsed['artworkId'];
	const artistId = parsed['artistId'];

	const artwork = useArtwork(artworkId);

	// const [prevStep, setPrevStep] = useState(null);
	const [step, setStep] = useState('artistinfo');
	const [steps, setSteps] = useState(STEPS);

	// const [currentStep, setCurrentStep] = useState(1);
	const user = useUser();

	const workForm = useForm();
	const saleForm = useForm();

	const handleNext = () => {
		setStep(STEPS.find((s) => s.step === step).nextStep);
		// setCurrentStep(currentStep + 1);

		// history.push(`/registration/${id}`);
		// storage.setStep(id);
		window.scrollTo(0, 0);
	};
	// console.log(workForm.watch());

	const handlePrev = () => {
		setStep(STEPS.find((s) => s.step === step).nextStep);
	};

	const uploadFile = async (target, file) => {
		const formData = new FormData();
		formData.append('file', file);

		return await axios.post(target, formData); //then((response) => {
		// setThumbnailFilename(file.name);
		// setValue('thumbnail', response.data.fileUrl);
		// 	return response;
		// });
	};

	const handleRegister = async () => {
		const params = {
			...workForm.watch(),
			...saleForm.watch(),
			content_agreement: true,
			nft_agreement: true,
		};

		if (artworkId) {
			/**
			 * 수정하기
			 */
			// console.log(params);
			try {
				await api.patch(`/artworks/${artworkId}/`, params);
				alert('수정되었습니다.');
				history.goBack();
			} catch (e) {
				alert('에러가 발생하였습니다.');
			}
		} else {
			/**
			 * 신규 등록하기
			 */
			try {
				// NFT 파일 등록
				const {
					data: { fileUrl: nftValue },
				} = await uploadFile(NFT_URL, params.nftValue[0]);
				// 썸네일 파일 등록
				const {
					data: { fileUrl: thumbnail },
				} = await uploadFile(THUMBNAIL_URL, params.thumbnail[0]);
				const nParams = {
					...params,
					nftValue,
					thumbnail,
				};
				// console.log(response);
				await api.post(`/artworks/`, nParams);
				alert(
					'작품 등록이 완료되었습니다.\n작품 등록 안내 메일이 발송되었습니다.',
				);
				history.push(`/mypage/myworks`);
			} catch (e) {
				alert('에러가 발생하였습니다.');
			}
		}
	};

	useEffect(() => {
		if (!artworkId) return;

		setSteps(SKIP_ARTIST_STEPS);
		setStep('workinfo');
		window.scrollTo(0, 0);
	}, [artworkId]);

	useEffect(() => {
		if (!artwork) return;

		workForm.setValue('nftValue', artwork.nftValue);
		workForm.setValue('category', artwork.category);
		workForm.setValue('name', artwork.name);
		workForm.setValue('price', artwork.price);
		workForm.setValue('amount', artwork.amount);
		workForm.setValue('description', artwork.description);
		workForm.setValue('additionalDesc', artwork.additionalDesc);
		workForm.setValue('thumbnail', artwork.thumbnail);

		saleForm.setValue('rewardA', artwork.rewardA);
		saleForm.setValue('rewardB', artwork.rewardB);
		saleForm.setValue('rewardC', artwork.rewardC);
		saleForm.setValue('bankName', artwork.bankName);
		saleForm.setValue('bankNumber', artwork.bankNumber);
		saleForm.setValue('bankOwner', artwork.bankOwner);
	}, [artwork]);

	useEffect(() => {
		if (!user) return;
		if (artworkId) return;

		if (user.isArtist) {
			/**
			 * 아티스트 등로 정보가 있을경우, "아티스트 정보" 입력 Form 스킵.
			 */
			setSteps(SKIP_ARTIST_STEPS);
			setStep('agree');
		}
	}, [user]);

	return (
		<ResistrationLayout
			name={user?.nickname}
			step={step}
			steps={steps}
			hideSteps={!!artistId}
		>
			{!!artistId ? (
				<ArtistInfo onRegisterSuccess={handleNext} />
			) : (
				<>
					{step === 'artistinfo' && (
						<ArtistInfo onRegisterSuccess={handleNext} />
					)}
					{step === 'agree' && <Agree handleNext={handleNext} />}
					{step === 'workinfo' && (
						<WorkInfo
							{...workForm}
							updateMode={!!artworkId}
							isOrderExists={artwork && artwork.isOrderExists}
							handlePrev={handlePrev}
							handleNext={handleNext}
						/>
					)}
					{step === 'saleinfo' && (
						<SaleInfo
							{...saleForm}
							updateMode={!!artworkId}
							isOrderExists={artwork && artwork.isOrderExists}
							handleRegister={handleRegister}
						/>
					)}
				</>
			)}
		</ResistrationLayout>
	);
};
export default Registration;
