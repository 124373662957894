import styled from 'styled-components';

import { ReactComponent as SearchSVG } from 'assets/svg/search-mini.svg';

const SearchField = (props) => {
	return (
		<Wrapper>
			<SearchSVG onClick={props.onQuery} />
			<Input placeholder='Search' {...props} />
		</Wrapper>
	);
};
export default SearchField;

const Wrapper = styled.div`
	position: relative;

	& > svg {
		position: absolute;
		top: 11px;
		left: 14px;
	}

	/* ${(p) => p.theme.device.md} {
		display: none;
	} */
`;
const Input = styled.input`
	width: 234px;
	height: 37px;
	background: #f8f9fa 0% 0% no-repeat padding-box;
	border-radius: 26px;
	font: normal normal medium 15px/18px Pretendard;
	letter-spacing: -0.15px;
	padding: 9px 36px;
`;
