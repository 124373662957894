import { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { setResistration } from '../../modules/resistration';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import _ from 'lodash';
import axios from 'axios';
import queryString from 'query-string';
import useArtist from 'hooks/useArtist';

import { ReactComponent as ProfileSVG } from 'assets/svg/profile-user.svg';

import api from 'lib/api';

const PROFILE_URL =
	'https://d3gpc3al7cvjm4.cloudfront.net/file-upload/original';
/**
 * react-hook-form의 useForm객체를 전달받아 사용합니다.
 * https://react-hook-form.com/get-started
 */
const ArtistInfo = ({
	// register,
	// handleSubmit,
	// formState: { errors },
	// onSubmit,
	// artistId,
	onRegisterSuccess,
}) => {
	const history = useHistory();
	const { search } = useLocation();
	const parsed = queryString.parse(search);
	const artistId = parsed['artistId'];
	console.log(artistId);
	const artist = useArtist(artistId);
	const {
		setValue,
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	const registerArtist = async (params) => {
		await api.post(`/artists/`, params);
		onRegisterSuccess();
	};

	const updateArtist = async (params) => {
		if (artist) {
			await api.patch(`/artists/${artist.id}/`, params);
			history.goBack();
		}
	};
	// const history = useHistory();
	// const dispatch = useDispatch();

	// const { name, price, category, amount } = useSelector(
	// 	({ resistration }) => ({
	// 		name: resistration.name,
	// 		price: resistration.price,
	// 		category: resistration.category,
	// 		amount: resistration.amount,
	// 	}),
	// );
	// const [artistHistory, setArtistHistory] = useState({
	// 	first: '',
	// 	second: '',
	// 	third: '',
	// });
	// const [artistInfo, setArtistInfo] = useState({
	// 	artistName: '',
	// 	artistDescription: '',
	// 	artistHistory: {},
	// });
	// const { artistName, artistDescription } = artistInfo;
	// const handleChange = (e) => {
	// 	setError('');
	// 	const { name, value } = e.target;
	// 	setArtistInfo({
	// 		...artistInfo,
	// 		[name]: value,
	// 	});
	// };
	// const handleHistory = (e) => {
	// 	const { name, value } = e.target;
	// 	setArtistHistory({
	// 		...artistHistory,
	// 		[name]: value,
	// 	});
	// };

	// # 다음으로 버튼
	// const handleClick = () => {
	// 	if (!artistName || !artistDescription || !artistHistory.first) {
	// 		setError('일부 항목 입력이 유효하지 않습니다');
	// 		return;
	// 	}
	// 	dispatch(
	// 		setResistration({
	// 			...artistInfo,
	// 			artistHistory,
	// 		}),
	// 	);
	// 	// handleNext(id);
	// };
	// function isEmptyObj(obj) {
	// 	if (obj.constructor === Object && _.isEmpty(obj)) {
	// 		return true;
	// 	}
	// 	return false;
	// }
	// useEffect(() => {
	// 	if (
	// 		isEmptyObj(name) ||
	// 		price === 0 ||
	// 		isEmptyObj(category) ||
	// 		amount < 1
	// 	) {
	// 		history.push('/registration/2');
	// 	}
	// }, []);

	const { profileImage } = watch();
	const [imagePreview, setImagePreview] = useState('');

	const onSubmit = async (data) => {
		let nParams = { ...data };

		try {
			if (typeof data.profileImage !== 'string') {
				// 프로필 파일 등록
				const formData = new FormData();
				formData.append('file', data.profileImage[0]);
				const {
					data: { fileUrl },
				} = await axios.post(PROFILE_URL, formData);

				nParams = {
					...data,
					profileImage: fileUrl,
				};
			}

			if (artist) {
				// 수정
				await updateArtist(nParams);
			} else {
				// 신규 등록
				await registerArtist(nParams);
			}
		} catch (e) {
			alert('에러가 발생하였습니다.');
		}
	};

	useEffect(() => {
		if (profileImage && profileImage.length > 0) {
			if (typeof profileImage === 'string') {
				setImagePreview(profileImage);
			} else {
				setImagePreview(URL.createObjectURL(profileImage[0]));
			}
		}
	}, [profileImage]);

	useEffect(() => {
		if (!artist) return;

		setValue('name', artist.name);
		setValue('profileImage', artist.profileImage);
		setValue('description', artist.description);
		setValue('homepage', artist.homepage);
		setValue('instagram', artist.instagram);
	}, [artist]);

	return (
		<Wrapper>
			<form id='artist-form' onSubmit={handleSubmit(onSubmit)}>
				<InputRow>
					<label>
						아티스트명
						<span className='required'>*</span>
						<span className='description'>
							팀일 경우, 팀명 기재
						</span>
					</label>
					<Input
						placeholder='아티스트명을 입력해 주세요.'
						{...register('name', { required: true })}
					/>
				</InputRow>

				<InputRow>
					<label>
						프로필 등록<span className='required'>*</span>
					</label>
					<ProfileSection>
						<Profile>
							{imagePreview ? (
								<img src={imagePreview} alt='' />
							) : (
								<ProfileSVG />
							)}
							{/* <ProfileSVG /> */}
						</Profile>
						<FileSelector>
							<input
								type='file'
								id='profileImage'
								{...register('profileImage', {
									// required: true,
									validate: {
										isJpgOrPng: (v) => {
											console.log(v, typeof v);
											if (typeof v === 'string')
												return true;

											const isValid = /jpeg|png|jpg/.test(
												v[0].type,
											);
											if (!isValid) {
												alert(
													'대표썸네일은 JPG / PNG 형식만 가능합니다.',
												);
											}
											return isValid;
										},
										isSizeLimit: (v) => {
											if (typeof v === 'string')
												return true;

											const isValid =
												v[0].size / 1024 / 1024 < 2;
											if (!isValid) {
												alert(
													'대표썸네일은 2MB 이내의 파일을 등록해주세요.',
												);
											}
											return isValid;
										},
									},
								})}
								// onChange={uploadThumbnail}
								style={{ display: 'none' }}
							/>
							<label htmlFor='profileImage'>파일 선택</label>
							<div className='description'>
								<span className='label'>이미지 용량:</span>{' '}
								<span className='value'>2MB 이하</span>
								<br /> <span className='label'>형식:</span>
								<span className='value'>JPG, PNG, GIF</span>
							</div>
						</FileSelector>
					</ProfileSection>
				</InputRow>

				<InputRow>
					<label>
						아티스트 소개<span className='required'>*</span>
					</label>
					<Textarea
						placeholder='주요 이력 등을 포함하여 본인 소개글을 작성해 주세요.'
						{...register('description', { required: true })}
					/>
				</InputRow>

				<InputRow>
					<label>홈페이지</label>
					<Input
						placeholder='개인 홈페이지 주소를 입력해 주세요. (선택)'
						{...register('homepage')}
					/>
				</InputRow>

				<InputRow>
					<label>SNS</label>
					<Input
						placeholder='대표 SNS 주소를 입력해 주세요. (선택)'
						{...register('instagram')}
					/>
				</InputRow>

				<Actions>
					<ActionButton onClick={() => history.goBack()}>
						취소
					</ActionButton>
					<ActionButton
						form='artist-form'
						type='submit'
						$isActive={true}
						// loading={loading}
						// onClick={loading ? null : handleClick}
						// form="profile-form"
					>
						{artist ? '수정 완료' : '다음으로'}
					</ActionButton>
				</Actions>
				{Object.keys(errors).length > 0 && (
					<Error>일부 항목 입력이 유효하지 않습니다.</Error>
				)}

				{/* <Title>
				아티스트명<span>*</span>&nbsp;
				<span className='alert'>팀일 경우, 팀명 기재</span>
			</Title>
			<InputContainer width='50%'>
				<Input
					maxLength='255'
					type='text'
					name='artistName'
					onChange={handleChange}
				/>
			</InputContainer>
			<Title>
				아티스트 설명<span>*</span>
			</Title>
			<InputContainer width='100%'>
				<textarea
					minLength='1'
					name='artistDescription'
					onChange={handleChange}
					id='artistDescription'
					cols='30'
					rows='10'
					placeholder='아티스트에 대한 설명글을 작성해주세요'
				></textarea>
			</InputContainer>
			<Title>
				주요이력<span>*</span>&nbsp;
			</Title>
			<InputContainer width='100%'>
				<Input
					name='first'
					type='text'
					onChange={handleHistory}
					placeholder='전시, 공연 등 주요 활동 이력을 입력해주세요 (필수)'
				/>
				<Input
					name='second'
					type='text'
					onChange={handleHistory}
					placeholder='전시, 공연 등 주요 활동 이력을 입력해주세요 (선택)'
				/>
				<Input
					name='third'
					type='text'
					onChange={handleHistory}
					placeholder='전시, 공연 등 주요 활동 이력을 입력해주세요 (선택)'
				/>
			</InputContainer> */}
				{/* <NextButtonContainer>
				<NextButton onClick={handleClick}>다음으로</NextButton>
			</NextButtonContainer>
			{error && <Error>{error}</Error>} */}
			</form>
		</Wrapper>
	);
};
export default ArtistInfo;

const Wrapper = styled.div`
	/* padding-top: 30px;
	${(props) => props.theme.device.md} {
		padding: 0 16px;
	} */
`;
const InputRow = styled.div`
	& > label {
		font: normal normal 600 24px/29px Pretendard;
		letter-spacing: -0.48px;
		color: #000000;
		margin-bottom: 15px;
		display: block;

		${(props) => props.theme.device.md} {
			font: normal normal bold 17px/20px Pretendard;
			letter-spacing: -0.17px;
			color: #1a1a1a;
		}

		& > span.required {
			color: #4568dc;
		}
		& > span.description {
			font: normal normal 400 16px/19px Pretendard;
			letter-spacing: -0.16px;
			color: #777777;
			margin-left: 25px;

			${(props) => props.theme.device.md} {
				font: normal normal 500 14px/17px Pretendard;
				letter-spacing: -0.14px;
				margin-left: 8px;
			}
		}
	}

	& + & {
		margin-top: 40px;
	}
`;

const ProfileSection = styled.div`
	display: flex;
	align-items: center;
	margin-top: 30px;

	${(props) => props.theme.device.md} {
		margin-top: 16px;
	}
`;
const Profile = styled.div`
	width: 179px;
	height: 179px;
	border-radius: 50%;
	background: #bebebe 0% 0% no-repeat padding-box;
	display: flex;
	align-items: center;
	justify-content: center;

	& > img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	${(props) => props.theme.device.md} {
		width: 107px;
		height: 107px;

		& > svg {
			width: 25px;
			height: 29px;
		}
	}
`;
const FileSelector = styled.div`
	margin-left: 50px;
	& > label {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 137px;
		height: 36px;
		background: #242424 0% 0% no-repeat padding-box;
		border-radius: 4px;
		font: normal normal 500 16px/19px Pretendard;
		letter-spacing: -0.4px;
		color: #ffffff;

		${(props) => props.theme.device.md} {
			width: 88px;
			height: 25px;
			font: normal normal 500 14px/17px Pretendard;
			letter-spacing: -0.28px;
		}
	}
	& > .description {
		font: normal normal 400 15px/20px Pretendard;
		letter-spacing: -0.15px;
		color: #777777;
		margin-top: 20px;

		${(props) => props.theme.device.md} {
			font: normal normal 500 14px/23px Pretendard;
			letter-spacing: -0.14px;
			color: #bebebe;
			margin-top: 15px;
			& > .label {
				display: inline-block;
				width: 70px;
				margin-right: 25px;
			}
			& > .value {
				color: #1a1a1a;
			}
		}
	}
`;
const Input = styled.input`
	width: ${(p) => (p.$small ? `407px` : '100%')};
	height: 55px;
	border: 1px solid #bebebe;
	border-radius: 10px;
	padding: 18px 20px;
	font: normal normal 400 16px/19px Pretendard;
	letter-spacing: -0.16px;

	&::placeholder {
		color: #bebebe;
	}

	${(props) => props.theme.device.md} {
		width: 100%;
		height: 41px;
		font: normal normal 400 14px/19px Pretendard;
		letter-spacing: -0.14px;
	}
`;

const Textarea = styled.textarea`
	width: 100%;
	height: 176px;
	border: 1px solid #bebebe;
	border-radius: 10px;
	padding: 18px 20px;
	font: normal normal 400 16px/19px Pretendard;
	letter-spacing: -0.16px;

	${(props) => props.theme.device.md} {
		height: 222px;
		font: normal normal 400 14px/17px Pretendard;
		letter-spacing: -0.13px;
	}
`;

const Actions = styled.div`
	margin-top: 145px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;

	${(props) => props.theme.device.md} {
		flex-direction: column;
		justify-content: center;
		margin-bottom: 20px;
	}
`;
const ActionButton = styled.button`
	width: 367px;
	height: 72px;
	background: ${(p) => (p.$isActive ? '#242424' : '#ffffff')};
	border-radius: 10px;
	font: normal normal 600 22px/48px Pretendard;
	letter-spacing: -0.55px;
	border: ${(p) => (p.$isActive ? 'none' : '1px solid #777777')};
	color: ${(p) => (p.$isActive ? '#ffffff' : '#000000')};

	${(props) => props.theme.device.md} {
		height: 47px;
		background: ${(p) => (p.$isActive ? '#1A1A1A' : '#ffffff')};
		font: normal normal 400 17px/20px Pretendard;
		letter-spacing: -0.17px;
	}
`;

const Error = styled.div`
	margin-top: 22px;
	text-align: center;
	font: normal normal 400 18px/21px Pretendard;
	letter-spacing: 0px;
	color: #fe7668;
	${(props) => props.theme.device.md} {
		margin-top: 12px;
		font: normal normal 500 14px/19px Pretendard;
		letter-spacing: -0.14px;
	}
`;
