import { useState } from 'react';
import styled from 'styled-components';
import { Menu as _Menu } from 'antd';

import { ReactComponent as CheckSVG } from 'assets/svg/check.svg';
import { ReactComponent as ArrowSVG } from 'assets/svg/chevron-left.svg';

import { ORDER_BY } from 'lib/constants';

const OrderBySelector = ({ orderByOptions = ORDER_BY, orderBy, onChange }) => {
	const [showSortOptions, setShowSortOptions] = useState(false);

	return (
		<Wrapper>
			<Toggler onClick={() => setShowSortOptions(!showSortOptions)}>
				{orderBy.label}
				<ArrowSVG />
			</Toggler>

			{showSortOptions && (
				<Menu>
					{orderByOptions.map((order, i) => (
						<MenuItem
							key={i}
							$active={order.value === orderBy.value}
							onClick={() => {
								onChange(order);
								setShowSortOptions(false);
							}}
						>
							{order.value === orderBy.value && <CheckSVG />}

							{order.label}
						</MenuItem>
					))}
				</Menu>
			)}
		</Wrapper>
	);
};
export default OrderBySelector;

const Wrapper = styled.div`
	position: relative;
	padding-right: 25px;
	${(p) => p.theme.device.md} {
		padding-right: 15px;
	}
`;
const Toggler = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	line-height: 21px;
	font: normal normal bold 18px/19px Pretendard;
	letter-spacing: -0.18px;
	color: #1a1a1a;

	& > svg {
		width: 15px;
		height: 7px;
		margin-left: 10px;
	}

	${(p) => p.theme.device.md} {
		line-height: 16px;
		font: normal normal bold 13px/19px Pretendard;
		letter-spacing: -0.13px;

		& > svg {
			width: 8px;
			height: 4px;
			margin-left: 5px;
		}
	}
`;
const Menu = styled(_Menu)`
	z-index: 999;
	right: 0;
	top: 37px;
	position: absolute;
	width: 297px;
	background: #4d4d4d;
	box-shadow: 0px 0px 99px #31313126 !important;
	/* mix-blend-mode: multiply; */
	border-radius: 10px;
	padding: 30px 35px 38px 35px;
`;
const MenuItem = styled(_Menu.Item)`
	height: 21px;
	font-weight: ${(p) => (p.$active ? 600 : 0)};
	font-size: 18px;
	letter-spacing: -0.18px;
	color: #ffffff;
	margin: 0;
	padding: 0;

	svg {
		margin-right: 10px;
	}

	&:hover {
		font-weight: 600;
		background-color: unset;
	}
`;
