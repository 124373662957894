import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import api from 'lib/api';
import {
	CenterLayout,
	Title,
	SubTitleRow,
} from 'components/common/LayoutComps';
import {
	CollectionWrapper,
	Collection,
	Thumbnail,
	ArtistName,
	Description,
} from 'components/CollectionComps';
import OrderBySelector from 'components/common/OrderBySelector';
import MobileSearchField from 'components/common/MobileSearchField';
import SearchField from 'components/common/SearchField';
import useCategory from 'hooks/useCategory';

const ORDER_BY_OPTIONS = [
	{
		label: '추천 순',
		value: 'recommend',
	},
	{
		label: '가나다순',
		value: 'name',
	},
	{
		label: '인기순',
		value: 'viewcount',
	},
	{
		label: '최신순',
		value: 'recent',
	},
	{
		label: '오래된순',
		value: 'old',
	},
];
const CATEGORIES = ['Top', 'Art', 'Music', '기타'];

const Collections = () => {
	const [orderBy, setOrderBy] = useState(ORDER_BY_OPTIONS[0]);
	const [category, setCategory] = useState('');
	const [query, setQuery] = useState('');
	const [queryBuffer, setQueryBuffer] = useState(''); // 검색창에서 엔터 입력 시, 쿼리 요청 용
	const [collections, setCollections] = useState([]);
	const categories = useCategory();

	useEffect(() => {
		(async () => {
			try {
				const {
					response: {
						data: { data },
					},
				} = await api.get(
					`/artists/?order_by=${
						orderBy.value
					}&category=${category.toLowerCase()}${
						query ? `&search=${query}` : ''
					}`,
				);
				setCollections(data);
			} catch (e) {
				setCollections([]);
			}
		})();
		window.scrollTo(0, 0);
	}, [orderBy.value, category, query]);

	return (
		<Wrapper>
			<Title>Collections</Title>

			<Categories>
				<Category onClick={() => setCategory('top')}>Top</Category>
				{categories.map((option, i) => (
					<Category
						key={i}
						$isActive={option === category}
						onClick={() => setCategory(option.code)}
					>
						{option.name}
					</Category>
				))}
			</Categories>

			<SubTitleRow>
				<div />
				<RightSection>
					<OrderBySelector
						orderByOptions={ORDER_BY_OPTIONS}
						orderBy={orderBy}
						onChange={(orderby) => setOrderBy(orderby)}
					/>
					<SearchField
						value={queryBuffer}
						onChange={(e) => setQueryBuffer(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								setQuery(queryBuffer);
							}
						}}
					/>
				</RightSection>
			</SubTitleRow>

			<MobileSearchField value={query} onChange={(q) => setQuery(q)} />

			<CollectionWrapper>
				{collections.map((collection) => (
					<CollectionItem key={collection.id} {...collection} />
				))}
			</CollectionWrapper>
		</Wrapper>
	);
};
export default Collections;
const Wrapper = styled(CenterLayout)`
	margin-bottom: 353px;
	${(p) => p.theme.device.md} {
		margin-bottom: 60px;
	}
`;
const RightSection = styled.div`
	display: flex;
	align-items: center;
	height: 21px;

	${(p) => p.theme.device.md} {
		height: 28px;
		padding: 6px 16px;

		& > div:nth-child(2) {
			display: none;
		}
	}
`;
const Categories = styled.section`
	display: flex;
	gap: 22px;
	margin-top: 57px;
	margin-bottom: 45px;

	${(p) => p.theme.device.md} {
		gap: 13px;
		width: 100%;
		margin-top: 33px;
		margin-bottom: 25px;
		overflow: auto;
	}
`;
const Category = styled.div`
	width: 137px;
	height: 45px;
	background: ${(p) => (p.$isActive ? '#4568DC' : '#ffffff')};
	box-shadow: 0px 0px 20px #0000000d;
	border: 1px solid #ebebeb;
	border-radius: 25px;
	font: normal normal 500 18px/21px Pretendard;
	letter-spacing: -0.18px;
	color: ${(p) => (p.$isActive ? '#fff' : '#1a1a1a')};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	${(p) => p.theme.device.md} {
		flex-shrink: 0;
		width: 94px;
		height: 33px;
		box-shadow: none;
		font: normal normal 500 14px/17px Pretendard;
		letter-spacing: -0.14px;
		background: ${(p) => (p.$isActive ? '#1A1A1A' : '#EEEEEE')};
		color: ${(p) => (p.$isActive ? '#fff' : '#1a1a1a')};
	}
`;

const CollectionItem = ({ id, name, description, profileImage }) => {
	const history = useHistory();

	return (
		<Collection onClick={() => history.push(`/collection/detail/${id}`)}>
			<Thumbnail $thumbnail={profileImage} />
			<ArtistName>{name}</ArtistName>
			<Description>{description}</Description>
		</Collection>
	);
};
