import { useState, useEffect, useCallback } from 'react';
import API from '../api';
import { useDispatch } from 'react-redux';
import { startLoading, finishLoading } from '../../modules/loading';

export default function useFetch(url, method, options, { immediate }) {
	const dispatch = useDispatch();
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const executeFetch = useCallback(async () => {
		dispatch(startLoading('START'));
		setIsLoading(true);
		setData(null);
		setError(null);
		await API[method](url, options)
			.then((response) => setData(response.response.data))
			.catch((err) => setError(err))
			.finally(() => {
				setIsLoading(false);
				dispatch(finishLoading('FINISH'));
			});
		return { data, error, isLoading };
	}, [url, options, data, error, isLoading, method, dispatch, setIsLoading]);

	useEffect(() => {
		if (immediate) {
			executeFetch();
		}
	}, [executeFetch, immediate]);
	return { executeFetch, data, error, isLoading };
}
