import { useEffect } from 'react';
import styled from 'styled-components';

import api from 'lib/api';
import { useState } from 'react';

const About = () => {
	const [content, setContent] = useState('');
	const [contentMobile, setContentMobile] = useState('');

	useEffect(() => {
		window.scrollTo(0, 0);
		(async () => {
			try {
				const {
					response: {
						data: {
							data: { content, contentMobile },
						},
					},
				} = await api.get('/forums/about/');
				setContent(content);
				setContentMobile(contentMobile);
			} catch (e) {
				setContent('');
				setContentMobile('');
			}
		})();
	}, []);

	return (
		<>
			<Wrapper dangerouslySetInnerHTML={{ __html: content }} />
			<MobileWrapper
				dangerouslySetInnerHTML={{ __html: contentMobile }}
			/>
		</>
	);
};

export default About;

const Wrapper = styled.div`
	width: 1200px;
	min-height: 1200px;
	margin-top: 80px;
	margin-bottom: 251px;
	/* background: #eeeeee 0% 0% no-repeat padding-box; */
	border-radius: 10px;

	img {
		width: 1200px !important;
	}

	${(props) => props.theme.device.md} {
		display: none;
	}
`;
const MobileWrapper = styled.div`
	display: none;
	${(props) => props.theme.device.md} {
		display: block;
		width: 100%;
		margin: 0 24px;
		min-height: 554px;
		margin-top: 35px;
		margin-bottom: 205px;

		img {
			width: calc(100% - 24px) !important;
		}
	}
`;
