import { createAction, handleActions } from 'redux-actions';

const RESISTRATION = 'resistration/RESISTRATION';

export const setResistration = createAction(RESISTRATION, (payload) => ({
	...payload,
}));

export const initialState = {
	name: '',
	category: '',
	contentAgreement: {},
	nftAgreement: {},
	nftValue: '',
	isNewNft: false,
	price: 0,
	amount: 1,
	mediaType: '',
	mediaInfo: '',
	description: '',
	thumbnail: '',
	displayInfo: {},
	bankName: '',
	bankNumber: '',
	bankOwner: '',
	artistName: '',
	artistDescription: '',
	artistHistory: {},
};

const resistration = handleActions(
	{
		[RESISTRATION]: (state, { payload }) => ({
			...state,
			...payload,
		}),
	},
	initialState,
);

export default resistration;
