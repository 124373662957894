import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={31}
			height={31}
			viewBox='0 0 31 31'
			{...props}
		>
			<rect
				data-name='\uC0AC\uAC01\uD615 900'
				width={31}
				height={31}
				rx={0.5}
				fill='#fff'
			/>
			<path
				data-name='\uD328\uC2A4 424'
				d='M18.249 7.574v8l-5.477-8H6.85v15.849h5.9v-8l5.478 8h5.92V7.574z'
				fill='#1ec800'
			/>
		</svg>
	);
}

export default SvgComponent;
