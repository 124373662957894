import styled from 'styled-components';

export const Wrapper = styled.article`
	width: 1200px;
	min-height: 1170px;
	margin: 0 auto;
	padding-top: 80px;
	${(props) => props.theme.device.md} {
		width: 100%;
		min-height: 450px;
		padding-top: 35px;
		padding-left: 24px;
		padding-right: 24px;
	}
`;
