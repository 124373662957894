import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import LOGO from 'assets/images/piecial_logo.png';
import { SearchBar } from 'components/Main';
import Nav from './Nav';

const PcHeader = () => {
	const history = useHistory();
	const goHome = () => history.push('/');

	return (
		<Wrapper>
			<LeftSection>
				<LogoSVG onClick={goHome}></LogoSVG>
				<SearchBar />
			</LeftSection>

			<Nav />
		</Wrapper>
	);
};
export default PcHeader;

const Wrapper = styled.article`
	width: 1200px;
	height: 197px;
	padding-top: 93px;
	padding-bottom: 52px;
	display: flex;
	align-items: center;
	margin: 0 auto;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	${(props) => props.theme.device.md} {
		width: 100%;
		display: none;
	}
`;
const LeftSection = styled.div`
	display: flex;
`;
const LogoSVG = styled.div`
	width: 203px;
	height: 52px;
	background-color: none;
	cursor: pointer;
	background-image: url(${LOGO});
	background-size: 100% 100%;
	background-repeat: no-repeat;
	margin-right: 20px;
`;
