import styled from 'styled-components';

const Header = () => (
	<Wrapper>
		<Title>Notice</Title>
	</Wrapper>
);
export default Header;

const Wrapper = styled.section``;
const Title = styled.h1`
	margin: 0;
	line-height: 45px;
	font-weight: bold;
	font-size: 37px;
	letter-spacing: -0.37px;
	color: #111111;

	${(props) => props.theme.device.md} {
		line-height: 29px;
		font-size: 24px;
		letter-spacing: -0.24px;
		color: #1a1a1a;
	}
`;
