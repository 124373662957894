import { useState } from 'react';
import { Drawer as _Drawer } from 'antd';
import styled from 'styled-components';
import { ReactComponent as SearchSVG } from 'assets/svg/search_mobile_input.svg';

const MobileSearch = ({ isShow, onSearch }) => {
	const [queryBuffer, setQueryBuffer] = useState(); // 검색창에서 엔터 입력 시, 쿼리 요청 용

	const handleSearch = () => {
		onSearch(queryBuffer);
		setQueryBuffer('');
	};

	return (
		<Drawer placement='bottom' visible={isShow} closable={false}>
			<InputRow>
				<Input
					placeholder='작품명, 아티스트, 판매자 검색'
					value={queryBuffer}
					onChange={(e) => setQueryBuffer(e.target.value)}
					onKeyPress={(e) => {
						if (e.key === 'Enter') {
							handleSearch();
						}
					}}
				/>
				<SearchButton onClick={handleSearch} />
			</InputRow>
		</Drawer>
	);
};
export default MobileSearch;

const Drawer = styled(_Drawer)`
	&.ant-drawer-bottom.ant-drawer-open {
		height: calc(100% - 108px);
		position: absolute;
		bottom: 0;
		.ant-drawer-content-wrapper {
			height: 100% !important;

			.ant-drawer-body {
				padding: 30px 24px;
			}
		}
	}
`;
const InputRow = styled.div`
	position: relative;
`;
const Input = styled.input`
	width: 100%;
	height: 42px;
	background: #f8f8f8;
	border-radius: 10px;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: -0.14px;
	padding: 0 15px;
`;
const SearchButton = styled(SearchSVG)`
	width: 16px;
	height: 16px;
	position: absolute;
	top: 13px;
	right: 11px;
`;
