import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import api from 'lib/api';
import NoHistory from 'components/MyPage/NoHistory';
import Nav from 'components/MyPage/Nav';
import MyWork from 'components/MyPage/MyWork';

const MyWorks = () => {
	const [myWorks, setMyWorks] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const {
					response: {
						data: { results },
					},
				} = await api.get('/user/artworks/', {
					per_page: 300,
				});
				setMyWorks(results);
			} catch (e) {
				setMyWorks([]);
			}
		})();
	}, []);

	return (
		<Nav>
			{myWorks.length > 0 ? (
				<History>
					<WorkListContainer>
						{myWorks.map((myWork) => (
							<MyWork key={myWork.id} myWork={myWork} />
						))}
					</WorkListContainer>
				</History>
			) : (
				<NoHistory content='아직 등록한 콘텐츠가 없군요!' />
			)}
		</Nav>
	);
};
const History = styled.div``;
const WorkListContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 75px;
	gap: 24px;
	${(props) => props.theme.device.md} {
		flex-direction: column;
		flex-wrap: nowrap;
		margin-top: 35px;
	}
`;
export default MyWorks;
