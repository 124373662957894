import { useState } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

const DeleteAccountModal = ({ id, onOk, ...modalProps }) => {
	const [password, setPassword] = useState('');

	return (
		<Wrapper
			title='서비스 이용 종료'
			width={1200}
			okText='비밀번호'
			footer={[
				<DeleteButton
					key='submit'
					type='primary'
					onClick={() => onOk(password)}
				>
					탈퇴하기
				</DeleteButton>,
			]}
			{...modalProps}
		>
			<Title>
				<div>잠깐!</div> <div>탈퇴 전 꼭 확인하세요.</div>
			</Title>

			<Warning>
				<div>ⓘ Piecial에서 판매 중인 작품은 삭제되지 않습니다.</div>
				<div className='description'>
					작품 삭제를 원하실 경우, 메일을 통해 문의해 주시길 바랍니다.
				</div>
			</Warning>
			<Warning>
				<div>
					ⓘ 탈퇴 시 등록된 개인 정보 및 작품 판매, 구매 정보가
					사라집니다.
				</div>
				<div className='description'>
					서비스 탈퇴 시, 회원님에 대한 모든 정보는 복구가
					불가능합니다.
				</div>
			</Warning>

			<InputField>
				<label>비밀번호</label>
				<input
					type='password'
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					placeholder='비밀번호를 입력해 주세요.'
				/>
			</InputField>
		</Wrapper>
	);
};
export default DeleteAccountModal;

const DeleteButton = styled.button`
	width: 367px;
	height: 72px;
	background: #242424 0% 0% no-repeat padding-box;
	border-radius: 10px;
	font: normal normal 600 22px/26px Pretendard;
	letter-spacing: -0.55px;
	color: #ffffff;

	${(p) => p.theme.device.md} {
		width: 100%;
		height: 46px;
		background: #1a1a1a 0% 0% no-repeat padding-box;
		font: normal normal 400 17px/20px Pretendard;
		letter-spacing: -0.17px;
		color: #ffffff;
	}
`;
const Wrapper = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;
		width: 1200px;
		height: 892px;
		padding: 66px 78px 120px 78px;

		${(p) => p.theme.device.md} {
			width: 366px;
			/* height: 713px; */
			padding: 26px 30px 32px 30px;
		}

		.ant-modal-header {
			padding: 0px;
			padding-bottom: 15px;
			.ant-modal-title {
				font: normal normal 600 24px/29px Pretendard;
				letter-spacing: -0.48px;
				color: #000000;
			}

			${(p) => p.theme.device.md} {
				padding-bottom: 23px;
				.ant-modal-title {
					font: normal normal 600 17px/20px Pretendard;
					letter-spacing: -0.17px;
					color: #1a1a1a;
				}
			}
		}

		.ant-modal-footer {
			text-align: center;
			border-top: none;
		}
		.ant-modal-body {
			padding: 66px 0 120px 0;
			${(props) => props.theme.device.md} {
				padding: 29px 0 73px 0;
			}
		}
	}
`;
const Title = styled.div`
	display: flex;
	gap: 4px;
	font: normal normal bold 37px/45px Pretendard;
	letter-spacing: -0.74px;
	color: #111111;
	margin-bottom: 50px;
	${(p) => p.theme.device.md} {
		flex-direction: column;
		font: normal normal bold 24px/32px Pretendard;
		letter-spacing: -0.24px;
		color: #1a1a1a;
	}
`;
const Warning = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	font: normal normal 600 18px/21px Pretendard;
	letter-spacing: -0.18px;
	color: #000000;

	& > .description {
		font: normal normal 400 16px/19px Pretendard;
		letter-spacing: -0.16px;
		color: #777777;
	}

	& + & {
		margin-top: 40px;
	}

	${(p) => p.theme.device.md} {
		font: normal normal 600 17px/26px Pretendard;
		letter-spacing: -0.17px;
		gap: 17px;

		& > .description {
			font: normal normal 400 14px/24px Pretendard;
			letter-spacing: -0.14px;
		}
	}
`;
const InputField = styled.div`
	margin-top: 65px;
	& > label {
		display: block;
		font: normal normal 600 24px/29px Pretendard;
		letter-spacing: -0.24px;
		color: #000000;
		margin-bottom: 15px;
	}
	& > input {
		width: 612px;
		height: 55px;
		border: 1px solid #bebebe;
		border-radius: 10px;
		font: normal normal 400 16px/19px Pretendard;
		letter-spacing: -0.16px;
		color: #bebebe;
		padding: 18px 20px;
	}

	${(p) => p.theme.device.md} {
		margin-top: 58px;
		& > label {
			font: normal normal 600 17px/20px Pretendard;
			letter-spacing: -0.17px;
			color: #1a1a1a;
		}
		& > input {
			width: 100%;
			height: 41px;
			font: normal normal normal 13px/16px Pretendard;
			letter-spacing: -0.13px;
			padding: 12px 17px;
		}
	}
`;
