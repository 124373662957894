import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import { ReactComponent as ArrowSVG } from 'assets/svg/chevron-left.svg';
import * as Artwork from 'components/common/ArtworkComps';
import api from 'lib/api';

const GallerySection = () => {
	const history = useHistory();
	const [galleries, setGalleries] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const {
					response: {
						data: { data },
					},
				} = await api.get('/gallery/?order_by=viewcount');
				setGalleries(data);
			} catch (e) {
				setGalleries([]);
			}
		})();
	}, []);

	return (
		<Artwork.CurationSection>
			<Artwork.CurationTitleRow>
				인기 갤러리 <Artwork.Link to='/gallery'>전체보기</Artwork.Link>
			</Artwork.CurationTitleRow>

			<PcCurationList>
				<Swiper
					spaceBetween={24}
					slidesPerView={3}
					modules={[Navigation]}
					navigation={{
						nextEl: '.gallery-next',
						prevEl: '.gallery-prev',
					}}
				>
					{galleries.map((gallery) => (
						<SwiperSlide key={gallery.id}>
							<Artwork.Artwork
								key={gallery.id}
								onClick={() =>
									history.push(
										`/gallery/detail/${gallery.id}`,
									)
								}
							>
								<Artwork.Thumbnail
									$thumbnail={gallery.thumbnail}
								></Artwork.Thumbnail>
								<Artwork.ArtworkTitle>
									{gallery.name}
								</Artwork.ArtworkTitle>
							</Artwork.Artwork>
						</SwiperSlide>
					))}
				</Swiper>
				<ArrowSVG className='nft-prev gallery-prev' />
				<ArrowSVG className='nft-next gallery-next' />
			</PcCurationList>

			<MobileCurationList>
				<Swiper
					spaceBetween={32}
					slidesPerView={1}
					pagination={{
						dynamicBullets: true,
					}}
					modules={[Pagination]}
				>
					{galleries.map((gallery) => (
						<SwiperSlide key={gallery.id}>
							<Artwork.Artwork
								key={gallery.id}
								onClick={() =>
									history.push(
										`/gallery/detail/${gallery.id}`,
									)
								}
							>
								<Artwork.Thumbnail
									$thumbnail={gallery.thumbnail}
								></Artwork.Thumbnail>
								<Artwork.ArtworkTitle>
									{gallery.name}
								</Artwork.ArtworkTitle>
							</Artwork.Artwork>
						</SwiperSlide>
					))}
				</Swiper>
			</MobileCurationList>
		</Artwork.CurationSection>
	);
};
export default GallerySection;

const PcCurationList = styled.div`
	display: block;
	position: relative;

	& > svg {
		position: absolute;
		top: 180px;
		cursor: pointer;

		&.nft-prev {
			transform: rotate(90deg);
			left: -37px;
		}
		&.nft-next {
			transform: rotate(270deg);
			right: -37px;
		}
	}

	${(p) => p.theme.device.md} {
		display: none;
	}
`;
const MobileCurationList = styled.div`
	display: none;
	${(p) => p.theme.device.md} {
		display: block;
	}
`;
