import PcHeader from './PcHeader';
import MobileHeader from './MobileHeader';

const Header = () => (
	<header>
		<PcHeader />
		<MobileHeader />
	</header>
);

export default Header;
