import React, { useEffect } from 'react';
import styled from 'styled-components';

const Policy = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Container>
			<Title>Piecial 개인정보처리방침</Title>
			<p>
				임팩트스테이션 주식회사(이하 “회사”)는 이용자의 개인정보 보호를
				매우 중요하게 생각하고 있으며 회사가 제공하는 서비스 Piecial
				(이하 “서비스”)를 이용하기 위해 이용자가 제공한 개인정보를
				보호하기 위해 최선을 다하고 있습니다. 회사는 “정보통신망
				이용촉진 및 정보보호 등에 관한 법률” 및 “개인정보보호법” 등
				개인정보 보호와 관련된 법령을 준수하고 있습니다. 회사는 본
				개인정보처리방침을 서비스 내에서 이용자가 언제든지 쉽게 확인할
				수 있도록 공개하고 있습니다. 본 개인정보처리방침은 관계 법령 및
				회사의 내부 방침에 따라 변경될 수 있으며 개정 시 공식 서비스
				페이지(또는 서면, 팩스, 이메일 등 개별 공지)를 통하여
				공지하겠습니다.
				<br />
				<br />
				<SubTitle>제 1 조 (개인정보 수집과 목적)</SubTitle>
				회사에서 수집하는 개인정보 항목과 각 목적은 다음과 같습니다.
				회사는 명시한 목적으로만 이용자의 개인정보를 처리합니다.
				처리하고 있는 개인정보는 다음의 목적 이 외의 용도로는 이용되지
				않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제 18 조에
				따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
				<br />
				1. 회원가입 및 서비스 이용 등을 통해 수집・이용하는 개인정보의
				항목
				<br />
				<br />
				a. 회원가입
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 카카오 서비스ID,
				카카오계정(이메일), 프로필정보(닉네임, 프로필 사진)
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 카카오계정(전화번호),
				본인확인정보(이름, 생년월일, 성별), 연계정보(CI)
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 마케팅 활용 및 광고성 정보
				수신 여부 선택 동의
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 카카오톡 채널 추가 및
				메시지 수신 여부선택 동의
				<br />
				b. 서비스 이용
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 암호화된 비밀번호
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Account Info (Klaytn
				Account Address)
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 이메일 주소, 휴대전화번호,
				카카오톡 ID식별자 선택 동의
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 이용자의 서비스 내 방문
				기록 및 활동 로그 선택 동의
				<br />
				c. 서비스 이용 과정에서 자동 생성・수집되는 정보
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 서비스 이용 기록, 접속
				로그, 거래기록, IP 정보, 쿠키, 불량 및 부정 이용 기록, 모바일
				기기 정보 (모델명, OS 정보, 화면 사이즈, 언어 및 국가정보 등)
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 서비스에 대한 불법/부정
				접근 행위 및 관련 기록, 서비스에 대한 접근 시도 기록, 서비스 및
				서비스 어플리케이션의 안전한 동작 환경 확인에 필요한 정보
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 토큰 추가 목록필수
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 사용자 설정 정보
				<br />
				d. 고객 상담
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 이메일주소, 상담챗ID
				<br />
				<br />
				2. 개인정보 수집・이용 목적
				<br />
				<br />
				a. 이용자 정보 관리
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 이용자 식별, 계정의 접근
				및 사용 권한 관리, 이용자 정보 관리, 각종 고지사항 전달
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 신규 서비스 개발, 다양한
				서비스 제공
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 이용자 상담 및 민원처리,
				고객 피해보상
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 비대면 본인 인증을 통한
				탈퇴 처리, 개인정보 변경 및 송금 비밀번호 초기화 등<br />
				b. 서비스 제공
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 본인 확인, 개인 환경 설정,
				디지털 자산 전송 내역 확인
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 디지털 자산 입출금 처리 등
				서비스 관리 전반에 관한 사항
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 타 지갑 연동 시 거래 건에
				대한 정산 및 대사
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 카카오톡 친구에게
				공유하기, 토큰 보내기
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 서비스 안정성 확보, 안전한
				서비스 제공, 법률 및 서비스 이용약관 위반 행위 제한 등<br />
				c. 이벤트 정보 안내
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 각종 이벤트 및 광고성 정보
				제공
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 신규 서비스 및 맞춤형
				서비스 제공 등<br />
				3. 개인정보 수집 방법
				<br />
				<br />
				a. 온라인
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 서비스 회원가입 및 이용,
				카카오톡 상담, 이메일 상담 등을 통한 수집
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 타 서비스에서 제3자 제공
				동의를 통한 수집
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 자동수집 장치를 통한 수집
				<br />
				<br />
				<SubTitle>제 2 조 (개인정보의 보유 및 이용기간)</SubTitle>
				회사는 법령에 따른 개인정보 보유.이용기간 또는 이용자로부터
				개인정보의 수집 시에 동의 받은 개인정보 보유. 이용기간 내에서
				개인정보를 처리합니다. 각각의 개인정보 보유 및 이용기간은 다음과
				같습니다.
				<br />
				1. 개인정보 보유 및 이용기간
				<br />
				회원 관리, 서비스 이용 및 제공, 이용자 식별 (회원탈퇴 후 30일)
				<br />
				다만, 관계 법령 위반에 따른 수사.조사 등이 진행 중인 경우에는
				수사.조사 종료 시까지 보유 및 이용합니다.
				<br />
				2. 서비스 제공에 따른 법률에 의한 보존
				<br />
				① 이용자의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이
				달성되면 지체없이 파기합니다. 단, 서비스 부정 이용에 따른 분쟁을
				방지하기 위한 내부 방침에 따라 부정이용기록은 1 년간 보존할 수
				있습니다.
				<br />
				② 회사는 법령 및 ‘개인정보 유효 기간제’에 따라 1 년간 서비스를
				이용하지 않는 장기 미 이용 회원의 개인정보를 서비스 미이용 기간
				만료 30일 전까지 개인정보가 파기되거나 분리되어 저장/관리 된다는
				사실 및 일시, 개인정보 항목을 전자우편, 서면, 팩스, 전화 또는
				이에 준하는 방법으로 통지한 후 별도로 분리 저장/관리 또는
				파기하고 있습니다. 분리 보관된 개인정보는 4 년간 보관 후
				지체없이 파기 합니다. 단, 법령에서 일정 기간 정보 보관 의무를
				부과하는 경우에는 해당 기간 동안 개인 정보를 안전하게
				보관합니다.
				<br />
				a. 전자상거래 등에서의 소비자보호에 관련한 법률 <br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 계약 또는 청약철회 등에
				관한 기록 : 5년
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 소비자의 불만 또는 분쟁
				처리에 관한 기록 : 3년
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 표시. 광고에 관한 기록:
				6개월
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 보상 및 오 입금에 관한
				기록 : 5년
				<br />
				b. 통신비밀보호법
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 로그인 기록 : 3개월
				<br />
				c. 특정금융거래정보 보고 및 이용 등에 관한 법률
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 특정금융거래 및 본인확인에
				관한 기록 : 5년
				<br />
				<SubTitle>제 3 조 (개인정보처리의 제공 및 위탁)</SubTitle>
				1. 개인정보처리 위탁
				<br />
				회사는 서비스 제공에 있어 필요한 업무 중 일부를 외부 업체가
				수행하도록 개인 정보를 위탁하고 있습니다. 그리고 위탁 받은
				업체가 관계 법령을 위반하지 않도록 관리.감독하고 있습니다.
				회사가 개인정보 처리를 위탁한 업체는 다음과 같습니다.
				<br />
				<br />
				- 비즈톡(주) : 카카오 알림톡 발송 대행
				<br />
				- ㈜카카오 : 카카오톡 채널 관리 및 메시지 발송
				<br />
				2. 개인정보처리 제공
				<br />
				회사는 이용자의 개인정보를 회원의 동의 없이 제3자에게 제공하지
				않으며, 제3자 제공이 필요하게 된 경우 이용자에게 해당 사실을
				알리고 동의를 얻은 후에 개인정보를 제3자에게 제공하고 있습니다.
				<br />
				다만, 다음 각 호의 경우에는 제3자에게 제공될 수 있습니다.
				<br />
				<br />
				① 다른 법률에 특별한 규정이 있는 경우
				<br />
				② 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에
				있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히
				정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여
				필요하다고 인정되는 경우
				<br />
				③ 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정
				개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우
				<br />
				<SubTitle>
					제 4 조 (이용자 및 법정대리인의 권리.의무와 행사 방법)
				</SubTitle>
				이용자는 회사에 대해 언제든지 개인정보 열람・정정・삭제・처리
				정지 요구 등의 권리를 행사할 수 있습니다. 다만, 개인정보 보호법
				제35조 제4항, 제36조 제1항, 제37조 제2항 등 관계 법령에서 정하는
				바에 따라 이용자의 개인정보 열람・정정・삭제・처리 정지 요구
				등의 권리 행사가 제한될 수 있습니다.
				<br />
				이용자의 권리 행사는 개인정보 보호법 시행령 제41조 제1항에 따라
				서면, 전자우편, FAX 등을 통하여 하실 수 있으며, 회사는 이에 대해
				지체없이 조치하겠습니다.
				<br />
				제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자
				등 대리인을 통하여서 하실 수 있습니다. 이 경우 개인정보 보호법
				시행규칙 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.
				<br />
				개인정보의 정정 및 삭제 요구 시 다른 법령에서 그 개인정보가 수집
				대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
				<br />
				회사는 이용자 이용 권리에 따른 열람의 요구, 정정.삭제의 요구,
				처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
				대리인인지를 확인합니다.
				<br />
				이용자는 권리행사의 거부 등에 대하여 불복 시 회사에 이의제기를
				하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
				<br />
				<br />
				<SubTitle>제 5 조 (개인정보의 파기)</SubTitle>
				회사는 개인정보 보유 기간의 경과, 처리 목적 달성 등 개인정보가
				불필요하게 되었을 때는 지체없이 해당 개인정보를 파기합니다.
				<br />
				이용자로부터 동의 받은 개인정보 보유 기간이 지나거나 처리 목적이
				달성되었음에도 불구하고 법령에 따라 개인정보를 계속 보존하여야
				하는 경우에는, 해당 개인 정보를 별도의 데이터베이스(DB) 로
				옮기거나 보관장소를 달리하여 보존합니다.
				<br />
				개인정보 파기 방법은 다음과 같습니다.
				<br />
				<br />
				① 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없도록
				영구 삭제
				<br />
				② 종이 문서에 기록.저장된 개인정보는 분쇄기로 분쇄하거나 소각
				<br />
				<SubTitle>
					제 6 조 (개인정보의 기술적.관리적 보호 대책)
				</SubTitle>
				회사는 이용자들의 개인정보를 처리하면서 개인정보가 분실, 도난,
				누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
				기술적/관리적 대책을 마련하고 있습니다.
				<br />
				1. 관리적 조치
				<br />
				<br />
				① 내부관리계획 수립 및 시행
				<br />
				② 개인정보 취급 직원의 최소화 및 교육 : 개인정보를 취급하는
				직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를
				관리하는 대책을 시행하고 있습니다.
				<br />
				2. 기술적 조치
				<br />
				<br />
				① 개인정보처리시스템 접근권한 관리 : 개인정보를 처리하는
				데이터베이스 시스템에 대한 접근권한의 부여, 변경, 말소를 통하여
				개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며
				침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
				있습니다.
				<br />
				② 개인정보의 암호화 : 고객의 개인정보인 비밀번호는 암호화 되어
				저장 및 관리되고 있어 본인만이 알 수 있으며, 중요한 데이터는
				파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는
				등의 별도 보안기능을 사용하고 있습니다.
				<br />
				③ 해킹 등에 대비한 기술적 대책 : 해킹이나 바이러스 등에 의한
				개인정보 유출 및 훼손을 막기 위하여 주기적으로 점검을 하며
				외부로부터 접근이 통제된 구역에 시스템을 설치하는 등
				기술적·물리적으로 감시 및 차단하고 있습니다.
				<br />④ 물리적 조치 : 전산실, 자료 보관실 등의 접근 통제
				<SubTitle>
					제 7 조 (개인정보 자동수집 장치의 설치.운영 및 그 거부에
					관한 사항)
				</SubTitle>
				회사는 이용자의 서비스 편의를 제공하기 위해 이용 정보를 저장하고
				수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트가
				고객의 컴퓨터 브라우저(인터넷 익스플로러 등)에 전송하는 소량의
				정보입니다.
				<br />
				1. 쿠키의 사용 목적
				<br />
				쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 더욱
				빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에
				활용합니다. 이를 통해 이용자는 더욱 손쉽게 서비스를 이용할 수
				있게 됩니다.
				<br />
				2. 쿠키의 설치.운영 및 거부
				<br />
				이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 언제든지
				이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.
				<br />
				3. 쿠키 설정 거부 방법
				<br />
				① Internet Explorer : 도구 메뉴 선택 &gt; 인터넷 옵션 선택 &gt;
				개인정보 탭 클릭 &gt; 고급 개인정보 설정 &gt; 쿠키 수준 설정
				<br />
				② Chrome : 설정 메뉴 선택 &gt; 고급 설정 표시 선택 &gt; 개인정보
				및 보안 &gt; 콘텐츠 설정 선택 &gt; 쿠키 수준 설정
				<br />
				③ Safari : 환경설정 메뉴 선택 &gt; 개인정보 탭 선택 &gt; 쿠키 및
				웹 사이트 데이터 수준 설정
				<br />
				④ FireFox: 도구 메뉴 선택 &gt; 환경 설정 메뉴 선택 &gt; 개인
				정보 및 보안 탭 선택 &gt; 콘텐츠 차단 설정
				<br />
				⑤ Opera: 도구 메뉴 선택 &gt; 설정 메뉴 선택 &gt; 개인 정보 보호
				및 보안 탭 선택 &gt; 쿠키 수준 설정
				<br />
				4. 구글 애널리틱스(Google Analytics)
				<br />
				① 회사는 고객에게 더 나은 서비스를 제공하기 위한 목적으로
				Google, Inc. (이하 ‘Google’)이 제공하는 웹 분석 서비스인 구글
				애널리틱스(Google Analytics)를 사용하여 고객들이 회사의 서비스를
				어떻게 이용하는지 분석 및 평가하고 고객의 수요를 파악하며,
				서비스와 제품을 개선하고 맞춤화하여 효율적인 서비스를 제공하는
				것에 목적이 있습니다.
				<br />
				② 회사는 통계 작성 및 학술 연구 등의 목적을 위하여 구글
				애널리틱스(Google Analytics)에서 사용될 수 있습니다.
				<br />
				③ 구글 애널리틱스(Google Analytics)를 통해 수집되는 정보의
				처리는 Google 개인정보보호정책 및 Google Analytics 서비스
				이용약관을 적용 받습니다.
				<br />
				④ IP 주소는 마지막 두개의 옥텟(Octet)이 제거 되어 익명화 되어
				저장되며, 다른 구글 데이터와 병합 또는 연결시키지 않습니다.
				<br />
				<SubTitle>제 8 조 (개인정보 보호 책임자 및 담당 부서)</SubTitle>
				회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을
				처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를
				지정하고 있습니다.
				<br />
				이름: 송상훈
				<br />
				직책: 개인정보 보호책임자
				<br />
				전화번호: 02-6385-0031
				<br />
				이메일: piecial@impactstation.kr
				<br />
				이용자가 회사의 서비스를 이용하며 발생하는 모든 개인정보 보호
				관련 민원은 개인정보 보호 책임자 및 담당 부서로 문의하실 수
				있습니다. 회사는 이용자의 문의 사항에 대해 답변 및 처리해드릴
				것입니다.
				<br />
				<br />
				<SubTitle>제 9 조 (권익침해 구제방법)</SubTitle>
				개인정보 침해에 대한 피해구제, 상담 등이 필요할 경우 다음 기관에
				문의하실 수 있습니다.
				<br />
				개인정보 침해신고센터 (한국인터넷진흥원 운영)
				<br />
				홈페이지: privacy.kisa.or.kr
				<br />
				전화: (국번없이) 118
				<br />
				개인정보 분쟁조정위원회
				<br />
				홈페이지: www.kopico.go.kr
				<br />
				전화: (국번없이) 1833-6972
				<br />
				대검찰청 사이버범죄수사단
				<br />
				홈페이지: www.spo.go.kr
				<br />
				전화: 02-3480-3573
				<br />
				경찰청 사이버안전국
				<br />
				홈페이지: cyberbureau.police.go.kr
				<br />
				전화: (국번없이) 182
				<br />
				<SubTitle>제 10 조 (링크 사이트에 대한 책임)</SubTitle>
				회사는 이용자에게 다른 외부 사이트로 연결되는 링크를 제공할 수
				있습니다. 이 경우 회사는 외부 사이트에 대한 통제권이 없으므로
				이용자가 외부 사이트로부터 제공받은 서비스나 자료의 유용성,
				진실성, 적법성에 대해 책임 및 보증할 수 없으며, 링크된 외부
				사이트의 개인정보처리방침은 회사와 무관하므로 해당 외부 사이트의
				정책을 확인하시기 바랍니다.
				<br />
				<br />
				<SubTitle>제 11 조 (개인정보처리방침 변경)</SubTitle>
				현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정
				최소 15 일 이전부터 ‘공지사항’을 통해 알릴 것입니다. 다만,
				개인정보의 수집 및 활용, 제 3 자 제공 등과 같이 이용자 권리의
				중요한 변경이 있을 때는 최소 30 일 이전에 알립니다.
				<br />
				<br />
			</p>
		</Container>
	);
};
const Container = styled.div`
	width: 1200px;
	margin: 20px auto;
	p {
		font-size: 12px;
	}
	${(props) => props.theme.device.md} {
		width: 100%;
		padding: 0 16px;
	}
`;
const Title = styled.div`
	font-size: 25px;
	margin: 10px 0;
	font-weight: bold;
`;
const SubTitle = styled.div`
	margin: 10px 0;
	font-size: 16px;
	font-weight: bold;
`;
export default Policy;
