import React from 'react';
import styled from 'styled-components';

const UserInput = ({
	type = 'text',
	value,
	text,
	name,
	pattern,
	placeholder,
	maxLength,
	button,
	buttonOnclick,
	handlechange,
	isLoading,
	isRequired,
	disabled = false,
}) => {
	return (
		<Wrapper>
			<Label>
				{text}
				{isRequired && <span>*</span>}
			</Label>
			<Row>
				<input
					value={value}
					pattern={pattern}
					name={name}
					type={type}
					maxLength={maxLength}
					placeholder={placeholder}
					onChange={handlechange}
					disabled={disabled}
				/>
				{button && (
					<Button
						isLoading={isLoading}
						onClick={isLoading ? null : buttonOnclick}
					>
						{button}
					</Button>
				)}
			</Row>
		</Wrapper>
	);
};

const Wrapper = styled.article`
	width: 100%;
	display: flex;
	flex-direction: column;

	& + & {
		margin-top: 40px;
		${(props) => props.theme.device.md} {
			margin-top: 30px;
		}
	}
`;
const Label = styled.span`
	font: normal normal 600 24px/29px Pretendard;
	letter-spacing: -0.24px;
	margin-bottom: 15px;

	& > span {
		color: #4568dc;
	}

	${(props) => props.theme.device.md} {
		line-height: 20px;
		font-weight: bold;
		font-size: 17px;
		letter-spacing: -0.17px;
		color: #1a1a1a;
	}
`;
const Row = styled.div`
	display: flex;
	align-items: center;

	input {
		width: 100%;
		height: 55px;
		border: 1px solid #bebebe;
		border-radius: 10px;
		font-size: 16px;
		letter-spacing: -0.16px;
		padding: 18px 20px;

		${(props) => props.theme.device.md} {
			height: 41px;
			background: #ffffff;
			border: 1px solid #bebebe;
			font-size: 13px;
			letter-spacing: -0.13px;
			padding: 12px 17px;
		}
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
`;
const Button = styled.button`
	width: 180px;
	height: 55px;
	background: #000000;
	border-radius: 10px;
	font-size: 18px;
	letter-spacing: -0.45px;
	color: #ffffff;
	margin-left: 20px;
	flex-shrink: 0;

	${(props) => props.theme.device.md} {
		width: 103px;
		height: 41px;
		background: #1a1a1a;
		font-size: 15px;
		letter-spacing: -0.15px;
		margin-left: 7px;
	}
`;
export default UserInput;
