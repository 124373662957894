import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import api from 'lib/api';
import * as Artwork from 'components/common/ArtworkComps';
import FilterSelector from 'components/common/FilterSelector';
import OrderBySelector from 'components/common/OrderBySelector';
import SearchField from 'components/common/SearchField';
import Paginator from 'components/Paginator';

const ARTWORK_PER_PAGE = 21;
const ArtworkSection = () => {
	const history = useHistory();
	const { id } = useParams();

	const [page, setPage] = useState(1);
	const [orderBy, setOrderBy] = useState({
		label: '추천 순',
		value: '-recommandation_point',
	});
	const [priceStart, setPriceStart] = useState(null);
	const [priceEnd, setPriceEnd] = useState(null);
	const [query, setQuery] = useState('');
	const [queryBuffer, setQueryBuffer] = useState(''); // 검색창에서 엔터 입력 시, 쿼리 요청 용

	const [artworkCount, setArtworkCount] = useState(0);
	const [artworks, setArtworks] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const {
					response: {
						data: { count, results },
					},
				} = await api.get(
					`/artworks/?order_by=${orderBy.value}&artist=${id}${
						priceStart ? `&price_starts=${priceStart}` : ''
					}${priceEnd ? `&price_ends=${priceEnd}` : ''}${
						query ? `&search=${query}` : ''
					}&page=${page}&per_page=${ARTWORK_PER_PAGE}`,
				);
				setArtworkCount(count);
				setArtworks(results);
			} catch (e) {
				setArtworkCount(0);
				setArtworks([]);
			}
			window.scrollTo(0, 0);
		})();
	}, [id, orderBy.value, priceStart, priceEnd, query, page]);

	return (
		<Wrapper>
			<Header>
				<ArtworkCount>{artworkCount}</ArtworkCount>
				<Filters>
					<OrderBySelector
						orderBy={orderBy}
						onChange={(orderby) => setOrderBy(orderby)}
					/>
					<FilterSelector
						onChange={(priceStart, priceEnd) => {
							setPriceStart(priceStart);
							setPriceEnd(priceEnd);
						}}
					/>
				</Filters>

				<Search>
					<SearchField
						value={queryBuffer}
						onChange={(e) => setQueryBuffer(e.target.value)}
						onQuery={() => setQuery(queryBuffer)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								setQuery(queryBuffer);
							}
						}}
					/>
				</Search>
			</Header>

			<Artwork.CurationList>
				{artworks.map((artwork) => (
					<Artwork.Artwork
						key={artwork.id}
						onClick={() =>
							history.push(`/products/detail/${artwork.id}`)
						}
					>
						<Artwork.Category>{artwork.category}</Artwork.Category>
						<Artwork.Thumbnail $thumbnail={artwork.thumbnail} />
						<Artwork.ArtworkTitle>
							{artwork.name}
						</Artwork.ArtworkTitle>

						<Artwork.PriceRow>
							<div className='extra-row'>
								{artwork.discountPrice > 0 && (
									<div className='price'>
										{artwork.price.toLocaleString()}
									</div>
								)}

								{artwork.discountPrice > 0 && (
									<div className='discount-rate'>{'xx%'}</div>
								)}
							</div>
							{(
								artwork.discountPrice || artwork.price
							).toLocaleString()}
							원
						</Artwork.PriceRow>
					</Artwork.Artwork>
				))}
			</Artwork.CurationList>

			{artworkCount > ARTWORK_PER_PAGE && (
				<Paginator
					pageSize={ARTWORK_PER_PAGE}
					totalPage={artworkCount}
					handlePageChange={(page) => setPage(page.selected + 1)}
				/>
			)}
		</Wrapper>
	);
};
export default ArtworkSection;

const Wrapper = styled.section`
	width: 100%;
	margin-top: 80px;

	${(p) => p.theme.device.md} {
		padding: 0 24px;
		margin-top: 111px;
	}
`;
const Header = styled.section`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;

	${(p) => p.theme.device.md} {
		width: 100%;
		margin-bottom: 16px;
	}
`;
const ArtworkCount = styled.div`
	font: normal normal 600 22px/26px Pretendard;
	letter-spacing: -0.22px;
	color: #111111;

	&::after {
		content: ' items';
	}

	${(p) => p.theme.device.md} {
		font: normal normal 600 17px/20px Pretendard;
		letter-spacing: -0.17px;
		color: #1a1a1a;
	}
`;
const Filters = styled.div`
	display: flex;
	align-items: center;
	height: 21px;

	${(p) => p.theme.device.md} {
		height: 28px;
		padding: 6px 16px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 0.5px solid #bebebe;
		border-radius: 17px;
	}
`;

const Search = styled.div`
	position: absolute;
	right: 220px;
	bottom: 0;

	${(p) => p.theme.device.md} {
		width: 100%;
		right: 0;
		bottom: 44px;

		input {
			width: 100%;
		}
	}
`;
