import React from 'react';

import { BannerSpinner } from 'components/Main';
import styled from 'styled-components';
import { CenterLayout } from 'components/common/LayoutComps';
import * as Curation from 'components/common/ArtworkComps';

import CurationSection from './CurationSection';
import GallerySection from './GallerySection';

const Main = () => (
	<Wrapper>
		<BannerSpinner />
		<CenterLayout>
			<GallerySection />

			<Curation.Title>Art</Curation.Title>
			<CurationSection />
		</CenterLayout>
	</Wrapper>
);

export default Main;

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
