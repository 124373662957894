import React from 'react';
import styled from 'styled-components';

const NoHistory = ({ content }) => {
	return (
		<Wrapper>
			<Highlight>{content}</Highlight>
			다양한 NFT 작품들을 둘러보세요!
		</Wrapper>
	);
};
const Wrapper = styled.div`
	padding-top: 175px;
	padding-bottom: 153px;
	text-align: center;
	line-height: 19px;
	font-size: 16px;
	letter-spacing: -0.16px;
	color: #111111;
	${(props) => props.theme.device.md} {
		font: normal normal 400 15px/16px Pretendard;
		letter-spacing: -0.15px;
		color: #777777;
		padding-top: 124px;
		padding-bottom: 66px;
	}
`;
const Highlight = styled.div`
	line-height: 36px;
	font-weight: bold;
	font-size: 30px;
	letter-spacing: -0.3px;
	color: #111111;
	margin-bottom: 26px;
	${(props) => props.theme.device.md} {
		font: normal normal bold 22px/26px Pretendard;
		letter-spacing: -0.22px;
		color: #111111;
	}
`;
export default NoHistory;
