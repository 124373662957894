import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { onClickPayment } from 'lib/checkout';
import { useSelector } from 'react-redux';
import useFetch from 'lib/hooks/useFetch';
import * as storage from 'lib/storage';
import dayjs from 'dayjs';

import Artwork from './Artwork';
import Buyer from './Buyer';
import PaymentMethod from './PaymentMethod';
import PaymentInfo from './PaymentInfo';

const Checkout = () => {
	const history = useHistory();
	const search = useLocation().search;
	const id = new URLSearchParams(search).get('id');
	const price = new URLSearchParams(search).get('price');
	const amount = new URLSearchParams(search).get('amount');
	const purchase = new URLSearchParams(search).get('purchase');
	const purchaseType = new URLSearchParams(search).get('purchaseType');

	const [paymentInfo, setPaymentInfo] = useState({
		paymentMethod: 'card',
		buyerAddr: '',
	});

	const [agreement, setAgreement] = useState({
		commonPolicy: false,
		personalPolicy: false,
		checkoutPolicy: false,
	});

	const { option, author } = useSelector(({ detail }) => ({
		author: detail.author,
		option: detail.option,
	}));

	const [selectedOption, setSelectedOption] = useState({});

	const {
		executeFetch: getProfile,
		data: _user,
		error,
		isLoading: isProfileLoading,
	} = useFetch(
		'/user/',
		'get',
		{},
		{
			immediate: false,
		},
	);
	const user = _user || {};

	const {
		executeFetch: getWork,
		data: _work,
		isLoading: isWorkLoading,
	} = useFetch(
		`/artworks/${id}/`,
		'get',
		{},
		{
			immediate: false,
		},
	);

	const work = (_work || {}).data || {};

	useEffect(() => {
		getProfile();
		getWork();
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (option) {
			console.log('>>>>', option);
			setSelectedOption({
				...option,
				amount: Number(amount),
				totalPrice: amount * price,
				selected: purchase,
			});
		}
	}, [option]);

	// useEffect(() => {
	// 	if (!option?.selected) {
	// 		history.push(`/products/detail/${id}`);
	// 	}
	// }, [option]);

	const payment = {
		pg:
			paymentInfo.paymentMethod === 'paypal'
				? 'paypal'
				: paymentInfo.paymentMethod === 'kakaopay'
				? 'kakaopay'
				: 'html5_inicis', // PG사
		pay_method:
			paymentInfo.paymentMethod === 'kakaopay'
				? 'card'
				: paymentInfo.paymentMethod, // 결제수단
		merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
		amount: amount * price, // 결제금액
		name: work.name, // 주문명
		buyer_email: user.username || 'test@test.com', // 구매자 이메일
		buyer_name: user.name || '홍길동', // 구매자 이름
		buyer_tel: user.phoneNumber || '010-1234-5678', // 구매자 전화번호
		m_redirect_url:
			window.location.origin +
			`/checkout/mobile?amount=${amount * price}&pay_method=${
				paymentInfo.paymentMethod
			}`,
		// buyer_addr: paymentInfo.buyerAddr, // 구매자 주소
	};

	const handleChange = (name, value) => {
		setPaymentInfo({
			...paymentInfo,
			[name]: value,
		});
	};
	const [errormsg, setError] = useState('');

	const orderPayload = {
		name: user.name || '홍길동',
		originalPrice: amount * price,
		originalFile: work.originalFile,
		discountedPrice: amount * price,
		discountInfo: {
			originalPrice: amount * price,
			discountPrice: work.discountPrice || 0,
			boucherDiscountPrice: work.boucherDiscount
				? work.boucherDiscount.discountPrice || 0
				: 0,
		},
		artworkId: id,
		artworkInfo: work,
		fullAddress: paymentInfo.buyerAddr,
		paymentMethod: paymentInfo.paymentMethod,
		paymentInfo: {},
		orderLines: {
			...selectedOption,
			paymentedAt: dayjs().format('YYYY.MM.DD hh:mm:ss'),
		},
		agreement,
	};

	const {
		executeFetch: orderExecuteFetch,
		data: orderData,
		error: orderError,
		isLoading: isOrderLoading,
	} = useFetch(
		'/orders/',
		'post',
		{ ...orderPayload },

		{
			immediate: false,
		},
	);

	// const handleCheckout = () => {

	// 	if (!agreement?.commonPolicy) {
	// 		setError('일부 항목 입력이 유효하지 않습니다.');
	// 		return;
	// 	}

	// 	storage.setArtWork(id, work);
	// 	storage.setAgreement(agreement);
	// 	storage.setOption(selectedOption);
	// 	if (paymentInfo.paymentMethod === 'card') {
	// 		onClickPayment(payment);
	// 		storage.setPayment(orderPayload);
	// 	} else {
	// 		orderExecuteFetch();
	// 		storage.setPayment(orderPayload);
	// 		history.push(`/checkout/success?id=${id}`);
	// 	}
	// };

	const handleCheckout = () => {
		if (!agreement?.commonPolicy) {
			setError('일부 항목 입력이 유효하지 않습니다.');
			return;
		}

		console.log(payment);

		storage.setArtWork(id, work);
		storage.setAgreement(agreement);
		storage.setOption(selectedOption);
		// if (paymentInfo.paymentMethod === 'card') {
		onClickPayment(payment);
		storage.setPayment(orderPayload);
		// } else {
		// 	try {
		// 		orderExecuteFetch();
		// 		storage.setPayment(orderPayload);
		// 		history.push(`/checkout/success?id=${id}`);
		// 	} catch (e) {
		// 		alert('에러가 발생하였습니다.');
		// 	}
		// }
	};
	// const handlePolicy = (path) => {
	// 	history.push(path);
	// };

	// if (isProfileLoading || isWorkLoading) {
	// 	return <></>;
	// }

	return (
		<Wrapper>
			<TitleContainer>
				<div>작품 구매</div>
				<div className='title'>결제하기</div>
			</TitleContainer>

			<Artwork work={work} />
			<Buyer user={user} />
			<PaymentInfo
				purchaseType={purchaseType}
				quantity={amount}
				price={price}
			/>
			<PaymentMethod
				paymentInfo={paymentInfo}
				handleChange={handleChange}
			/>

			<CheckoutInfo>
				<AgreementContainer>
					<InfoItem>
						<input
							type='checkbox'
							id='common'
							checked={agreement.commonPolicy}
							onChange={() => {
								setAgreement({
									...agreement,
									commonPolicy: !agreement.commonPolicy,
								});
							}}
						/>
						<label htmlFor='common'>
							주문 내역을 확인하였으며, 이에 동의합니다. (필수)
						</label>
					</InfoItem>
				</AgreementContainer>
				<ButtonContainer>
					<button onClick={handleCheckout}>결제하기</button>
					{errormsg && <Error>{errormsg}</Error>}
				</ButtonContainer>
			</CheckoutInfo>
		</Wrapper>
	);
};

export default Checkout;

const Error = styled.div`
	font: normal normal 400 18px/21px Pretendard;
	letter-spacing: 0px;
	color: #fe7668;
	margin-top: 22px;

	${(props) => props.theme.device.md} {
		font: normal normal 500 14px/17px Pretendard;
		letter-spacing: -0.14px;
	}
`;
const Wrapper = styled.div`
	width: 1200px;
	padding-top: 80px;

	${(props) => props.theme.device.md} {
		width: 100%;
		padding: 35px 24px 0 24px;
		/* background: #f8f8f8 0% 0% no-repeat padding-box; */
	}
`;
const TitleContainer = styled.div`
	font: normal normal 400 22px/26px Pretendard;
	letter-spacing: -0.11px;
	color: #666666;
	margin-bottom: 27px;
	div.title {
		background: #ffffff 0% 0% no-repeat padding-box;
		font: normal normal bold 37px/45px Pretendard;
		letter-spacing: -0.37px;
		color: #111111;
		padding-top: 12px;
	}

	${(props) => props.theme.device.md} {
		font: normal normal 500 14px/17px Pretendard;
		letter-spacing: 0px;
		color: #777777;
		margin-bottom: 38px;
		div.title {
			font: normal normal bold 23px/27px Pretendard;
			letter-spacing: -0.23px;
			color: #1a1a1a;
			margin-top: 6px;
		}
	}
`;
const CheckoutInfo = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	width: 100%;
	margin-top: 209px;
	${(props) => props.theme.device.md} {
		margin-top: 80px;
		padding-top: 60px;
	}
`;
const InfoItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: ${(props) =>
		props.justify ? 'space-between' : 'flex-start'};
	margin-bottom: 30px;
	&:last-child {
		margin-bottom: 0;
	}
	span {
		font-size: 15px;
	}
	span.label {
		min-width: 20%;
	}
	span.bold {
		font-weight: bold;
	}
	input {
		height: 24px;
		margin-right: 10px;
	}
	label {
		line-height: 24px;
	}
	input.address {
		width: 100%;
		height: 38px;
		border: 1px solid #dddddd;
		border-radius: 4px;
		padding: 10px;
	}
	input::placeholder {
		color: #dddddd;
	}

	& > input[type='checkbox'] {
		width: 27px;
		height: 27px;
		${(props) => props.theme.device.md} {
			width: 12px;
			height: 12px;
		}
	}
`;
// (필수) 동의
const AgreementContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font: normal normal 400 20px/24px Pretendard;
	letter-spacing: -0.2px;
	color: #333333;

	${(props) => props.theme.device.md} {
		width: 100%;
		font: normal normal 500 15px/18px Pretendard;
		letter-spacing: -0.15px;
		color: #1a1a1a;
	}
`;
// 결제하기 버튼
const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 50px 0;

	${(props) => props.theme.device.md} {
		margin-top: 26px 0;
		padding-bottom: 126px;
	}

	& > button {
		width: 384px;
		height: 72px;
		background: #242424 0% 0% no-repeat padding-box;
		border-radius: 10px;
		font: normal normal 600 22px/26px Pretendard;
		letter-spacing: -0.55px;
		color: #ffffff;

		${(props) => props.theme.device.md} {
			width: 366px;
			height: 47px;
			background: #1a1a1a 0% 0% no-repeat padding-box;
			border-radius: 10px;
			font: normal normal 400 17px/20px Pretendard;
			letter-spacing: -0.17px;
			color: #ffffff;
		}
	}
`;
