import React from 'react';
import Header from './Header';
import Footer from './Footer';
import styled from 'styled-components';

const Layout = ({ children }) => {
	return (
		<>
			<Header />
			<Container>{children}</Container>
			<Footer />
		</>
	);
};
const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	${(props) => props.theme.device.md} {
		margin-top: 108px;
	}
`;
export default Layout;
