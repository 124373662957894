import { useEffect, useState } from 'react';
// import { sortBy } from 'lodash';

import API from 'lib/api';

const useUser = () => {
	const [user, setUser] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const {
					response: { data },
				} = await API.get('/user/');
				setUser(data);
			} catch (e) {
				setUser(null);
			}
		})();
	}, []);

	return user;
};
export default useUser;
