import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Layout from 'components/common/Layout';
import Collections from 'containers/Collections';
import CollectionDetail from 'containers/CollectionDetail';
import Gallery from 'containers/Gallery';
import GalleryDetail from 'containers/GalleryDetail';
import Listing from 'containers/Listing';
import Main from 'containers/Main';
import SignIn from 'containers/SignIn';
import SNSLoginCallBack from 'containers/SignIn/SNSLoginCallBack';
import FindPassword from 'containers/FindPassword';
import NewPassword from 'containers/FindPassword/NewPassword';
import SignUp from 'containers/SignUp';
import Register from 'containers/SignUp/Register';
import ArtworkDetail from 'containers/ArtworkDetail';
import Checkout from 'containers/Checkout';
import CheckoutSuccess from 'containers/Checkout/Success';
import CheckoutMobile from 'containers/Checkout/Mobile';
import PurchaseHistory from 'containers/MyPage/PurchaseHistory';
import Likes from 'containers/MyPage/Likes';
import SalesHistory from 'containers/MyPage/SalesHistory';
import MyWorks from 'containers/MyPage/MyWorks';
import Profile from 'containers/MyPage/Profile';
import ChangePassword from 'containers/MyPage/ChangePassword';
import Registration from 'containers/Resistration';
// import Community from 'containers/Community';
import About from 'containers/About';
import Terms from 'containers/Terms';
import Policy from 'containers/Policy';
import Notice from 'containers/Notice';
import NoticeDetail from 'containers/Notice/Detail';
// import CommunityDetail from 'components/Community/Detail';

const Routes = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Layout>
					<Route exact path='/' render={() => <Main />} />
					<Route exact path='/login' render={() => <SignIn />} />
					<Route
						exact
						path='/login/naver/callback'
						render={() => <SNSLoginCallBack />}
					/>
					<Route
						exact
						path='/login/kakao/callback'
						render={() => <SNSLoginCallBack />}
					/>
					<Route
						exact
						path='/find/password'
						render={() => <FindPassword />}
					/>
					<Route
						exact
						path='/find/password/reset'
						render={() => <NewPassword />}
					/>
					<Route exact path='/register' render={() => <Register />} />
					<Route exact path='/signup' render={() => <SignUp />} />
					{/* 큐레이션 페이지 */}
					<Route exact path='/listing' render={() => <Listing />} />
					{/* Collections 페이지 */}
					<Route
						exact
						path='/collections'
						render={() => <Collections />}
					/>
					<Route
						exact
						path='/collection/detail/:id'
						render={() => <CollectionDetail />}
					/>
					{/* Gallery 페이지 */}
					<Route exact path='/gallery' render={() => <Gallery />} />
					<Route
						exact
						path='/gallery/detail/:id'
						render={() => <GalleryDetail />}
					/>
					<Route
						exact
						path='/products/detail/:id'
						render={() => <ArtworkDetail />}
					/>

					<Route exact path='/checkout' render={() => <Checkout />} />
					<Route
						exact
						path='/checkout/success'
						render={() => <CheckoutSuccess />}
					/>
					<Route
						exact
						path='/checkout/mobile'
						render={() => <CheckoutMobile />}
					/>
					<Route
						exact
						path='/mypage'
						render={() => <PurchaseHistory />}
					/>
					<Route
						exact
						path='/mypage/likes'
						render={() => <Likes />}
					/>
					<Route
						exact
						path='/mypage/saleshistory'
						render={() => <SalesHistory />}
					/>
					<Route
						exact
						path='/mypage/myworks'
						render={() => <MyWorks />}
					/>
					<Route
						exact
						path='/mypage/editprofile'
						render={() => <Profile />}
					/>
					<Route
						exact
						path='/mypage/changepassword'
						render={() => <ChangePassword />}
					/>
					<Route
						exact
						path='/registration'
						render={() => <Registration />}
					/>
					{/* <Route
						exact
						path='/community'
						render={() => <Community />}
					/>
					<Route
						exact
						path='/community/detail'
						render={() => <CommunityDetail />}
					/> */}
					<Route exact path='/about' render={() => <About />} />
					<Route exact path='/terms' render={() => <Terms />} />
					<Route exact path='/policy' render={() => <Policy />} />
					<Route exact path='/notice' render={() => <Notice />} />
					<Route
						exact
						path='/notice/detail'
						render={() => <NoticeDetail />}
					/>
				</Layout>
			</Switch>
		</BrowserRouter>
	);
};
export default Routes;
