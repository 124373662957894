import React, { useMemo, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';

import ArtistNews from './ArtistNews';
import Purchase from './Purchase';
import RecommendNFT from './RecommendNFT';
import { ReactComponent as ImageSVG } from 'assets/svg/image.svg';
import api from 'lib/api';
import { getNickname } from 'lib/storage';
import useArtwork from 'hooks/useArtwork';

const ArtworkDetail = () => {
	const history = useHistory();
	const { id } = useParams();

	const artwork = useArtwork(id);

	const fileExtension = useMemo(() => {
		if (!artwork) return null;

		const matched = artwork.originalFile.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
		if (!matched) return null;
		if (matched.length > 0) {
			return matched[1].toLowerCase();
		}
		return null;
	}, [artwork]);

	useEffect(() => {
		window.scrollTo(0, 0);
		api.post(`/artworks/${id}/viewed/`);
	}, [id]);

	return (
		<Wrapper>
			{artwork ? (
				<>
					<Row>
						<Thumbnail>
							{!/mp4/.test(fileExtension) && (
								<img
									src={
										!fileExtension ||
										/mp4|mp3|tif/.test(fileExtension)
											? !fileExtension
												? artwork.originalFile
												: artwork.thumbnail
											: artwork.originalFile
									}
									alt=''
								/>
							)}
							{/mp3/.test(fileExtension) && (
								<audio controls>
									<source
										src={artwork.originalFile}
										type='audio/mp3'
									/>
								</audio>
							)}
							{/mp4/.test(fileExtension) && (
								<video controls width='700'>
									<source
										src={artwork.originalFile}
										type='video/mp4'
									/>
								</video>
							)}
						</Thumbnail>

						<Purchase workInfo={artwork} />
					</Row>

					<Description>
						{artwork.description &&
							artwork.description.split('\n').map((line, i) => {
								return (
									<Fragment key={i}>
										{line}
										<br />
									</Fragment>
								);
							})}

						<div className='additional'>
							{artwork.additionalDesc &&
								artwork.additionalDesc
									.split('\n')
									.map((line, i) => {
										return (
											<Fragment key={i}>
												{line}
												<br />
											</Fragment>
										);
									})}
						</div>

						{artwork.gallery && (
							<ImageSVG
								onClick={() =>
									history.push(
										`/gallery/detail/${artwork.gallery}`,
									)
								}
							/>
						)}
					</Description>

					{artwork.ownerNickname === getNickname() && (
						<UpdateArtworkButton
							onClick={() =>
								history.push(
									`/registration?artworkId=${artwork.id}`,
								)
							}
						>
							수정하기
						</UpdateArtworkButton>
					)}

					<RecommendNFT artworkId={artwork.id} />
					<ArtistNews artistId={artwork.artist?.id} />
				</>
			) : (
				<div />
			)}
		</Wrapper>
	);
};
export default ArtworkDetail;

const Wrapper = styled.div`
	position: relative;
	width: 1200px;
	margin-top: 80px;

	${(props) => props.theme.device.md} {
		width: 100%;
		padding: 0 24px;
		margin-top: 35px;
	}
`;
const Row = styled.div`
	display: flex;
	gap: 76px;
	justify-content: space-between;

	${(props) => props.theme.device.md} {
		flex-direction: column;
		gap: 22px;
	}
`;
const Thumbnail = styled.div`
	width: 716px;
	height: 716px;
	background-color: #fff;
	text-align: center;
	overflow: hidden;
	position: relative;
	img,
	video,
	audio {
		object-fit: cover;
		width: 716px;
		height: 716px;
		border-radius: 15px;
	}
	audio {
		width: 700px;
		display: block;
		z-index: 999;
		position: absolute;
		bottom: 10px;
		left: 8px;
	}
	${(props) => props.theme.device.md} {
		width: 100%;
		height: auto;
		background-color: transparent;

		img,
		video,
		audio {
			width: 100%;
			height: auto;
			border-radius: 10px;
		}
		audio {
			width: 100%;
			display: block;
			position: absolute;
			bottom: 10px;
			left: 3px;
		}
	}
`;
const Description = styled.div`
	position: relative;
	border-top: 1px solid #bebebe;
	margin-top: 162px;
	padding-top: 30px;
	font: normal normal 400 17px/30px Pretendard;
	letter-spacing: -0.17px;
	color: #242424;

	& > .additional {
		margin-top: 83px;

		${(p) => p.theme.device.md} {
			margin-top: 27px;
			font: normal normal 500 14px/17px Pretendard;
			letter-spacing: -0.14px;
			color: #777777;
		}
	}

	&::before {
		content: 'Description';
		position: absolute;
		top: -43px;
		font: normal normal 500 20px/24px Pretendard;
		letter-spacing: -0.2px;
		color: #000000;
	}

	& > svg {
		cursor: pointer;
		width: 20px;
		height: 20px;
		position: absolute;
		top: -43px;
		right: 72px;
	}

	${(p) => p.theme.device.md} {
		margin-top: 114px;
		padding-top: 36px;
		font: normal normal 500 15px/22px Pretendard;
		letter-spacing: -0.15px;
		color: #1a1a1a;

		&::before {
			font: normal normal 500 15px/18px Pretendard;
			letter-spacing: -0.15px;
			top: -30px;
		}

		& > svg {
			width: 16px;
			height: 16px;
			top: -30px;
			right: 18px;
		}
	}
`;
const UpdateArtworkButton = styled.div`
	font: normal normal 400 18px/21px Pretendard;
	letter-spacing: 0px;
	color: #6e6e6e;
	text-decoration: underline;
	text-align: right;
	cursor: pointer;

	${(p) => p.theme.device.md} {
		font: normal normal 500 14px/17px Pretendard;
		letter-spacing: -0.14px;
		color: #bebebe;
	}
`;
