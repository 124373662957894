import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'query-string';

import { ReactComponent as SearchSVG } from 'assets/svg/search.svg';
import { useEffect } from 'react';

const SearchBar = () => {
	const history = useHistory();
	const parsed = queryString.parse(useLocation().search);
	const searchQuery = parsed['search'] || '';

	const [query, setQuery] = useState('');
	const [queryBuffer, setQueryBuffer] = useState(searchQuery); // 검색창에서 엔터 입력 시, 쿼리 요청 용

	const onSearch = () => {
		if (queryBuffer) {
			setQuery(queryBuffer);
			setQueryBuffer('');
		}
	};

	useEffect(() => {
		if (!query) return;

		history.push(`/listing?search=${query}`);
	}, [query]);

	return (
		<Wrapper>
			<Button onClick={onSearch}>
				<SearchSVG />
			</Button>
			<Input
				type='text'
				placeholder='작품명, 아티스트, 판매자 검색'
				value={queryBuffer}
				onChange={(e) => setQueryBuffer(e.target.value)}
				onKeyPress={(e) => {
					if (e.key === 'Enter') {
						onSearch();
					}
				}}
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
	width: 377px;
	justify-content: space-between;

	${(props) => props.theme.device.md} {
		display: none;
	}
`;
const Input = styled.input`
	margin-right: 10px;
	width: 377px;
	height: 48px;
	padding-left: 59px;
	border-radius: 26px;
	border: none;
	background: #f8f9fa 0% 0% no-repeat padding-box;
	font-weight: medium;
	font-size: 15px;
	letter-spacing: -0.15px;
	color: #777777;

	${(props) => props.theme.device.md} {
		position: relative;
		margin-right: 0;
	}
`;
const Button = styled.div`
	cursor: pointer;
	position: absolute;
	left: 28px;
	top: 14px;
	${(props) => props.theme.device.md} {
		position: absolute;
		right: 25px;
	}
`;
export default SearchBar;
