import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import * as storage from 'lib/storage';

const MENUS = [
	{
		name: '구매 내역',
		route: '/mypage',
	},
	{
		name: '프로필 수정',
		route: '/mypage/editprofile',
	},
	{
		name: '판매 내역',
		route: '/mypage/saleshistory',
	},
	{
		name: '등록 현황',
		route: '/mypage/myworks',
	},
];

const Nav = ({ children }) => {
	const history = useHistory();
	const nickName = storage.getNickname();

	const isAuth = storage.isAuth();

	const handleLogout = () => {
		localStorage.removeItem('isAuth');
		localStorage.removeItem('token');
		localStorage.removeItem('nickname');
		history.push('/');
	};

	return (
		<Wrapper>
			<MenuSection
				$hidden={/detail|changepassword/.test(
					history.location.pathname,
				)}
			>
				<MenuTitle>
					<div>안녕하세요,</div>
					<div>{nickName || '회원'}님</div>
				</MenuTitle>

				<TabBox>
					<MenuBox>
						{MENUS.map((menu, i) => {
							return (
								<MenuLink
									$active={
										menu.route === history.location.pathname
									}
									key={i}
									to={menu.route}
								>
									{menu.name}
								</MenuLink>
							);
						})}
					</MenuBox>
					{isAuth && <Logout onClick={handleLogout}>로그아웃</Logout>}
				</TabBox>
			</MenuSection>
			{children}
			{/* <ContentSection>{children}</ContentSection> */}
		</Wrapper>
	);
};
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 1200px;
	margin-top: 80px;

	${(props) => props.theme.device.md} {
		width: 100%;
		margin-top: 0;
		padding: 0 24px;
	}
`;
const MenuSection = styled.section`
	${(props) => props.theme.device.md} {
		display: ${(p) => (p.$hidden ? 'none' : 'flex')};
	}
`;
const MenuTitle = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	& > div:first-child {
		line-height: 26px;
		font-size: 22px;
		letter-spacing: -0.11px;
		color: #666666;
	}
	& > div:last-child {
		line-height: 45px;
		font-size: 37px;
		font-weight: bold;
		letter-spacing: -0.37px;
		color: #111111;
	}

	${(props) => props.theme.device.md} {
		display: none;
	}
`;
const TabBox = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 47px;
	border-bottom: 1px solid #bebebe;
	${(props) => props.theme.device.md} {
		padding-top: 36px;
	}
`;
const MenuBox = styled.div``;
const MenuLink = styled(Link)`
	display: inline-block;
	line-height: 24px;
	font-weight: medium;
	font-size: 20px;
	letter-spacing: -0.2px;
	color: ${(p) => (p.$active ? '#000000' : '#bebebe')};
	width: 174px;
	text-align: center;
	padding-bottom: 18px;
	border-bottom: ${(p) => (p.$active ? '2px solid #000000' : 'none')};

	&:hover {
		color: '#bebebe';
	}
	&:visited {
		color: ${(p) => (p.$active ? '#000000' : '#bebebe')};
	}

	${(props) => props.theme.device.md} {
		line-height: 18px;
		font-weight: 600;
		font-size: 15px;
		letter-spacing: -0.15px;
		color: #000000;
		padding-right: 12px;
		width: fit-content;
	}
`;
const Logout = styled.button`
	width: 69px;
	height: 24px;
	border: none;
	background: none;
	font-weight: medium;
	font-size: 20px;
	letter-spacing: -0.2px;
	color: #bebebe;

	${(props) => props.theme.device.md} {
		line-height: 15px;
		font-size: 12px;
		letter-spacing: -0.12px;
		color: #bebebe;
	}
`;
export default Nav;
