import React, { useEffect } from 'react';
import styled from 'styled-components';

const Terms = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Container>
			<Title>Piecial 이용 약관</Title>
			<p>
				<Bold>제 1 조 (목적)</Bold>본 약관은 서비스 이용자가
				임팩트스테이션 주식회사(이하 "회사")가 제공하는 서비스 Piecial
				(이하 "서비스")에 회원으로 가입하고 이를 이용함에 있어 회사와
				회원(이하 "회원")의 권리, 의무 및 책임사항을 규정함을 목적으로
				합니다.
				<Bold>제 2 조 (용어의 정의) </Bold>
				1. "블록체인"이라 함은 전자적인 블록에 데이터를 담아 각 블록을
				체인과 같이 순차적으로 연결하는 기술로, 누구나 열람할 수 있는
				장부에 거래 내역을 기록하고 블록체인 네트워크에 참여한 각
				컴퓨터에 이를 복제하여 저장하는 분산형 데이터 저장 방식을
				말합니다.
				<br />
				3. "토큰(token)"이라 함은 Klaytn 메인체인 위에서 스마트
				컨트랙트를 통해 발행된 코인 또는 토큰 형태의 디지털 자산을
				말합니다.
				<br />
				4. "스마트 컨트랙트"라 함은 Klaytn 위에서 프로그램으로 동작
				가능한 언어로 작성된 코드 뭉치를 말합니다.
				<br />
				5. "서비스"라 함은 단말기(PC, 휴대형 단말기 등의 각종 유무선
				장치를 포함)에 상관없이, 회사가 제공하는 제반 서비스를
				의미합니다.
				<br />
				6. "회원"이라 함은 본 약관에 따라 회사와 이용계약을 체결하고,
				회사가 제공하는 서비스를 이용하는 개인을 말합니다.
				<br />
				7. "계정"이라 함은 서비스에서 본인을 식별하기 위해 생성된
				계정으로 본 약관에 동의하고 회사와 이용계약을 체결한 회원에게
				부여되는 서비스 계정을 말합니다.
				<br />
				8. "지갑"이라 함은 회원의 계정과 연계된 디지털 자산을 보관하기
				위해 사용하는 디지털 지갑으로, 회원이 비슷한 기능을 가진 외부
				지갑과 Piecial 사이에 디지털 자산을 주고받을 수 있는 서비스를
				의미합니다.
				<br />
				9. "지갑 주소"라 함은 서비스에서 회원 간 디지털 자산의 변화를
				기록하기 위해 존재하는 고유한 식별 주소를 말합니다.
				<br />
				10. "비밀번호"라 함은 회원이 계정 및 계정 주소와 일치되는
				회원임을 확인하고 비밀보호를 위해 회원 자신이 정한 숫자의 조합을
				의미합니다.
				<br />
				11. "디지털 자산"이라 함은 서비스를 통해 전자적인 방법으로
				가치의 저장 수단, 교환의 매개가 되는 것으로 코인, 토큰, 카드
				등을 포함한 블록체인 상의 모든 데이터를 말합니다.
				<br />
				12. "NFT"라 함은 고유의 키 값을 가지는 'non-fungible token'을
				말합니다. 회원은 회사 또는 제3자가 발행한 NFT를 서비스 또는 개별
				서비스 내에서 사용할 수 있습니다.
				<br />
				13. "Piecial"라 함은 판매자가 판매 위탁한 디지털 아트를 회원에게
				소개하고 옥션 및 에디션 등의 판매 서비스를 통해 회원이 구매할 수
				있도록 지원하며, 회원이 구매한 디지털 아트 작품을 확인 및 관리할
				수 있는 기능을 제공하는 서비스를 말합니다.
				<br />
				14. "증명서"라 함은 양수도계약을 통한 디지털 아트 소유권 취득을
				증명하는 소유권 증명서를 말합니다.
				<br />
				15. "디지털 아트"라 함은 전자적 기술을 창작 및 표현의 핵심
				요소로 활용하는 디지털 예술 콘텐츠를 말합니다.
				<br />
				16. "옥션"이라 함은 디지털 아트의 소유권을 경매의 방식으로
				판매하는 서비스를 말합니다.
				<br />
				17. "에디션"이라 함은 한정된 수량의 복제본이 존재하는 디지털
				아트의 소유권을 고정된 가격에 판매하는 서비스를 말합니다.
				<br />
				18. "판매자"라 함은 디지털 아트의 소유권을 판매 서비스를 통해
				판매할 목적으로 회사에게 판매자를 대행하여 작품 소유권
				양수도계약을 체결할 권리 및 디지털 아트 판매 홍보를 위해 작품
				이미지 등을 사용할 권리 등을 위임한 개인, 기관, 법인 등 모두를
				말합니다.
				<br />
				19. "구매자"라 함은 옥션 또는 에디션 등 디지털 아트 판매
				서비스를 통해 디지털 아트 소유권을 구매한 개인 또는 기관, 법인
				등 모두를 말합니다.
				<br />
				20. "소유권"이라 함은 디지털 아트 보유권, 디지털 아트 사용권,
				디지털 아트 처분권 등을 포함하는 권리를 말합니다.
				<br />
				21. "보유권"이라 함은 서비스 내에서 구매한 디지털 아트를 기간
				제한없이 보존할 수 있는 권리를 말합니다.
				<br />
				22. "사용권"이라 함은 서비스 내에서 구매한 디지털 아트를 원형
				그대로 표시, 감상하거나 비상업적인 목적으로 사회관계망 서비스
				또는 공개 게시판 등에 게시할 수 있는 권리를 말합니다.
				<br />
				23. "처분권"이라 함은 서비스 내에서 디지털 아트의 소유권을
				구매한 회원이 해당 디지털 아트의 소유권을 다른 회원에게 판매할
				수 있는 권리를 말합니다.
				<br />
				<br />
				본 약관에서 사용하는 용어의 정의는 전항에서 정하는 것을
				제외하고는 관계 법령 및 일반적인 상관례에 의합니다.
				<br />
				<br />
				<Bold>제 3 조 (약관의 게시와 개정)</Bold>
				1. 회사는 이 약관의 내용을 회원 및 이용자가 쉽게 알 수 있도록
				서비스 초기 화면에 게시합니다.
				<br />
				2. 회사는 "전자상거래 등에서의 소비자보호에 관한 법률", "약관의
				규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한
				법률(이하 "정보통신망법")", "개인정보 보호법", "소비자 보호법"
				등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
				<br />
				3. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
				현행 약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 15일
				전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한
				약관의 개정의 경우에는 공지 외에 문자메세지, 일정기간 서비스내
				전자우편, 로그인 팝업창 등의 전자적 수단을 통해 따로 명확히
				통지하도록 합니다.
				<br />
				4. 회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게
				15일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로
				본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로
				거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한
				것으로 봅니다.
				<br />
				5. 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정
				약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할
				수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는
				경우에는 회사가 이용계약을 해지할 수 있습니다.
				<br />
				<br />
				<Bold>제 4 조 (약관 외 준칙)</Bold>
				본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는
				대한민국의 관련 법령 또는 회사가 정한 서비스의 개별 이용약관과
				일반 상관례에 의합니다.
				<br />
				<br />
				<Bold>제 5 조 (이용계약 체결)</Bold>
				1. 이용계약은 회원이 되고자 하는 자(이하 "가입신청자")가 약관의
				내용에 대하여 동의를 한 다음 회원가입신청을 하고 회사가 이러한
				신청에 대하여 승낙함으로써 체결됩니다.
				<br />
				2. 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을
				원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에
				대하여는 승낙을 하지 않거나 승낙 후에라도 이용계약을 해지할 수
				있습니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 가입신청자가 이 약관에
				의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사의 회원
				재가입 승낙을 얻은 경우에는 예외로 함.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. 실명이 아니거나 타인의
				명의를 이용한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. 허위의 정보를 기재하거나,
				회사가 제시하는 내용을 기재하지 않은 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d. 19세 미만 미성년자인 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e. 회원 및 이용자의
				귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을
				위반하며 신청하는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f. 회원의 거주지에서 효력이
				있는 대한민국 외의 법률에 따라 본 서비스 이용행위가 해당 법률의
				위반을 구성하거나 구성할 위험이 있는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g. 가입신청자가 기존의
				서비스 탈퇴 신청을 한 후 삼십(30)일이 경과하지 않은 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;h. 가입신청자가 기존
				서비스에 가입된 상태에서 재가입 또는 신규가입을 시도하는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i.외국인등록증이 없는
				비거주자 외국인인 경우
				<br />
				3. 제1항에 따른 신청에 있어 회사는 전문기관을 통한 실명확인 및
				본인인증을 요청할 수 있습니다.
				<br />
				4. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상
				문제가 있는 경우에는 승낙을 유보할 수 있습니다.
				<br />
				5. 제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나
				유보한 경우, 회사는 원칙적으로 이를 가입신청자에게 알리도록
				합니다.
				<br />
				6. 이용계약의 성립 시기는 가입신청자가 약관에 동의한 후 회원가입
				신청을 가입이 완료된 시점으로 합니다. 7. 회사는 회원에 대해
				회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스
				메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
				<br />
				<br />
				<Bold>제 6 조 (회원정보의 변경)</Bold>
				1. 회원은 개인정보 관리 화면을 통하여 언제든지 본인의 개인정보를
				열람할 수 있습니다. 개인정보의 수정을 할 때는 본인 명의의 휴대폰
				등을 통해 본인인증을 수행하여 수정을 할 수 있습니다.
				<br />
				2. 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우
				온라인으로 수정을 하거나 전자우편 등의 기타 방법으로 회사에
				대하여 그 변경사항을 알려야 합니다.
				<br />
				3. 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여
				회사는 책임지지 않습니다.
				<br />
				<br />
				<Bold>제 7 조 (개인정보보호 의무)</Bold>
				회사는 "개인정보 보호법", "정보통신망법정보통신망 이용촉진 및
				정보보호 등에 관한 법률" 등 관계 법령이 정하는 바에 따라 회원의
				개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에
				대해서는 관련 법 및 회사의 개인정보처리방침이 적용됩니다. 다만,
				회사의 공식 사이트 이외의 링크된 사이트에서는 회사의
				개인정보처리방침이 적용되지 않습니다.
				<br />
				<br />
				<Bold>
					제 8 조 ("회원"의 "비밀번호" 등 정보 관리에 대한 의무)
				</Bold>
				1. 회원의 비밀번호에 관한 관리 책임은 회원에게 있으며, 이를
				제3자가 이용하도록 하여서는 안 됩니다.
				<br />
				2. 회원은 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한
				경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
				<br />
				3. 제2항의 경우에 해당하는 회원이 회사에 그 사실을 통지하지
				않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한
				불이익에 대하여 회사는 책임지지 않습니다.
				<br />
				4. 회원의 비밀번호 등 접근 정보가 분실, 도용 혹은 공개되어
				회원에게 발생한 손해에 대하여 회사는 책임지지 않습니다. 다만,
				회사의 고의 또는 과실에 의한 경우에는 그러하지 않습니다.
				<br />
				<br />
				<Bold>제 9 조 ("회원"에 대한 통지)</Bold>
				1. 회사는 서비스를 운영함에 있어 서비스와 관련한 정보 등을
				회원에게 통지하는 경우 회사가 보유한 회원의 전자우편 주소로의
				전자우편 발송 또는 카카오톡 메시지, 핸드폰 SMS 발송, 서비스 내
				팝업 등 합리적으로 가능한 방법으로 회원에게 개별 통지합니다.
				<br />
				2. 회사는 회원 전체에 대한 통지의 경우 7일 이상 서비스 내
				공지사항 등에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
				<br />
				<br />
				<Bold>제 10 조 ("회사"의 의무)</Bold>
				1. 회사는 관련 법과 이 약관이 금지하거나 일반 상식에 위배되는
				행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기
				위하여 최선을 다하여 노력합니다.
				<br />
				2. 회원의 Klaytn Wallet Key 는 회사가 안전하게 보관・관리하며
				위조, 변조, 훼손 등이 되지 않도록 전자적인 보안 수단과 운영
				수단을 통해 보호됩니다.
				<br />
				3. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보
				보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을
				공지하고 준수합니다.
				<br />
				4. 회사는 서비스 이용과 관련하여 발생하는 회원 및 이용자의 불만
				또는 피해구제 요청을 적절하게 처리할 수 있도록 필요한 인력 및
				시스템을 구비합니다.
				<br />
				5. 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나
				불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이
				제기한 의견이나 불만사항에 대해서는 전자우편 등을 통하여
				회원에게 처리 과정 및 결과를 전달합니다.
				<br />
				<br />
				<Bold>제 11 조 ("회원"의 의무)</Bold>
				1. 회원은 다음 행위를 하여서는 안 됩니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 신청 또는 변경 시 허위
				내용의 등록
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. 타인의 정보도용
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. 회사가 게시한 정보의 변경
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d. 회사가 정한 정보 이외의
				정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e. 회사와 기타 제3자의
				저작권 등 지식재산권에 대한 침해
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f. 회사 및 기타 제3자의
				명예를 손상시키거나 업무를 방해하는 행위
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g. 외설 또는 폭력적인
				메시지, 화상, 음성, 기타 사회질서에 위반하는 정보를 서비스에
				공개 또는 게시하는 행위
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;h. 회사의 동의 없이 영리를
				목적으로 서비스를 사용하는 행위
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. 비정상적인 방법으로 토큰
				또는 카드를 주고받는 행위
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;j. 정상적인 서비스 이용이
				아닌 투기적 목적으로 거래 등 주고받는 행위
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;k. 불법행위에 대한 동기 부여
				및 실행에 도움이 되는 용도로 토큰 또는 카드를 사용하는 행위
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;l. 다단계, 유사수신 등
				불법적인 행위
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;m. 기타 불법적이거나 부당한
				행위 2. 회원은 관계법, 이 약관의 규정, 이용안내 및 서비스와
				관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야
				하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
				<br />
				<br />
				<Bold>제 12 조 ("서비스"의 제공 등)</Bold>
				1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 새로운 계정 주소의 생성
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. 디지털 아트 자산의 구매
				및 확인 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. 디지털 아트
				자산의 전송 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d. 기타 회사가
				추가 개발하거나 다른 회사와의 제휴계약 등을 통해 회원에게
				제공하는 일체의 서비스
				<br />
				2. 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
				<br />
				3. 회사는 컴퓨터 등 정보통신 설비의 보수 점검, 교체 및 고장,
				통신 두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을
				일시적으로 중단할 수 있습니다. 이 경우 회사는 제9조["회원"에
				대한 통지]에 정한 방법으로 회원에게 통지합니다. 다만, 회사가
				사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수
				있습니다.
				<br />
				4. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수
				있으며, 정기점검 시간은 서비스 제공 화면에 공지한 바에 따릅니다.
				또한, 정기점검 시 서비스 이용에 일부 또는 전체에 대하여 제한이
				발생할 수 있으며, 회사의 고의 또는 과실이 아닌 한 그로 인해
				발생한 손해에 대해서는 책임을 지지 않습니다.
				<br />
				5. 서비스를 통해 보유한 토큰을 포함한 디지털 자산을 외부 계정
				주소로 보낼 수 있습니다. 회원이 제공하는 외부 계정 주소의
				정확성에 대한 책임은 회원에게 있으며, 회사는 회원이 잘못 기재한
				외부 계정 주소로 토큰 또는 디지털 자산을 보내는 것에 대해서
				책임을 지지 않습니다.
				<br />
				6. 서비스와 연계된 제3자의 서비스 등의 장애 또는 점검 등의
				사유로 서비스 내 일부 기능에 제한이 발생하는 문제에 대해서는
				회사의 고의, 과실이 없는 한 어떠한 책임을 지지 않습니다. 이
				경우, 회원에게 해당 사실을 공지 또는 통지합니다.
				<br />
				7. 서비스의 제공 범위는 Klaytn 블록체인 기반 토큰에 한하며, 그
				외 블록체인 기반 토큰 등에 대해서는 지원되지 않으므로 월렛 간
				송금 서비스 이용에 각별한 유의를 바랍니다.
				<br />
				8. 회원은 App2App API 연동을 통한 토큰 전송, NFT 전송, 컨트랙트
				실행 시 수수료가 발생할 수 있습니다.
				<br />
				<br />
				<Bold>제 13 조 ("서비스"의 변경 및 중단 등)</Bold>
				1. 회사는 다음과 같은 경우 또는 이에 해당하는 사실이 존재한다고
				의심할 만한 합리적 이유가 있는 경우에 운영상, 기술상의 필요에
				따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 서비스를 통해 불법적인
				행위가 발생한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. 컴퓨터 등 정보통신설비의
				보수점검, 장비의 교체 및 고장, 통신의 두절 등의 사유가 발생한
				경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. 서비스를 위한 설비 등의
				유지보수 등으로 인한 부득이한 사정이 있는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d. 정전, 제반 설비의 장애
				또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는
				경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e. 전국 또는 지역 등
				범위적인 네트워크 장애가 발생하는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f. 경영상 이유 등으로 인하여
				서비스를 제공할 수 없는 부득이한 사정이 발생한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g. 기타 천재지변,
				국가비상사태, 각 국가의 법령/정책 상의 규제 또는 제한 등
				불가항력적 사유가 있는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. 서비스의 내용, 이용방법,
				이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의
				내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에
				게시하거나 제9조["회원"에 대한 통지]에 따라 통지합니다. 단,
				회사가 통제할 수 없는 사유 또는 긴급사유 등으로 인하여 회원에
				대한 공지 또는 통지가 불가능한 경우에는 그러하지 아니합니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. 회사는 무료로 제공되는
				서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정,
				중단, 변경할 수 있으며, 이에 대하여 관련 법에 특별한 규정이 없는
				한 회원에게 별도의 보상을 하지 않습니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. 회원은 서비스 변경에
				동의하지 않을 경우 회사에 거부 의사를 표시하고 이용계약을 해지할
				수 있습니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. 회사는 본 서비스의 변경,
				중단으로 발생하는 문제에 대해서 회사의 고의 또는 과실이 없는 한
				어떠한 책임도 지지 않습니다.
				<br />
				<br />
				<Bold>제 14 조 (정보의 제공 및 광고의 게재)</Bold>
				1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한
				정보를 공지사항이나 전자우편, 카카오톡 메시지 등의 방법으로
				회원에게 제공할 수 있습니다. 다만, 회원은 관련 법에 따른 거래
				관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지
				전자우편에 대해서 수신 거절을 할 수 있습니다.
				<br />
				2. 제1항의 정보를 전화 및 모사전송 기기에 의하여 전송하려고 하는
				경우에는 회원의 사전 동의를 받아서 전송합니다. 다만, 회원의 거래
				관련 정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.
				<br />
				3. 회사는 서비스의 운영과 관련하여 서비스 화면, 홈페이지에
				광고를 게재할 수 있으며 수신 동의한 회원에 대해 전자우편 또는
				카카오톡 메시지 등의 방법으로 이를 제공할 수 있습니다.
				<br />
				회사는 서비스 상에 게재되어 있거나 서비스를 통한 광고주와의
				판촉활동에 회원이 참여하거나 교신 또는 거래로서 발생하는 모든
				손실 또는 손해에 대해 책임지지 않습니다.
				<br />
				4. BApp 서비스를 통해 이루어지는 모든 광고, 서비스, 다른
				웹사이트로의 링크, 기타 자료는 제3자가 회원에게 제공하는 것이며,
				회사는 제3자가 제공하는 어떠한 서비스, 행위 등에 대해서는 어떠한
				권리나 이해관계를 가지지 않습니다.
				<br />
				<br />
				<Bold>제 15 조 (계약해제, 해지 등)</Bold>
				1. 회원은 언제든지 탈퇴 신청을 통해 회사에 해지의사를
				통지함으로써 이용계약을 해지할 수 있으며 이용계약은 회원의
				해지의사가 회사에 도달한 때에 종료됩니다. 다만, 회원은 회사
				이용계약 해지 기준 및 절차에 따라 토큰 등 디지털 자산을 외부로
				이전한 후 해지 신청을 하여야 합니다.
				<br />
				2. 본 조에 따라 해지를 한 회원이 재가입 신청을 할 경우 회사의
				정책에 따라, 가입이 삼십(30)일 동안 제한될 수 있습니다.
				<br />
				3. 회사는 다음과 같은 사유가 있는 경우 이용계약을 해지할 수
				있습니다. 이 경우 회사는 회원에게 전자우편, 전화, 팩스 기타의
				방법을 통하여 해지사유를 밝혀 해지의사를 통지합니다. 이 경우
				회사는 해당 회원에게 사전에 해지사유에 대한 의견진술의 기회를
				부여하여야 하며, 이용계약은 회사의 해지의사가 회원에게 도달한
				때에 종료됩니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 회원에게 제5조 제2항에서
				정하고 있는 이용 신청의 승낙 거부 사유가 있음이 확인된 경우
				<br />
				회원이 본 약관에 위배되는 행위를 한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. 불법프로그램의 제공 및
				서비스 운영 방해, 불법통신 및 해킹, 악성프로그램의 배포,
				접속권한 초과행위 등과 같이 관련 법령에 위배되는 행위를 한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. 회사가 제공하는 서비스의
				원활한 진행을 방해하는 행위를 하거나 그러한 행위를 시도한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d. 기타 회사가 합리적인
				판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정하는 경우
				<br />
				4. 본 조에 따른 이용계약 해지 시 회원이 서비스 이용을 통해
				획득한 모든 혜택이 소멸되며, 회사는 이에 대해 별도로 보상하지
				않습니다.
				<br />
				5. 이용계약 해지가 완료되는 경우 관련 법령 및 개인정보처리방침에
				따라 회사가 법령에 따라 보유하여야 하는 정보를 제외한 회원의
				모든 정보가 삼십(30)일 후 삭제됩니다.
				<br />
				6. 전항에도 불구하고 제3항에 따라 회사가 이용계약을 해지하는
				경우 회사는 회원의 이의신청 접수 및 처리 등을 위하여 일정 기간
				동안 회원의 정보를 보관할 수 있습니다.
				<br />
				7. 회원은 서비스와 연동된 카카오 계정의 영구이용정지 등으로
				인하여 서비스 이용이 불가능하게 되었을 경우 회사에서 정하는
				별도의 방법에 따라 고객센터를 통한 계약해지(회원 탈퇴)를
				진행하실 수 있습니다. 다만, 계약해지(회원 탈퇴)로 인한 토큰,
				카드 등 디지털 자산에 대한 보장, 환불 등은 진행이 불가능하므로
				이용에 참고하시기 바랍니다.
				<br />
				8. 회사는 제7항에 따른 계약해지(회원 탈퇴)를 진행하는 경우
				제2항부터 제6항까지 동일하게 적용되며, 이로 인해 발생하는
				불이익에 대해서는 회사는 어떠한 책임을 지지 않습니다.
				<br />
				<br />
				<Bold>제 17 조 (디지털 아트의 위탁 등)</Bold>
				1. 위탁자는 회사에 디지털 아트를 판매 위탁하여 Piecial 내에 옥션
				또는 에디션 등의 방식으로 판매되도록 할 수 있습니다. 다만, 각
				호의 경우에 회사는 위탁자의 판매 위탁을 제한 또는 중지할 수
				있습니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 디지털 아트 제작에 별도
				원화가 존재하는 기존 미술작품의 이미지 등이 사용되었을 시, 해당
				미술작품의 원저작권자가 위탁자가 동일 인물임을 확인하기 어렵거나
				또는 해당 미술작품의 원저작권자와 위탁자 간 저작권 사용 허가
				관계를 확인하기 어려운 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. 위탁자가 판매 위탁하는
				디지털 아트의 성격 또는 특성이 회사의 서비스/콘텐츠 관련 정책과
				부합하지 않거나 사회통념 상 부적절하다고 간주될 우려가 있는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. 위탁자와 회사 간 디지털
				아트의 시작가 또는 판매가에 대한 협의가 도출되지 못할 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d. 위탁자가 판매 위탁하는
				디지털 아트에 위탁자가 저작권을 보유하지 않은 저작물이
				부적절하게 사용되었을 우려가 있는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e. 위탁자 등이 판매 위탁된
				디지털 아트의 판매 가격 등에 영향을 미치기 위해 옥션 및 에디션
				판매에 허위 응찰, 거래 허위 체결, 응찰 시세 조작 등 부정한
				방식으로 참여하였음이 확인되거나 참여하였을 우려가 있는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f. 기타 회사에서 적합하지
				않다고 판단되는 경우
				<br />
				2. 위탁자는 다음 각 호의 의무와 책임을 집니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 위탁자는 자신이 판매
				위탁하는 디지털 아트를 적법하게 양도할 수 있는 권리를 지녀야
				하며, 자신에게 정당한 소유권 또는 원저작권이 있음을 보증할
				의무와 책임이 있습니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. 위탁자는 디지털 아트의
				낙찰 또는 판매와 동시에 디지털 아트 소유자에게 배타적으로
				양도되는 저작재산권 상의 권리 (작품 원본을 복제할 권리 등)
				행사를 중지할 책임이 있습니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. 위탁자는 자신이 판매
				위탁하는 디지털 아트의 소유권 양도가 본 약관 및 기타 모든 유관
				법률에 저촉되지 아니함을 보장하여야 합니다.
				<br />
				3. 위탁자는 디지털 아트의 판매 위탁 등을 임의로 철회할 수
				없으며, 부득이하게 철회를 하는 경우에는 판매일이 도래하기 앞서
				최소 3영업일 전에 서면으로 회사에 위탁철회 의사를 통보하여야
				합니다. 또한, 위탁자의 위탁철회로 회사에 손해가 발생할 경우
				회사는 손해배상을 청구할 수 있습니다.
				<br />
				4. 회사의 고의 또는 과실로 불이익이 발생한 경우가 아닌 한,
				회사는 유찰되거나 또는 판매가 이루어지지 않은 디지털 아트에
				대하여 어떠한 책임도 지지 않습니다.
				<br />
				<br />
				<Bold>제 18 조 (경매 및 판매의 방식)</Bold>
				1. 회사는 위탁받은 디지털 아트를 옥션 및 에디션 방식으로
				판매하기 앞서 위탁자와 협의된 기간에 따라 회원 대상으로 사전
				안내를 진행합니다.
				<br />
				2. 회사는 위탁자와 협의된 일정을 준수하여 위탁받은 디지털 아트의
				판매를 진행합니다. 다만 회사는 서비스의 원활한 제공을 위해 사전
				통지 없이 예정된 옥션 및 에디션 일정을 변경하거나 중단할 수
				있습니다.
				<br />
				3. 회사는 옥션 방식으로 디지털 아트를 판매 시 옥션 마감 시간을
				정하여 운영하며, 마감 시간은 회원의 응찰 현황에 따라 자동적으로
				연장될 수 있습니다. 단, 최대 연장 가능한 옥션 마감 시간 이후로는
				더 이상 연장되지 않으며, 옥션 마감 시점에 최고 응찰가를 제시한
				회원이 낙찰자가 됩니다.
				<br />
				4. 회원은 옥션에 응찰한 뒤에는 취소가 불가하므로 반드시 옥션
				참여 유의사항을 숙지하고 이용 바랍니다. 다만, 회원의 응찰 가격이
				비정상적으로 등록되거나 하는 중대한 기술적 문제가 발생할
				경우에는 예외로 합니다.
				<br />
				5. 회사가 회원에게 Piecial 서비스를 제공함에 있어 회원이
				고의적으로 또는 과실로 구매 후 환불 신청을 반복하는 등의 행위를
				확인하거나 또는 회원의 서비스 부정 사용이 우려되는 경우, 회사는
				해당 회원에게 Piecial 서비스 제공을 일정기간 또는 영구적으로
				제한할 수 있습니다. 회원은 이용제한 조치에 대한 이의제기를 할 수
				있으며, 회사는 회원이 이의제기한 사실을 통보받은 후 2주간의 확인
				기간을 거쳐 확인 결과를 회원에게 통지합니다.
				<br />
				6. 회원이 옥션 및 에디션 방식의 디지털 아트 판매에 참여하기 위해
				사용할 수 있는 재화는 원화로 한정됩니다. 다만, 회사 사정에 따라
				재화가 추가 또는 변동될 수 있으며, 이 경우 공지사항, 카카오톡,
				이메일 등의 방법으로 사전에 회원에게 통지합니다.
				<br />
				7. 회원은 옥션 또는 에디션을 통해 구매한 디지털 아트에 대해
				구매일로부터 7일 이내에 계약 철회를 요청할 수 있으며, 철회를
				희망하는 경우 회사에 철회 의사를 통보하여야 합니다. 옥션을 통한
				낙찰을 철회하고자 할 경우 낙찰가의 일정 부분이 낙찰 철회
				수수료로서 제외된 후 반환될 수 있으며 철회 기준 및 방법은 본
				약관 및 별도 운영정책을 따릅니다.
				<br />
				<Bold>제 19 조 (디지털 아트 판매 관련 수수료)</Bold>
				1. 위탁자가 회사에 디지털 아트 판매 위탁 시 위탁수수료는 판매된
				디지털 아트의 낙찰가 또는 판매가에 2%를 적용하여 산출됩니다. 단,
				2021년 이는 9월 14일부터 적용됩니다.
				<br />
				2. 옥션을 통해 디지털 아트를 낙찰받은 회원이 부득이 낙찰 철회를
				요청하고자 하는 경우, 낙찰을 통보받은 날로부터 7일 이내에 회사에
				낙찰철회 의사를 통보해야 하며, 판매된 디지털 아트 낙찰가의
				일정부분에 해당하는 낙찰철회 수수료가 발생할 수 있습니다.
				<br />
				<Bold>제 20 조 (디지털 아트의 보증)</Bold>
				1. 회사는 옥션 및 에디션 방식으로 판매된 디지털 아트에 대한
				어떠한 보증도 하지 않음을 원칙으로 합니다. 또한, 회사에 의해
				표현된 디지털 아트의 보조설명, 가격, 기타 글로 설명된 내용은
				참고사항으로 회사가 보증하는 내용이 아닙니다. 다만, 위탁
				판매되는 디지털 아트와 관련된 내용 전반에 대해 위탁자가 보증
				의무 및 책임을 가집니다.
				<br />
				2. 특정한 디지털 아트 작품에 대하여 회사가 보증책임을 지는 경우,
				디지털 아트의 위탁자도 같은 의무를 가지며, 회사가 보증책임에
				따라 응찰자에게 이행한 손해배상책임 및 기타 회사에 발생한 손해
				전부 또는 일부에 대해 위탁자에게 변상을 요청할 수 있습니다.
				<br />
				<Bold>제 21 조 (청약철회 등)</Bold>
				1. 회원은 옥션 또는 에디션 판매를 통해 구매한 디지털 아트에 대해
				낙찰일 또는 구매일로부터 7일 이내에 계약 철회를 요청할 수
				있습니다.
				<br />
				2. 회원은 구매한 디지털 아트가 다음 각 호에 해당하는 경우에는
				철회 또는 환불할 수 없습니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 회원의 책임있는 사유로
				인해 서비스 내에서 구매한 디지털 아트의 소유권 증명서가 삭제되어
				회사가 회원의 작품 소유 여부를 판단하기 어려운 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. 회원이 디지털 아트 구매
				후 제공받은 특전 또는 특별 혜택을 신청 또는 수령하였거나, 온라인
				또는 오프라인에서 이를 사용한 이력이 조회될 경우
				<br />
				3. 회원은 제1항 및 제2항의 규정에도 불구하고 디지털 아트의
				내용이 표시 내용과 다르거나 다르게 이행된 경우에는 공급받은
				날부터 3개월 이내, 또는 그 사실을 안 날 또는 알 수 있었던 날부터
				30일 이내에 청약철회 등을 할 수 있습니다.
				<br />
				<br />
				<Bold>제 22 조 (이용제한 등)</Bold>
				1. 회사는 다음 각 호에 해당하는 경우, 경고, 정지, 영구이용정지,
				이용계약의 해지 등으로 서비스 이용을 단계적으로 제한할 수
				있습니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 해킹 및 사기 사고가
				발생한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. 명의 도용으로 의심되는
				경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. 국가기관이 관련 법령에
				따라 서비스 제한을 요청한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d. 자금세탁, 불공정거래,
				범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로
				의심되는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e. 회원으로 가입된 이용자가
				미성년자로 확인된 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f. 회원이 약관의 의무를
				위반하거나 서비스의 정상적인 운영을 방해한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g. 본인인증 정보를 알 수
				없거나 본인 식별이 어려운 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;h. 기타 각 호에 준하는
				사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한
				경우
				<br />
				2. 전항에 따른 서비스 이용에 제한이 발생하는 경우,
				제12조["서비스"의 제공] 제1항의 내용을 포함한 아래의 이용제한
				조치가 중첩적으로 적용될 수 있습니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 회원이 보유 중인 카드의
				사용제한
				<br />
				3. 회사는 전항에도 불구하고, "주민등록법"을 위반한 명의도용 및
				결제도용, "저작권법" 및 "컴퓨터프로그램보호법"을 위반한
				불법프로그램의 제공 및 운영방해, "정보통신망법"을 위반한
				불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과
				같이 관련법을 위반한 경우를 포함하여 회사가 긴급하게 이용을
				중지해야 할 필요가 있다고 인정하는 경우에는 즉시 이용제한 또는
				영구이용정지를 할 수 있습니다. 본 항에 따른 이용제한 또는
				영구이용정지 시 서비스 내 보유한 토큰 등 모든 디지털 자산에 대해
				이용에 제한이 발생하거나 일부 또는 전부 이용이 불가능하도록
				조치됩니다. 이에 해당하는 경우 회사는 해당 내용을 회원에게
				제9조["회원"에 대한 통지]에 정한 방법으로 회원에게 통지합니다.
				<br />
				4. 이용제한 또는 영구이용정지가 발생한 경우 회사에서 정하는
				해제조건을 갖추었을 경우에 한해 회원의 이용제한된 상태를 해제할
				수 있습니다.
				<br />
				5. 회사는 회원이 계속해서 1년 이상 로그인하지 않는 경우,
				회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수
				있습니다.
				<br />
				6. 회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은
				일반적인 온라인 서비스의 상식과 관례에 따릅니다.
				<br />
				7. 본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는
				경우에는 회사는 제9조["회원"에 대한 통지]에 따라 통지합니다.
				<br />
				8. 회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에
				따라 이의신청을 할 수 있습니다. 이때 이의가 정당하다고 회사가
				인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
				<br />
				<br />
				<Bold>제 23 조 (책임제한)</Bold>
				1. 다음과 같은 사유로 회원에게 손해가 발생하였을 경우, 회사의
				고의 또는 과실에 의한 경우가 아닌 경우에는 그 손해에 대하여
				책임을 지지 않습니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 천재지변 또는 이에 준하는
				국가 비상사태 등으로 인하여 서비스를 제공할 수 없는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. 정부 기관의 사실상 또는
				법률상 행정처분 및 명령 등에 대한 준수로 인하여 서비스 이용이
				불가능하게 된 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. 블록체인 플랫폼 Klaytn 의
				장애 또는 이용자 측 귀책사유로 인하여 서비스 장애가 발생한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d. 순간적인 서비스 접속 증가
				등으로 인한 서버의 장애가 발생한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e. 회원이 서비스와 관련하여
				게재한 정보, 자료, 사실의 신뢰도, 정확성 및 완전성 등의 내용이
				변조, 허위 등의 사실이 확인된 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f. 무료로 제공되는 서비스
				이용과 관련하여 관련 법에 특별한 규정이 없는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g. 회원 간 또는 회원과 제3자
				상호 간에 서비스를 매개로 하여 거래 등을 한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. BApp 서비스에 대해 회사와
				회원 간의 사전 합의 및 계약이 이루어지지 않거나 해당 합의/계약의
				범위를 벗어나 발생한 문제의 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;j. 본 약관에 따른 이용제한
				및 이용정지 조치로 인한 손해가 발생하는 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;k. Klaytn 블록체인 기반
				토큰이 아닌 기타 다른 블록체인 기반 토큰을 입금한 경우
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;l. Klaytn 블록체인 기반
				토큰을 기타 다른 블록체인 기반 월렛 등으로 전송한 경우
				<br />
				2. 회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나
				상실한 것에 대하여 책임을 지지 않습니다.
				<br />
				3. 회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본
				약관에 명시되지 않은 어떠한 사항에 대하여 직간접적인 약정이나
				보증을 하지 않습니다. 또한, 회사는 KLAY 등 토큰을 포함한 어떠한
				디지털 자산의 가치도 보증하지 않습니다.
				<br />
				<br />
				<Bold>제 24 조 (손해배상)</Bold>
				1. 회사의 책임 있는 사유로 인하여 회원에게 손해가 발생한 경우
				회사의 손해배상 범위는 민법에서 정하고 있는 통상손해를 포함하고,
				특별한 사정으로 인한 손해는 회사가 그 사정을 알았거나 알 수
				있었을 때에 한하여 배상책임이 있습니다.
				<br />
				2. 전항에도 불구하고 다음 각 호의 어느 하나에 해당하는 경우에는
				회원이 그 책임의 전부 또는 일부를 부담할 수 있습니다.
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 회원이 손해 발생의 원인
				또는 손해 발생 사실을 알았음에도 불구하고 회사에 즉시 통지하지
				않은 경우 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. 회원이 고의
				또는 과실로 제3자에게 지갑 관련 계정 및 거래 정보를 유출하거나
				지갑을 사용하게 한 경우 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c.
				그 외에 손해 발생에 있어서 회원의 고의나 과실이 있는 경우 3.
				회사는 회사의 책임 없는 사유로 인하여 회원에게 발생한 손해에
				대하여는 배상책임이 없습니다.
				<br />
				4. 회원이 회사에 제공한 정보가 사실과 다를 경우, 회사는 언제든지
				서비스의 제공을 중단하고 본 약관에 의해 계약 전체 또는 일부를
				해지할 수 있으며, 이로 인하여 회사에 손해가 발생한 경우,
				회원에게 그 손해의 배상을 청구할 수 있습니다.
				<br />
				5. 회원이 회사의 서비스 또는 관련 시스템 운영을 방해하는 등 고의
				또는 과실로 법령, 본 약관, 사회질서에 위배하는 행위 등을 통하여
				회사에 손해를 발생시킨 경우에는 회사에 발생한 손해를 모두
				배상해야 합니다.
				<br />
				6. 회원이 회사에 손해배상을 청구할 경우 회사는 회원과 합의하여
				회원의 지갑으로 디지털 자산을 지급하는 방법으로 회원의 손해를
				배상할 수 있습니다.
				<br />
				<br />
				<Bold>제 25 조 (준거법 및 재판관할)</Bold>
				1. 회사와 회원 간 제기된 소송은 대한민국 법을 준거법으로 합니다.
				<br />
				2. 회사와 회원 간 발생한 분쟁에 관한 소송은 제소 당시의 회원의
				주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의
				전속관할로 합니다. 단, 제소 당시 회원의 주소 또는 거소가
				명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.
				<br />
				3. 해외에 주소나 거소가 있는 회원의 경우 회사와 회원 간 발생한
				분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을
				관할법원으로 합니다.
				<br />
				<br />
			</p>
		</Container>
	);
};
const Container = styled.div`
	width: 1200px;
	margin: 20px auto;
	p {
		word-break: keep-all;
		font-size: 13px;
	}
	${(props) => props.theme.device.md} {
		width: 100%;
		padding: 0 16px;
	}
`;
const Title = styled.div`
	font-size: 25px;
	margin: 10px 0;
	font-weight: bold;
`;
const Bold = styled.div`
	margin: 10px 0;
	font-size: 16px;
	font-weight: bold;
`;
export default Terms;
