import React from 'react';
import Routes from './containers/Routes';
import GlobalStyle from './lib/globalStyle';

import 'swiper/modules/pagination/pagination.min.css';

const App = () => {
	return (
		<div className='App'>
			<Routes />
			<GlobalStyle />
		</div>
	);
};

export default App;
