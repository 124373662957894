import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';

import List from 'components/MyPage/ArtworkList';
import NoHistory from 'components/MyPage/NoHistory';
import Nav from 'components/MyPage/Nav';
import useUser from 'hooks/useUser';
import { numberWithCommas } from 'lib/util';
import API from 'lib/api';
import { getPurchaseHistory, getTransactions } from 'service/mypage';

const paymentMethodToString = (method) => {
	switch (method) {
		case 'trans':
			return '실시간 계좌이체';
		case 'vbank':
			return '가상계좌';
		case 'kakaopay':
			return '카카오페이';
		default:
			return '카드';
	}
};

const PurchaseHistory = () => {
	const profile = useUser();

	const [histories, setHistories] = useState([]);

	const [previewArtwork, setPreviewArtwork] = useState(null);
	const [vbankMethod, setVbankMethod] = useState(null);

	// 내 지갑으로 전송할 주문 정보.
	const [selectedOrderTransaction, setSelectedOrderTransaction] =
		useState(undefined);

	const fetchHistory = async () => {
		const {
			response: {
				data: { results },
			},
		} = await getPurchaseHistory();
		await setHistories(results);
	};

	const handleClickTransfer = async (orderId) => {
		// 주문 transaction 호출
		const {
			response: {
				data: { data },
			},
		} = await getTransactions();

		const transaction = data.find(
			(transaction) => transaction.orderId === orderId,
		);
		setSelectedOrderTransaction(transaction);
	};

	useEffect(() => {
		if (selectedOrderTransaction === undefined) return;

		moveToMyWallet();
	}, [selectedOrderTransaction]);

	const moveToMyWallet = async () => {
		let wallet = profile?.klipInfo;
		if (!wallet) {
			wallet = prompt(
				'주소가 등록되지 않았습니다. 주소를 입력해주세요.',
				'',
			);
		}

		if (!wallet) {
			return;
		}

		if (
			window.confirm(
				'정확하지 않은 kaikas 주소를 입력하신 경우, 전송이 진행되지 않습니다. 입력하신 주소로 전송하시겠습니까?',
			)
		) {
			API.patch(`/order-transactions/${selectedOrderTransaction.id}/`, {
				buyerWalletInfo: wallet,
			})
				.then((res) => {
					if (res.response.status !== 406) {
						// 성공 status 로 바꿔주긴 해야함. => 성공
					} else {
						alert(
							'NFT가 전송되지 않았습니다. 전자지갑 주소를 다시 확인해주세요.',
						);
					}
				})
				.catch((e) => {
					// handle error
					alert(
						'NFT가 전송되지 않았습니다. 전자지갑 주소를 다시 확인해주세요.',
					);
				});
		}
	};

	useEffect(() => {
		fetchHistory();
	}, []);

	return (
		<Nav>
			<List>
				{histories?.length > 0 ? (
					histories.map(
						({
							id,
							status,
							artworkInfo: work,
							discountedPrice,
							paymentMethod,
							orderLines,
							vbankDate,
							vbankHolder,
							vbankName,
							vbankNum,
							// originalFile,
						}) => {
							return (
								<List.Artwork key={id}>
									<List.Status>{status}</List.Status>
									<List.Thumbnail>
										<List.Thumbnail.Image
											src={work.thumbnail}
											alt={work.name}
										/>
										<List.Thumbnail.Actions>
											<List.Thumbnail.Actions.ViewButton
												onClick={() =>
													setPreviewArtwork(work)
												}
											>
												작품 보기
											</List.Thumbnail.Actions.ViewButton>
											{status === '주문완료' && (
												<List.Thumbnail.Actions.MyWalletButton
													onClick={() =>
														handleClickTransfer(id)
													}
												>
													내 지갑으로
												</List.Thumbnail.Actions.MyWalletButton>
											)}
										</List.Thumbnail.Actions>
									</List.Thumbnail>

									<List.Description>
										<List.Description.Header>
											<List.Description.Header.Category>
												{work.category}
											</List.Description.Header.Category>
											<List.Description.Header.Title>
												{work.name}
											</List.Description.Header.Title>
										</List.Description.Header>

										<List.Description.Body>
											<List.Description.Body.AuthorBox>
												<List.Description.OwnerRow>
													<List.Description.Label>
														Owned by
													</List.Description.Label>
													{work.ownerNickname}
												</List.Description.OwnerRow>
												<List.Description.OwnerRow>
													<List.Description.Label>
														Created by
													</List.Description.Label>
													{work.artistName}
												</List.Description.OwnerRow>
											</List.Description.Body.AuthorBox>

											<List.Description.Body.PaymentBox>
												<List.Description.Row>
													<List.Description.Label>
														결제일시
													</List.Description.Label>
													{orderLines.paymentedAt}
												</List.Description.Row>
												<List.Description.Row>
													<List.Description.Label>
														결제수단
													</List.Description.Label>
													<div
														style={{
															width: '100%',
															display: 'flex',
															justifyContent:
																'space-between',
														}}
													>
														{paymentMethodToString(
															paymentMethod,
														)}
														{paymentMethod ===
															'vbank' && (
															<MethodDetailButton
																onClick={() =>
																	setVbankMethod(
																		{
																			vbankDate,
																			vbankHolder,
																			vbankName,
																			vbankNum,
																			discountedPrice,
																		},
																	)
																}
															>
																결제 상세정보
															</MethodDetailButton>
														)}
													</div>
												</List.Description.Row>
												<List.Description.Row>
													<List.Description.Label>
														결제 상품
													</List.Description.Label>
													{`${
														orderLines.selected
													} X ${
														orderLines.amount || 0
													}개`}
												</List.Description.Row>
											</List.Description.Body.PaymentBox>
										</List.Description.Body>

										<List.Description.MobileFooter>
											<List.Description.Row className='sell-type'>
												{orderLines.selected}
											</List.Description.Row>
											<List.Description.Row className='amount-price'>
												<span className='amount'>
													{orderLines.amount || 1}개
												</span>
												<div className='price'>
													{numberWithCommas(
														discountedPrice,
													)}
													원
												</div>
											</List.Description.Row>
										</List.Description.MobileFooter>

										<List.Description.Footer>
											<List.Description.Row>
												<List.Description.Label>
													결제금액
												</List.Description.Label>
												<strong>
													{numberWithCommas(
														discountedPrice,
													)}
												</strong>
												원
											</List.Description.Row>
										</List.Description.Footer>
									</List.Description>
								</List.Artwork>
							);
						},
					)
				) : (
					<NoHistory content='아직 구매한 작품이 없군요.' />
				)}
			</List>

			<PreviewModal
				width={1200}
				visible={!!previewArtwork}
				onCancel={() => setPreviewArtwork(null)}
			>
				<div className='title'>{previewArtwork?.name}</div>
				<div className='owner'>
					<label>Owned by</label>
					{previewArtwork?.ownerNickname}
				</div>
				<div className='body'>
					<img src={previewArtwork?.originalFile} alt='' />
					<button onClick={() => setPreviewArtwork(null)}>
						닫기
					</button>
				</div>
			</PreviewModal>

			<VbankInfoModal
				width={1200}
				visible={!!vbankMethod}
				onCancel={() => setVbankMethod(null)}
			>
				<div className='title'>계좌 정보</div>
				<p>
					- {vbankMethod?.vbankName} {vbankMethod?.vbankNum}
					<br />- 예금주 : {vbankMethod?.vbankHolder}
				</p>

				<div className='title'>결제 금액</div>
				<p>{vbankMethod?.discountedPrice}원</p>

				<div className='title'>입금 기간</div>
				<p>{vbankMethod?.vbankDate} 까지</p>
			</VbankInfoModal>
		</Nav>
	);
};

export default PurchaseHistory;

const PreviewModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;
		width: 1200px;
		height: 1224px;

		${(props) => props.theme.device.md} {
			width: 366px;
			height: 556px;
		}

		.ant-modal-close,
		.ant-modal-header,
		.ant-modal-footer {
			display: none;
		}
		.ant-modal-body {
			padding: 50px 82px;
			${(props) => props.theme.device.md} {
				padding: 31px 28px;
			}

			div.title {
				line-height: 36px;
				font-weight: bold;
				font-size: 30px;
				letter-spacing: -0.3px;
				color: #111111;
				margin-bottom: 24px;

				${(props) => props.theme.device.md} {
					line-height: 23px;
					font-size: 18px;
					letter-spacing: -0.18px;
					color: #1a1a1a;
					margin-bottom: 13px;
				}
			}
			div.owner {
				line-height: 19px;
				font-size: 16px;
				letter-spacing: 0px;
				color: #666666;
				${(props) => props.theme.device.md} {
					line-height: 18px;
					font-weight: 500;
					font-size: 15px;
					letter-spacing: -0.15px;
					color: #1a1a1a;
				}
				label {
					font-weight: 500;
					font-size: 16px;
					letter-spacing: 0px;
					color: #000000;
					margin-right: 36px;

					${(props) => props.theme.device.md} {
						line-height: 18px;
						font-weight: 600;
						font-size: 15px;
						letter-spacing: -0.15px;
						color: #bebebe;
						margin-right: 10px;
					}
				}
			}
			div.body {
				margin-top: 50px;
				display: flex;
				flex-direction: column;
				align-items: center;

				${(props) => props.theme.device.md} {
					margin-top: 27px;
				}
				img {
					width: 872px;
					height: 872px;
					margin-bottom: 50px;

					${(props) => props.theme.device.md} {
						width: 310px;
						height: 310px;
						margin-bottom: 39px;
					}
				}
				button {
					width: 367px;
					height: 72px;
					background: #242424;
					border-radius: 10px;
					font-weight: 600;
					font-size: 22px;
					letter-spacing: -0.55px;
					color: #ffffff;

					${(props) => props.theme.device.md} {
						width: 310px;
						height: 43px;
						background: #1a1a1a;
						font-weight: 400;
						font-size: 16px;
						letter-spacing: -0.16px;
					}
				}
			}
		}
	}
`;
const VbankInfoModal = styled(PreviewModal)`
	.ant-modal-content {
		height: 514px;

		.ant-modal-close {
			display: block;

			svg {
				width: 22px;
				height: 22px;
			}
		}
		.ant-modal-body {
			padding: 14px 82px 50px 82px;
			div.title {
				font: normal normal 600 28px/34px Pretendard;
				letter-spacing: -0.28px;
				color: #000000;
				margin-top: 44px;
			}
			p {
				font: normal normal 400 20px/34px Pretendard;
				letter-spacing: -0.2px;
				color: #777777;
			}
		}
	}
`;
const MethodDetailButton = styled.button`
	background: #ffffff;
	border: 1px solid #4568dc;
	border-radius: 3px;
	font: normal normal normal 16px/19px Pretendard;
	letter-spacing: -0.48px;
	color: #4568dc;
	padding: 4px 10px;
`;
