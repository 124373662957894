import React from 'react';
import styled from 'styled-components';

const Title = ({ title }) => {
	return <Wrapper>{title}</Wrapper>;
};
const Wrapper = styled.h1`
	text-align: center;
	font-size: 37px;
	letter-spacing: 0px;
	color: #111111;
	margin: 0;
	line-height: 45px;
`;
export default Title;
