import styled from 'styled-components';

import ArtistSection from './ArtistSection';
import ArtworkSection from './ArtworkSection';

const CollectionDetail = () => {
	return (
		<Wrapper>
			<ArtistSection />
			<ArtworkSection />
		</Wrapper>
	);
};
export default CollectionDetail;

const Wrapper = styled.section`
	width: 1200px;
	padding: 83px 0 252px 0;
	${(p) => p.theme.device.md} {
		width: 100%;
		padding: 52px 0 128px 0;
	}
`;
