import React from 'react';
import styled from 'styled-components';
import { Title, FullButton } from '../../components/common';
import SocialSignUp from '../../components/SocialLogin';

const Register = () => {
	return (
		<>
			<SignupContainer>
				<TitleContainer>
					<Title title='회원가입' />
				</TitleContainer>
				<FullButton
					url='/signup'
					text='이메일 주소로 회원가입'
					backgroundColor='#4878D9'
				/>
				<SocialSignUpContainer>
					<SocialSignUp />
				</SocialSignUpContainer>
			</SignupContainer>
		</>
	);
};
const SignupContainer = styled.div`
	padding: 100px 0;
`;
const TitleContainer = styled.div`
	margin-bottom: 40px;
`;
const SocialSignUpContainer = styled.div`
	margin-top: 15px;
`;
export default Register;
