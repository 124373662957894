export function isAuth() {
	const isAuth = localStorage.getItem('isAuth');
	if (isAuth == null || typeof isAuth === undefined) return false;

	return isAuth;
}

export function login(token) {
	localStorage.setItem('token', JSON.stringify(token));
	localStorage.setItem('isAuth', true);
	return true;
}

export function getToken() {
	return JSON.parse(localStorage.getItem('token'));
}

export function logout() {
	localStorage.removeItem('isAuth');
	localStorage.removeItem('token');
	localStorage.removeItem('nickname');

	return true;
}

export function setStep(id) {
	localStorage.setItem(`step${id}`, JSON.stringify(id));
	return true;
}

export function removeStep(id) {
	localStorage.removeItem(`step${id}`);
	return true;
}

export function getStep(id) {
	return JSON.parse(localStorage.getItem(`step${id}`));
}

export function setArtWork(id, artworkInfo) {
	localStorage.setItem('artworkId', id);
	localStorage.setItem('artworkInfo', JSON.stringify(artworkInfo));
	return true;
}

export function getArtWorkId() {
	return JSON.parse(localStorage.getItem('artworkId'));
}

export function getArtWorkInfo() {
	return JSON.parse(localStorage.getItem('artworkInfo'));
}

export function setAgreement(agreement) {
	localStorage.setItem('agreement', JSON.stringify(agreement));
	return true;
}

export function getOption() {
	return JSON.parse(localStorage.getItem('option'));
}

export function setOption(option) {
	localStorage.setItem('option', JSON.stringify(option));
	return true;
}

export function getAgreement() {
	return JSON.parse(localStorage.getItem('agreement'));
}

export function setPayment(payment) {
	localStorage.setItem('payment', JSON.stringify(payment));
	return true;
}

export function getPayment() {
	return JSON.parse(localStorage.getItem('payment'));
}

export function setNickname(nickname) {
	localStorage.setItem('nickname', JSON.stringify(nickname));
	return true;
}

export function getNickname() {
	return JSON.parse(localStorage.getItem('nickname'));
}

export function setEmail(email) {
	localStorage.setItem('email', JSON.stringify(email));
	return true;
}

export function getEmail() {
	return JSON.parse(localStorage.getItem('email'));
}
