import { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { UserInput } from 'components/common';
import Nav from 'components/MyPage/Nav';
import api from 'lib/api';
import * as storage from 'lib/storage';
import useUser from 'hooks/useUser';

import DeleteAccountModal from './DeleteAccountModal';
import { useEffect } from 'react';

const Profile = () => {
	const history = useHistory();

	const userProfile = useUser();
	const email = storage.getEmail();
	const [password, setPassword] = useState('');
	const [user, setUser] = useState(null);
	const nickname = useRef('');
	const [validNickname, setValidNickname] = useState(false);
	const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
	// const [checkedPassword, setCheckedPAssword] = useState(false);

	const checkToken = async (password) => {
		const {
			response: { data },
		} = await api.post(`/user/token/`, {
			username: email,
			password: password,
		});

		if (data === 400) {
			alert('비밀번호가 일치하지 않습니다.');
			return null;
		} else {
			const { user } = data.data;
			return user;
		}
	};

	const checkPassword = async (password) => {
		const user = await checkToken(password);

		setUser(user);
		if (user) {
			nickname.current = user.nickname;
		}

		// try {
		// 	const {
		// 		response: { data },
		// 	} = await api.post(`/user/token/`, {
		// 		username: email,
		// 		password: password,
		// 	});
		// 	if (data === 400) {
		// 		alert('비밀번호가 일치하지 않습니다.');
		// 		return;
		// 	} else {
		// 		const { user } = data.data;
		// 		// console.log(data);
		// 		setUser(user);
		// 		nickname.current = user.nickname;
		// 	}
		// } catch (e) {
		// 	setUser(false);
		// 	alert('비밀번호가 일치하지 않습니다.');
		// }
	};

	const checkNickname = async () => {
		if (nickname.current === user.nickname) return;

		const {
			response: {
				data: {
					data: { isExist },
				},
			},
		} = await api.get('/user/nickname/?value=' + user.nickname);

		if (isExist) {
			alert('이미 등록된 닉네임입니다.');
			setValidNickname(false);
		} else {
			alert('사용가능한 닉네임입니다.');
			nickname.current = user.nickname;
			setValidNickname(true);
		}
	};

	const sendChangePasswordMail = async () => {
		try {
			await api.post('/password_reset/', {
				email: email,
			});
			alert('비밀번호 변경 이메일이 전송되었습니다.');
		} catch (e) {
			alert('에러가 발생하였습니다.');
		}
	};

	const deleteUser = async (password) => {
		const user = await checkToken(password);

		if (user) {
			try {
				await checkPassword(password);
				await api.delete('/user/');
				storage.logout();
				setShowDeleteAccountModal(false);
				history.push(`/`);
			} catch (e) {
				alert('에러가 발생하였습니다.');
			}
		}
	};

	const updateProfile = async () => {
		if (nickname.current !== user.nickname && !validNickname) {
			alert('닉네임 중복 확인을 통해 닉네임을 등록해주세요.');
			return;
		}
		// if (user.phoneNumber.length < 11) {
		// 	alert('휴대전화 번호를 확인해주세요.');
		// 	return;
		// }
		if (user.klipInfo) {
			alert(
				'정확하지 않은 kaikas 주소를 입력하신 경우, 전송이 진행되지 않습니다',
			);
		}
		const {
			response: { status },
		} = await api.patch(`/user/`, user);
		if (status === 202) {
			alert('수정되었습니다.');
		}
	};

	// console.log(user.isSocial);

	useEffect(() => {
		if (userProfile && userProfile.isSocial) {
			setUser(userProfile);
		}
	}, [userProfile]);

	return (
		<>
			<Nav>
				<ProfileContainer>
					{user ? (
						<Container>
							<BodyWrapper>
								<UserInput
									text='이메일*'
									value={user.username}
									disabled={true}
									placeholder='example@domain.com'
								/>
								<UserInput
									text='이름(실명)*'
									value={user.name}
									placeholder='홍길동'
									handlechange={(e) =>
										setUser({
											...user,
											name: e.target.value,
										})
									}
								/>
								<UserInput
									text='닉네임*'
									value={user.nickname}
									placeholder='닉네임을 입력해주세요'
									button='중복확인'
									buttonOnclick={checkNickname}
									handlechange={(e) =>
										setUser({
											...user,
											nickname: e.target.value,
										})
									}
								/>
								<UserInput
									text='휴대전화 번호*'
									// pattern='[0-9]+'
									value={user.phoneNumber}
									placeholder='- 제외한 숫자만 입력'
									handlechange={(e) =>
										setUser({
											...user,
											phoneNumber: e.target.value
												.replace(/[^0-9.]/g, '')
												.replace(/(\..*)\./g, '$1')
												.slice(0, 11),
										})
									}
								/>
								<UserInput
									text='전자지갑 주소(선택)'
									value={user.klipInfo}
									placeholder='kaikas 전자지갑 주소를 입력해 주세요.'
									// button='Kaikas 열기' -
									handlechange={(e) =>
										setUser({
											...user,
											klipInfo: e.target.value,
										})
									}
									// buttonOnclick={() => {
									// 	if (
									// 		navigator.userAgent.indexOf(
									// 			'Chrome',
									// 		) !== -1
									// 	) {
									// 		console.log('zmfha');
									// 		let exec =
									// 			document.createElement('a');
									// 		exec.setAttribute(
									// 			'href',
									// 			'sample://abc',
									// 		);
									// 		console.log(exec);
									// 		exec.click();
									// 	} else {
									// 		alert(
									// 			'PC Chrome 환경에서 가능합니다.',
									// 		);
									// 	}
									// }}
								/>
							</BodyWrapper>

							<FooterWrapper>
								<CheckboxContainer>
									<input type='checkbox' id='marketing' />
									<label htmlFor='marketing'>
										마케팅 정보 수신 동의(선택)
									</label>
								</CheckboxContainer>
								<EditButton onClick={updateProfile}>
									수정하기
								</EditButton>

								<Row>
									<TextButton
										onClick={() =>
											setShowDeleteAccountModal(true)
										}
									>
										탈퇴하기
									</TextButton>
									{!user.isSocial && (
										<TextButton>
											<Link
												to='/mypage/changepassword'
												style={{ color: '#1A1A1A' }}
											>
												비밀번호 변경
											</Link>
										</TextButton>
									)}
								</Row>
							</FooterWrapper>
						</Container>
					) : (
						<PasswordConfirmWrapper>
							<PasswordTitle>비밀번호 재입력</PasswordTitle>
							<PasswordInput
								type='password'
								placeholder='비밀번호를 입력해 주세요.'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<EditButton onClick={() => checkPassword(password)}>
								확인
							</EditButton>
						</PasswordConfirmWrapper>
					)}
				</ProfileContainer>
			</Nav>

			<DeleteAccountModal
				visible={showDeleteAccountModal}
				onCancel={() => setShowDeleteAccountModal(false)}
				onOk={(password) => deleteUser(password)}
			/>
		</>
	);
};
export default Profile;

const ProfileContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Container = styled.div`
	width: 702px;
	margin-top: 75px;
	margin-bottom: 254px;

	${(props) => props.theme.device.md} {
		width: 100%;
		margin-top: 35px;
		margin-bottom: 128px;
	}
`;
const BodyWrapper = styled.div`
	margin-bottom: 148px;
	${(props) => props.theme.device.md} {
		margin-bottom: 75px;
	}
`;
const FooterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const EditButton = styled.button`
	width: 367px;
	height: 72px;
	background: #242424;
	border-radius: 10px;
	font-weight: 600;
	font-size: 22px;
	letter-spacing: -0.55px;
	color: #ffffff;
	margin-top: 26px;
	margin-bottom: 24px;

	${(props) => props.theme.device.md} {
		height: 47px;
		background: #1a1a1a;
		font-size: 17px;
		letter-spacing: -0.17px;
		margin-top: 24px;
		margin-bottom: 38px;
	}
`;
const CheckboxContainer = styled.div`
	& > label {
		line-height: 19px;
		font-size: 16px;
		letter-spacing: -0.16px;
		color: #333333;
		margin-left: 13px;

		${(props) => props.theme.device.md} {
			font-weight: 500;
			font-size: 16px;
			color: #1a1a1a;
			margin-left: 16px;
		}
	}
`;
const Row = styled.div`
	width: 367px;
	display: flex;
	justify-content: space-between;
`;
const TextButton = styled.div`
	cursor: pointer;
	line-height: 19px;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: -0.16px;
	color: #777777;

	${(props) => props.theme.device.md} {
		height: 18px;
		font-size: 15px;
		letter-spacing: -0.15px;
	}
`;
const PasswordConfirmWrapper = styled.div`
	margin-top: 120px;
	margin-bottom: 185px;
	display: flex;
	flex-direction: column;
	align-items: center;

	${(props) => props.theme.device.md} {
		margin-top: 65px;
	}
`;
const PasswordTitle = styled.div`
	font: normal normal bold 30px/36px Pretendard;
	letter-spacing: -0.3px;
	color: #111111;

	${(props) => props.theme.device.md} {
		font: normal normal bold 24px/29px Pretendard;
		letter-spacing: -0.24px;
		color: #1a1a1a;
	}
`;
const PasswordInput = styled.input`
	width: 367px;
	height: 55px;
	padding: 18px 20px;
	border: 1px solid #bebebe;
	border-radius: 10px;
	margin-top: 33px;
	margin-bottom: 34px;

	${(props) => props.theme.device.md} {
		width: 100%;
		padding: 12px 17px;
		font: normal normal 400 13px/16px Pretendard;
		letter-spacing: -0.13px;
		color: #bebebe;
		height: 41px;
		margin-top: 76px;
		margin-bottom: 8px;
	}
`;
