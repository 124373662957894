import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';

import API from 'lib/api';

const useCategory = () => {
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const { response } = await API.get('/artwork-categories/');
				setCategories(sortBy(response.data.data, ['id']));
			} catch (e) {
				setCategories([]);
			}
		})();
	}, []);

	return categories;
};
export default useCategory;
