import styled from 'styled-components';
import { Section } from 'components/Checkout';

const Artwork = ({ work }) => (
	<Section label='작품 정보'>
		<Wrapper>
			<Thumbnail>
				<img src={work.thumbnail} alt='' />
			</Thumbnail>

			<Info>
				<Category>{work.category}</Category>
				<Title>{work.name}</Title>
				<Author>
					<label>Owned by</label>
					<span>{work.ownerNickname}</span>
				</Author>
				<Author>
					<label>Created by</label>
					<span>{work.artistName}</span>
				</Author>
				<MediaInfo>
					{work.mediaType} | {work.mediaInfo}
				</MediaInfo>
			</Info>
		</Wrapper>
	</Section>
);
export default Artwork;

const Wrapper = styled.div`
	display: flex;
	gap: 56px;
	${(p) => p.theme.device.md} {
		flex-direction: column;
		gap: 16px;
	}
`;
const Thumbnail = styled.div`
	width: 486px;
	height: 486px;
	border-radius: 15px;

	img {
		width: 100%;
		height: 100%;
		border-radius: 15px;
	}

	${(p) => p.theme.device.md} {
		width: 100%;
		height: 366px;
		border-radius: 10px;
	}
`;
const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
const Category = styled.div`
	font: normal normal bold 24px/29px Pretendard;
	letter-spacing: -0.24px;
	color: #000000;
	margin-bottom: 11px;

	${(p) => p.theme.device.md} {
		font: normal normal bold 15px/18px Pretendard;
		letter-spacing: -0.15px;
		color: #1a1a1a;
		margin-bottom: 10px;
	}
`;
const Title = styled.div`
	line-height: 38px;
	font-weight: bold;
	font-size: 30px;
	letter-spacing: -0.3px;
	color: #111111;
	margin-bottom: 39px;

	${(p) => p.theme.device.md} {
		line-height: 25px;
		font-weight: 600;
		font-size: 20px;
		letter-spacing: -0.2px;
		color: #1a1a1a;
		margin-bottom: 10px;
	}
`;
const Author = styled.div`
	line-height: 21px;
	font-size: 18px;
	letter-spacing: 0px;
	color: #666666;

	& > label {
		display: inline-block;
		width: 110px;
		font-weight: 500;
	}

	& + & {
		margin-top: 14px;
	}

	${(p) => p.theme.device.md} {
		display: flex;
		flex-direction: column;
		gap: 8px;
		line-height: 18px;
		font-weight: 500;
		font-size: 15px;
		letter-spacing: -0.15px;
		color: #1a1a1a;

		& > label {
			font-weight: 600;
			color: #bebebe;
		}
	}
`;
const MediaInfo = styled.div`
	line-height: 21px;
	font-size: 18px;
	letter-spacing: 0px;
	color: #000000;
	margin-top: 31px;

	${(p) => p.theme.device.md} {
		line-height: 17px;
		font-weight: 500;
		font-size: 14px;
		letter-spacing: -0.14px;
		color: #777777;
		margin-top: 31px;
	}
`;
