import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { isAuth } from 'lib/storage';

import { ReactComponent as HomeSVG } from 'assets/svg/homeMenu.svg';
import { ReactComponent as ArtSVG } from 'assets/svg/artMenu.svg';
import { ReactComponent as CollectionsSVG } from 'assets/svg/collectionsMenu.svg';
import { ReactComponent as GallerySVG } from 'assets/svg/galleryMenu.svg';
import { ReactComponent as MySVG } from 'assets/svg/myMenu.svg';

const MobileNav = () => {
	const history = useHistory();
	const userExist = isAuth();

	const handleRoute = (path) =>
		userExist ? history.push(path) : history.push('/login');

	return (
		<Wrapper>
			<Menu onClick={() => history.push('/')}>
				<HomeSVG />
				HOME
			</Menu>
			<Menu onClick={() => history.push('/listing')}>
				<ArtSVG />
				ART
			</Menu>
			<Menu onClick={() => history.push('/collections')}>
				<CollectionsSVG />
				COLLECTIONS
			</Menu>
			<Menu onClick={() => history.push('/gallery')}>
				<GallerySVG />
				GALLERY
			</Menu>
			<Menu onClick={() => handleRoute('/mypage')}>
				<MySVG />
				MY PAGE
			</Menu>
		</Wrapper>
	);
};
export default MobileNav;

const Wrapper = styled.article`
	display: none;

	${(props) => props.theme.device.md} {
		display: flex;
		flex-basis: 100%;
		width: 100%;
		height: 90px;
		background: #ffffff;
		justify-content: space-between;
		padding: 0 22px;
		position: fixed;
		right: 0;
		bottom: 0;
		z-index: 999;
		border-top: 1px solid #bebebe;
	}
`;
const Menu = styled.div`
	width: 61px;
	height: 90px;
	color: #000000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font: normal normal 600 9px/11px Pretendard;
	letter-spacing: 0px;

	& > svg {
		margin-bottom: 5px;
	}
`;
