// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import useFetch from '../../lib/hooks/useFetch';
import styled from 'styled-components';
// import { setResistration } from '../../modules/resistration';

const SaleInfo = ({
	register,
	handleSubmit,
	formState: { errors },
	updateMode,
	isOrderExists,
	handleRegister,
}) => {
	// const dispatch = useDispatch();
	// const { allResistration } = useSelector(({ resistration }) => ({
	// 	allResistration: resistration,
	// }));
	// const [loading, setLoading] = useState(false);
	// const [errorMSG, setErrorMSG] = useState('');
	// const [display, setDisplay] = useState({
	// 	printFrame: 'none',
	// 	frame: 'none',
	// 	LCD: 'none',
	// });
	// const handleChange = (e) => {
	// 	const { name, value } = e.target;
	// 	setDisplay({
	// 		...display,
	// 		[name]: value,
	// 	});
	// };
	// const [bankInfo, setBankInfo] = useState({
	// 	bankName: '',
	// 	bankNumber: '',
	// 	bankOwner: '',
	// });
	// const bankInfoChange = (e) => {
	// 	setErrorMSG('');
	// 	const { name, value } = e.target;
	// 	setBankInfo({
	// 		...bankInfo,
	// 		[name]: value,
	// 	});
	// };
	// const history = useHistory();
	// const { executeFetch, data, error, isLoading } = useFetch(
	// 	'/artworks/',
	// 	'post',
	// 	{
	// 		...allResistration,
	// 		bankName: bankInfo.bankName,
	// 		bankNumber: bankInfo.bankNumber,
	// 		bankOwner: bankInfo.bankOwner,
	// 		displayInfo: display,
	// 	},
	// 	{
	// 		immediate: false,
	// 	},
	// );
	// const handleClick = async () => {
	// 	if (!bankInfo.bankName || !bankInfo.bankNumber || !bankInfo.bankOwner) {
	// 		setErrorMSG('일부 항목 입력이 유효하지 않습니다');
	// 		return;
	// 	}
	// 	await executeFetch();
	// 	// await history.push('/mypage/myworks')
	// };

	// useEffect(() => {
	// 	if (data?.data) {
	// 		setLoading(false);
	// 		history.push('/mypage/myworks');
	// 	}
	// }, [data]);

	return (
		<Wrapper>
			<form id='saleinfo-form' onSubmit={handleSubmit(handleRegister)}>
				{/* <Title>디스플레이 선택</Title>
				<Container width='50%'>
					<SelectBox>
						<span>+ 인화 + 액자</span>
						<select name='printFrame' onChange={handleChange}>
							<option selected='selected' value='none'>
								없음
							</option>
							<option value='display'>있음</option>
						</select>
					</SelectBox>
					<SelectBox>
						<span>+ 디지털 액자</span>
						<select name='frame' onChange={handleChange}>
							<option value='none'>없음</option>
							<option value='display'>있음</option>
						</select>
					</SelectBox>
					<SelectBox>
						<span>+ LCD 모니터</span>
						<select name='LCD' onChange={handleChange}>
							<option value='none'>없음</option>
							<option value='display'>있음</option>
						</select>
					</SelectBox>
				</Container> */}

				<InputRow>
					<label>리워드 설정</label>
					<div className='description'>
						* 본인의 작품을 구매한 구매자에게 주는 리워드입니다.
						리워드 설정은 최대 3개까지 가능하며, 가격 책정에 영향을
						미치지 않습니다.
					</div>
					<div>
						<SubInputRow>
							<label>+리워드 A</label>
							<Input
								// name='name'
								// value={name}
								disabled={updateMode && isOrderExists}
								placeholder='예) 오프라인 전시 초대권 2매'
								// onChange={inputChange}
								{...register('rewardA')}
							/>
						</SubInputRow>

						<SubInputRow>
							<label>+리워드 B</label>
							<Input
								// name='name'
								// value={name}
								disabled={updateMode && isOrderExists}
								placeholder='예) 작품 실사 출력 1매'
								// onChange={inputChange}
								{...register('rewardB')}
							/>
						</SubInputRow>

						<SubInputRow>
							<label>+리워드 C</label>
							<Input
								// name='name'
								// value={name}
								disabled={updateMode && isOrderExists}
								placeholder='예) NFT 커뮤니티 입장권 (디스코드, 오픈채팅방 등)'
								// onChange={inputChange}
								{...register('rewardC')}
							/>
						</SubInputRow>
					</div>
				</InputRow>

				<InputRow>
					<label>은행명</label>
					<Input
						// name='name'
						// value={name}
						placeholder='이름을 입력해 주세요.'
						// onChange={inputChange}
						{...register('bankName', { required: true })}
					/>
				</InputRow>

				<InputRow>
					<label>계좌번호</label>
					<Input
						// name='name'
						// value={name}
						placeholder='숫자를 입력해 주세요.'
						// onChange={inputChange}
						{...register('bankNumber', { required: true })}
					/>
				</InputRow>

				<InputRow>
					<label>예금주</label>
					<Input
						// name='name'
						// value={name}
						placeholder='이름을 입력해 주세요.'
						// onChange={inputChange}
						{...register('bankOwner', { required: true })}
					/>
					<div className='description'>
						* 작품이 판매되었을 때 정산 받으실 계좌 정보입니다.
					</div>
				</InputRow>

				{/* <Title>
					은행명<span>*</span>&nbsp;
				</Title>
				<InputContainer width='50%'>
					<Input
						type='text'
						name='bankName'
						onChange={bankInfoChange}
					/>
				</InputContainer>
				<Title>
					계좌번호<span>*</span>&nbsp;
				</Title>
				<InputContainer width='100%'>
					<Input
						type='text'
						name='bankNumber'
						onChange={bankInfoChange}
					/>
				</InputContainer>
				<Title>
					예금주<span>*</span>&nbsp;
				</Title>
				<InputContainer width='50%'>
					<Input
						type='text'
						name='bankOwner'
						onChange={bankInfoChange}
					/>
				</InputContainer> */}
				<Actions>
					{/* <ActionButton
					// loading={loading}
					// onClick={loading ? null : handleClick}
					>
						이전으로
					</ActionButton> */}

					<ActionButton
						type='submit'
						$isActive={true}
						// loading={loading}
						// onClick={loading ? null : handleClick}
						// form="profile-form"
					>
						등록하기
					</ActionButton>
				</Actions>
				{Object.keys(errors).length > 0 && (
					<Error>일부 항목 입력이 유효하지 않습니다.</Error>
				)}
			</form>
		</Wrapper>
	);
};

const NextButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	${(props) => props.theme.device.md} {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}
`;
const NextButton = styled.div`
	width: 306px;
	height: 51px;
	line-height: 51px;
	text-align: center;
	background-color: ${(props) => (props.loading ? '#999999' : '#6b98f2')};
	color: #fff;
	font-size: 18px;
	margin-top: 40px;
	cursor: pointer;
	a {
		display: inline-block;
		width: 306px;
		height: 51px;
	}
`;
export default SaleInfo;

const Wrapper = styled.div``;

const InputRow = styled.div`
	& > label {
		font: normal normal 600 24px/29px Pretendard;
		letter-spacing: -0.48px;
		color: #000000;
		margin-bottom: 15px;
		display: block;

		${(props) => props.theme.device.md} {
			font: normal normal bold 17px/20px Pretendard;
			letter-spacing: -0.17px;
			color: #1a1a1a;
		}

		& > span {
			color: #6b98f2;
		}
	}

	& > .description {
		margin-top: 20px;
		font: normal normal 400 16px/19px Pretendard;
		letter-spacing: -0.16px;
		color: #777777;
		margin-bottom: 45px;

		${(props) => props.theme.device.md} {
			font: normal normal 400 14px/21px Pretendard;
			letter-spacing: -0.14px;
			color: #777777;
			margin-bottom: 30px;
		}
	}

	& + & {
		margin-top: 40px;
	}
`;
const Input = styled.input`
	width: 588px;
	height: 55px;
	border: 1px solid #bebebe;
	border-radius: 10px;
	padding: 18px 20px;
	font: normal normal normal 16px/19px Pretendard;
	letter-spacing: -0.16px;

	&::placeholder {
		color: #bebebe;
	}

	${(props) => props.theme.device.md} {
		width: 100%;
		height: 41px;
		font: normal normal normal 13px/16px Pretendard;
		letter-spacing: -0.13px;
	}
`;

const SubInputRow = styled(InputRow)`
	display: flex;
	align-items: center;
	padding-left: 20px;

	& > label {
		font: normal normal 500 18px/21px Pretendard;
		letter-spacing: -0.18px;
		margin-right: 37px;
		margin-bottom: 0;
	}

	& > input {
		width: 453px;
	}

	${(props) => props.theme.device.md} {
		display: block;
		padding-left: 0px;

		& > label {
			font: normal normal 500 16px/19px Pretendard;
			letter-spacing: -0.16px;
			color: #1a1a1a;
			margin-bottom: 16px;
		}
		& > input {
			width: 100%;
		}
	}
`;

const Actions = styled.div`
	margin-top: 145px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;

	${(props) => props.theme.device.md} {
		flex-direction: column;
		justify-content: center;
		margin-bottom: 20px;
	}
`;
const ActionButton = styled.button`
	width: 367px;
	height: 72px;
	background: ${(p) => (p.$isActive ? '#242424' : '#ffffff')};
	border-radius: 10px;
	font: normal normal 600 22px/48px Pretendard;
	letter-spacing: -0.55px;
	border: ${(p) => (p.$isActive ? 'none' : '1px solid #777777')};
	color: ${(p) => (p.$isActive ? '#ffffff' : '#000000')};

	${(props) => props.theme.device.md} {
		height: 47px;
		background: ${(p) => (p.$isActive ? '#1A1A1A' : '#ffffff')};
		font: normal normal 400 17px/20px Pretendard;
		letter-spacing: -0.17px;
	}
`;
const Error = styled.div`
	margin-top: 22px;
	text-align: center;
	font: normal normal 400 18px/21px Pretendard;
	letter-spacing: 0px;
	color: #fe7668;
	${(props) => props.theme.device.md} {
		margin-top: 12px;
		font: normal normal 500 14px/19px Pretendard;
		letter-spacing: -0.14px;
	}
`;
