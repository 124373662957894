import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;  
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;
}

  button {
    border: none;
    background-color: unset;
    cursor: pointer;
  }

  a {
    color: #000;
    cursor: pointer;
    text-decoration: none;
  }
  a:hover, a:visited {
    color: inherit;
  }
  /* reset INPUT */
  input, select {
    outline: none;
    font-size: 16px;
    border: none;
  }

  input:focus, select:focus {
    outline: none;
  }

  input::placeholder {
    font-size: 16px;
  }
  /* reset list */
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6,p {
    margin: 0;
  }
`;

export default GlobalStyle;
