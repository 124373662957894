import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const MyWork = ({ myWork }) => {
	const history = useHistory();

	return (
		<Work>
			<Thumbnail>
				<img src={myWork.thumbnail} alt='' />
				<label>{myWork.category}</label>
			</Thumbnail>
			<Author>{myWork.artistName}</Author>
			<Title>{myWork.name}</Title>
			<DiscountPrice>{myWork.price}원</DiscountPrice>
			<Button
				$isActive={
					myWork.approvalStatus === 'APPROVED'
						? true
						: myWork.approvalStatus === 'READY'
						? false
						: undefined
				}
				onClick={() =>
					myWork.approvalStatus === 'APPROVED' &&
					history.push(`/products/detail/${myWork.id}`)
				}
			>
				{myWork.approvalStatusText}
			</Button>
		</Work>
	);
};
export default MyWork;

const Work = styled.div`
	position: relative;
	width: 384px;
	margin-bottom: 95px;

	${(props) => props.theme.device.md} {
		width: 100%;
		&:first-child {
			margin-top: 0;
			margin-bottom: 45px;
		}
	}
`;
const Thumbnail = styled.div`
	position: relative;
	width: 384px;
	height: 384px;
	margin-bottom: 30px;
	/* border-radius: 15px; */
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		margin-bottom: 23px;
		border-radius: 15px;
	}
	label {
		position: absolute;
		top: 10px;
		left: 10px;
		width: 121px;
		height: 35px;
		background: #77777780;
		border-radius: 10px;
		line-height: 34px;
		font-weight: 500;
		font-size: 17px;
		letter-spacing: -0.17px;
		color: #ffffff;
		text-align: center;
	}

	${(props) => props.theme.device.md} {
		width: 366px;
		height: 366px;
		img {
			border-radius: 10px;
		}
		label {
			width: 68px;
			height: 22px;
			line-height: 22px;
			background: #77777780;
			font-weight: 500;
			font-size: 14px;
			letter-spacing: -0.14px;
			color: #ffffff;
		}
	}
`;
const Author = styled.div`
	display: none;
	${(props) => props.theme.device.md} {
		display: block;
		line-height: 18px;
		font-weight: 500;
		font-size: 15px;
		letter-spacing: -0.15px;
		color: #1a1a1a;
		margin-bottom: 10px;
	}
`;
const Title = styled.div`
	line-height: 31px;
	font-weight: 600;
	font-size: 26px;
	letter-spacing: -0.26px;
	color: #000000;
	margin-bottom: 24px;

	${(props) => props.theme.device.md} {
		line-height: 25px;
		font-size: 20px;
		letter-spacing: -0.2px;
		color: #1a1a1a;
		margin-bottom: 20px;
	}
`;
const DiscountPrice = styled.div`
	height: 32px;
	font-weight: bold;
	font-size: 27px;
	letter-spacing: -0.27px;
	color: #1a1a1a;
	${(props) => props.theme.device.md} {
		line-height: 24px;
		text-align: right;
		font-weight: bold;
		font-size: 20px;
		letter-spacing: -0.2px;
		color: #1a1a1a;
	}
`;
const Button = styled.button`
	width: 100%;
	height: 51px;
	background: ${(p) =>
		p.$isActive === undefined
			? '#BEBEBE'
			: p.$isActive
			? '#4568dc'
			: '#777777'};
	border-radius: 10px;
	margin-top: 47px;
	font-weight: 600;
	font-size: 18px;
	letter-spacing: -0.45px;
	color: #ffffff;

	${(props) => props.theme.device.md} {
		height: 50px;
		font-weight: 400;
		font-size: 17px;
		letter-spacing: -0.17px;
		color: #ffffff;
	}
`;
