import { forwardRef } from 'react';
import styled from 'styled-components';

const CategoryField = forwardRef(({ label, ...rest }, ref) => (
	<Wrapper>
		<label>
			<input ref={ref} type='radio' {...rest} />
			<span className='txt'>{label}</span>
		</label>
	</Wrapper>
));
export default CategoryField;

const Wrapper = styled.div`
	& > label {
		cursor: pointer;

		& > input {
			left: -99999px;
			position: absolute;

			&:checked ~ span {
				background: #242424 0% 0% no-repeat padding-box;
				letter-spacing: -0.18px;
				color: #ffffff;
			}
		}

		& > span {
			font: normal normal 500 18px/21px Pretendard;
			display: inline-block;
			min-width: 180px;
			height: 55px;
			align-items: center;
			border: 1px solid #bebebe;
			border-radius: 10px;

			color: #666;
			display: inline-flex;
			justify-content: center;
			padding: 0 8px;

			${(props) => props.theme.device.md} {
				font: normal normal 500 14px/17px Pretendard;
				min-width: 85px;
				height: 39px;
			}
		}

		${(p) => p.theme.device.md} {
		}
	}
`;
