export const ORDER_BY = [
	{
		label: '추천 순',
		value: '-recommandation_point',
	},
	{
		label: '인기 순',
		value: '-popularity',
	},
	{
		label: '최신 순',
		value: '-created',
	},
	{
		label: '오래된 순',
		value: 'created',
	},
	{
		label: '가격 낮은 순',
		value: 'price',
	},
	{
		label: '가격 높은 순',
		value: '-price',
	},
];

export const ARTWORK_PER_PAGE = 21;
