import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';

const FullButton = ({
	isLoading,
	width = '400px',
	url,
	text,
	backgroundColor,
	textColor,
	handleClick,
}) => {
	return (
		<Button
			isLoading={isLoading}
			width={width}
			backgroundColor={backgroundColor}
			textColor={textColor}
			onClick={isLoading ? null : handleClick}
		>
			{url ? <Link to={url}>{isLoading ? <Spin /> : text}</Link> : text}
		</Button>
	);
};
const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${(props) => props.width};
	height: 51px;
	font-size: 17px;
	color: ${(props) => (props.textColor ? props.textColor : '#fff')};
	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : '#6B98F2'};
	a {
		display: block;
		width: 100%;
		height: 100%;
		line-height: 51px;
		text-align: center;
		color: ${(props) => (props.textColor ? props.textColor : '#fff')};
	}
	${(props) => props.theme.device.md} {
		width: 100%;
	}
`;
export default FullButton;
