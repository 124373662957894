import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CertifiedSVG } from 'assets/svg/certified.svg';
// import { changeWork } from 'modules/detail';
import * as storage from 'lib/storage';

import {
	PurchaseQuantity,
	PurchaseTypeSelector,
} from 'components/ArtworkDetail';

const Purchase = ({ workInfo }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const isAuth = storage.isAuth();

	const [purchaseType, setPurchaseType] = useState(null);
	const [purchaseQuantity, setPurchaseQuantity] = useState(1);

	const moveToPurchasePage = () => {
		if (!isAuth) {
			history.push('/login');
			return;
		}

		if (!purchaseType) {
			alert('옵션을 선택하세요');
			return;
		}

		history.push(
			`/checkout?id=${workInfo.id}&amount=${purchaseQuantity}&price=${workInfo.price}&purchase=${purchaseType.value}&purchaseType=${purchaseType.label}`,
		);
	};

	return (
		<Wrapper>
			<Category>{workInfo.category}</Category>
			<Title>{workInfo.name}</Title>
			<Owner>
				<label>Owned by</label>
				<div>{workInfo.ownerNickname}</div>
			</Owner>
			<Owner>
				<label>Created by</label>
				<div>
					<a href={`/collection/detail/${workInfo.artist?.id}`}>
						{workInfo.artist?.name}
					</a>
					{workInfo.artist?.isVerified && <CertifiedSVG />}
				</div>
			</Owner>

			<MobileLinkRow></MobileLinkRow>

			<PriceRow>
				<Remaining>
					<span>{workInfo.availAmount}</span> 개 구매 가능
				</Remaining>

				<Price>
					{workInfo.price && workInfo.price.toLocaleString()}원
				</Price>
				{/* {actualDiscounted > 0 && (
					<Direction row flexEnd margin='20px'>
						<DiscountPercent>
							<DiscountRate
								price={workInfo.price}
								discountPrice={actualDiscounted}
							/>
						</DiscountPercent>
						<DiscountPrice>
							{(
								workInfo.price - actualDiscounted
							).toLocaleString()}
							원
						</DiscountPrice>
					</Direction>
				)} */}
			</PriceRow>

			<PurchaseTypeSelector
				price={workInfo.price || 0}
				rewardA={workInfo.rewardA}
				rewardB={workInfo.rewardB}
				rewardC={workInfo.rewardC}
				onChange={(purchaseType) => setPurchaseType(purchaseType)}
			/>
			<PurchaseQuantity
				title={purchaseType?.label}
				value={purchaseQuantity}
				price={workInfo.price || 0}
				maxQuantity={workInfo.availAmount}
				onChange={(quantity) => setPurchaseQuantity(quantity)}
			/>

			<CheckoutButton
				disabled={workInfo.availAmount === 0}
				onClick={moveToPurchasePage}
			>
				{workInfo.availAmount > 0 ? '결제하기' : '판매완료'}
			</CheckoutButton>
		</Wrapper>
	);
};

export default Purchase;

const Wrapper = styled.div`
	width: 408px;
	${(props) => props.theme.device.md} {
		width: 100%;
	}
`;
const Category = styled.div`
	line-height: 24px;
	font-weight: bold;
	font-size: 20px;
	letter-spacing: -0.2px;
	margin-bottom: 15px;

	${(props) => props.theme.device.md} {
		position: absolute;
		top: 9px;
		left: 36px;
		width: 68px;
		height: 22px;
		background: #77777780;
		border-radius: 10px;
		text-align: center;
		line-height: 22px;
		font-weight: 500;
		font-size: 14px;
		letter-spacing: -0.14px;
		color: #ffffff;
	}
`;
const Title = styled.div`
	line-height: 37px;
	font-weight: bold;
	font-size: 30px;
	letter-spacing: -0.3px;
	color: #1a1a1a;
	margin-bottom: 44px;

	${(props) => props.theme.device.md} {
		line-height: 25px;
		font-weight: 600;
		font-size: 20px;
		letter-spacing: -0.2px;
		margin-bottom: 24px;
	}
`;
const Owner = styled.div`
	line-height: 24px;
	font-weight: 600;
	font-size: 20px;
	letter-spacing: -0.2px;
	color: #1a1a1a;

	& > label {
		line-height: 21px;
		font-weight: 600;
		font-size: 18px;
		letter-spacing: -0.18px;
		color: #bebebe;
	}
	& + & {
		margin-top: 24px;
	}

	${(props) => props.theme.device.md} {
		line-height: 19px;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: -0.16px;

		& > label {
			line-height: 18px;
			font-size: 15px;
			letter-spacing: -0.15px;
		}
		& + & {
			margin-top: 20px;
		}
	}
`;
const PriceRow = styled.div`
	margin: 44px 0;
	${(props) => props.theme.device.md} {
		display: flex;
		margin-top: 20px;
		justify-content: flex-end;
	}
`;
const Remaining = styled.div`
	text-align: right;
	line-height: 21px;
	font-weight: 500;
	font-size: 18px;
	letter-spacing: -0.18px;
	color: #777777;
	span {
		font-weight: bold;
	}

	${(props) => props.theme.device.md} {
		line-height: 18px;
		font-size: 15px;
		letter-spacing: -0.15px;
		padding-top: 3px;
	}
`;
const Price = styled.div`
	text-align: right;
	line-height: 36px;
	font-weight: bold;
	font-size: 30px;
	letter-spacing: -0.3px;
	color: #1a1a1a;
	${(props) => props.theme.device.md} {
		line-height: 25px;
		margin-left: 13px;
		font-size: 21px;
		letter-spacing: -0.21px;
	}
`;
const CheckoutButton = styled.button`
	width: 100%;
	height: 62px;
	background: #1a1a1a;
	border-radius: 10px;
	font-weight: 600;
	font-size: 22px;
	letter-spacing: -0.22px;
	color: #ffffff;
	margin-top: 44px;
`;
const MobileLinkRow = styled.div`
	display: none;
	${(props) => props.theme.device.md} {
		display: flex;
	}
`;
