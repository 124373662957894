import styled from 'styled-components';
/**
 * 모든 페이지에 적용가능한 레이아웃 관련 styled Component들.
 */
export const CenterLayout = styled.article`
	width: 1200px;
	padding-top: 80px;

	${(p) => p.theme.device.md} {
		width: 100%;
		padding: 0 24px;
		padding-top: 35px;
	}
`;
export const Title = styled.h1`
	font: normal normal bold 37px/45px Pretendard;
	letter-spacing: -0.37px;
	color: #111111;

	${(p) => p.theme.device.md} {
		font: normal normal bold 24px/29px Pretendard;
		letter-spacing: -0.24px;
		color: #1a1a1a;
	}
`;

export const SubTitleRow = styled.div`
	display: flex;
	justify-content: space-between;
	line-height: 26px;
	font-weight: 600;
	font-size: 22px;
	letter-spacing: -0.22px;
	color: #111111;
	margin-bottom: 27px;

	${(p) => p.theme.device.md} {
		line-height: 20px;
		font-size: 17px;
		letter-spacing: -0.17px;
		color: #1a1a1a;
		margin-bottom: 16px;
	}
`;
