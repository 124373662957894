import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { UserInput } from 'components/common';
import Nav from 'components/MyPage/Nav';

import api from 'lib/api';
import * as storage from 'lib/storage';

const ChangePassword = () => {
	const history = useHistory();
	const email = storage.getEmail();

	// const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [checkPassword, setCheckPassword] = useState('');

	const changePassword = async () => {
		let regExp = /^(?=.*[a-zA-z])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/;

		if (!regExp.test(newPassword)) {
			alert(
				'비밀번호는 8자리 이상 문자, 숫자, 특수문자로 구성하여야 합니다.',
			);
			return;
		}

		if (newPassword !== checkPassword) {
			alert('비밀번호가 일치하지 않습니다.');
			return;
		}

		try {
			await api.put('/users/change-password/', {
				password: newPassword,
				passwordConfirm: checkPassword,
			});
			alert('비밀번호가 변경되엇습니다.');
			history.push('/');
		} catch (e) {
			alert('에러가 발생하였습니다.');
		}
	};

	return (
		<Nav>
			<ChangePasswordContainer>
				<Container>
					<Title>새로운 비밀번호를 설정해주세요</Title>
					<SubTitle>{email} 계정의 비밀번호를 변경합니다</SubTitle>

					{/* <UserInput
						type='password'
						text='현재 비밀번호'
						placeholder='비밀번호를 입력해 주세요.'
						value={password}
						handlechange={(e) => setPassword(e.target.value)}
					/> */}
					<UserInput
						type='password'
						text='새 비밀번호 (영문자+특수문자 조합 8자리)'
						placeholder='비밀번호를 입력해 주세요.'
						value={newPassword}
						handlechange={(e) => setNewPassword(e.target.value)}
					/>
					<UserInput
						type='password'
						text='새 비밀번호 확인'
						placeholder='비밀번호를 입력해 주세요.'
						value={checkPassword}
						handlechange={(e) => setCheckPassword(e.target.value)}
					/>
				</Container>
				<ButtonContainer>
					<ChangeButton onClick={changePassword}>
						비밀번호 변경하기
					</ChangeButton>
				</ButtonContainer>
			</ChangePasswordContainer>
		</Nav>
	);
};
const ChangePasswordContainer = styled.div``;
const Container = styled.div`
	width: 589px;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	padding-top: 75px;
	p {
		text-align: center;
		margin: 40px 0 80px;
	}

	${(props) => props.theme.device.md} {
		width: 100%;
		padding-top: 35px;
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 146px;
	margin-bottom: 250px;

	${(props) => props.theme.device.md} {
		margin-top: 70px;
		margin-bottom: 128px;
	}
`;
const ChangeButton = styled.button`
	width: 367px;
	height: 72px;
	background: #242424;
	border-radius: 10px;
	font-weight: 600;
	font-size: 22px;
	letter-spacing: -0.55px;
	color: #ffffff;

	${(props) => props.theme.device.md} {
		height: 47px;
		background: #1a1a1a;
		font-size: 17px;
		letter-spacing: -0.17px;
	}
`;
const Title = styled.div`
	display: none;
	${(props) => props.theme.device.md} {
		width: 171px;
		line-height: 31px;
		font-weight: bold;
		font-size: 24px;
		letter-spacing: -0.24px;
		color: #1a1a1a;
		display: block;
	}
`;
const SubTitle = styled.div`
	display: none;
	${(props) => props.theme.device.md} {
		display: block;
		line-height: 17px;
		font-weight: 500;
		font-size: 14px;
		letter-spacing: -0.14px;
		color: #777777;
		margin-top: 13px;
		margin-bottom: 41px;
	}
`;
export default ChangePassword;
