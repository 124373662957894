import { createAction, handleActions } from 'redux-actions';

const DETAIL = 'detail/DETAIL';
const CHANGE_OPTION = 'detail/CHANGE_OPTION';

// Action Function
export const changeWork = createAction(DETAIL, (work) => ({
	...work,
}));
export const selectOption = createAction(CHANGE_OPTION, (option) => ({
	...option,
}));

// Saga
// reducer
const option = {
	selected: '',
	amount: 1,
	totalPrice: 0,
};
export const initialState = {
	id: '',
	title: '',
	type: '',
	author: '',
	like: '',
	price: 0,
	discountedPrice: 0,
	discountedPercent: 0,
	url: '',
	option,
};

const detail = handleActions(
	{
		[DETAIL]: (state, { payload }) => ({
			...state,
			...payload,
		}),
	},
	initialState,
);

export default detail;
