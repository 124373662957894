import { useMemo } from 'react';
import styled from 'styled-components';

import useCategory from 'hooks/useCategory';

const MobileCategorySelector = ({ selectedCategory, onChange }) => {
	const categories = useCategory();

	const allCategory = useMemo(
		() => categories.find((c) => c.code === 'all'),
		[categories],
	);

	return (
		<Wrapper>
			{allCategory && (
				<Tab
					$active={selectedCategory === allCategory.code}
					onClick={() => onChange(allCategory.code)}
				>
					{allCategory.name}
				</Tab>
			)}
			{categories
				.filter((c) => c.code !== 'all')
				.map((category) => (
					<Tab
						key={category.id}
						$active={selectedCategory === category.code}
						onClick={() => onChange(category.code)}
					>
						{category.name}
					</Tab>
				))}
		</Wrapper>
	);
};
export default MobileCategorySelector;

const Wrapper = styled.article`
	display: none;

	${(p) => p.theme.device.md} {
		display: flex;
		width: 100%;
		height: 36px;
		border-bottom: 1px solid #bebebe;
		margin-bottom: 32px;
		overflow: auto;
	}
`;
const Tab = styled.div`
	flex-shrink: 0;
	font: ${(p) =>
		p.$active
			? 'normal normal 600 16px/19px Pretendard'
			: 'normal normal medium 16px/19px Pretendard'};
	letter-spacing: -0.16px;
	color: ${(p) => (p.$active ? '#000000' : '#bebebe')};
	padding-right: 35px;
	border-bottom: ${(p) => (p.$active ? '2px solid #1A1A1A' : 'unset')};
`;
