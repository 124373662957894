import { useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import styled from 'styled-components';

import { CenterLayout, SubTitleRow } from 'components/common/LayoutComps';
import Paginator from 'components/Paginator';
import * as Curation from 'components/common/ArtworkComps';
import useArtworksByCuration from 'hooks/useArtworksByCuration';
import api from 'lib/api';
import { ARTWORK_PER_PAGE } from 'lib/constants';
import FilterSelector from 'components/common/FilterSelector';
import MobileCategorySelector from './MobileCategorySelector';
import OrderBySelector from 'components/common/OrderBySelector';

/**
 * Art 메뉴 화면 리스팅 / 헤더 검색 결과 리스팅 / 큐레이션 리스팅
 */
const Listing = () => {
	const history = useHistory();
	const { search } = useLocation();
	const parsed = queryString.parse(search);

	const curationId = parsed['curation']; // 큐레이션 리스팅
	const searchQuery = parsed['search']; // 헤더 > 검색 결과 리스팅
	const category = parsed['cat']; // Art > 리스팅

	const [page, setPage] = useState(1);
	const [totalPage, setTotalPage] = useState(0);
	const [orderBy, setOrderBy] = useState({
		label: '추천 순',
		value: '-recommandation_point',
	});
	const [priceStart, setPriceStart] = useState(null);
	const [priceEnd, setPriceEnd] = useState(null);

	const [title, setTitle] = useState('');
	const [artworks, setArtworks] = useState([]);
	const artworksByCuration = useArtworksByCuration(curationId);

	const categoryQ = useMemo(
		() => (category !== 'all' ? `&category=${category}` : null),
		[category],
	);

	useEffect(() => {
		if (!!curationId) return;

		(async () => {
			try {
				const {
					response: {
						data: { results, count },
					},
				} = await api.get(
					`/artworks/?order_by=${
						orderBy.value
					}&page=${page}&per_page=${ARTWORK_PER_PAGE}${
						category ? `&category=${category}` : ''
					}${priceStart ? `&price_starts=${priceStart}` : ''}${
						priceEnd ? `&price_ends=${priceEnd}` : ''
					}${searchQuery ? `&search=${searchQuery}` : ''}`,
				);

				setTitle(category);
				setArtworks(results);
				setTotalPage(count);
			} catch (e) {
				setArtworks([]);
				setTotalPage(0);
			}
		})();
		window.scrollTo(0, 0);
	}, [categoryQ, orderBy.value, page, priceStart, priceEnd, searchQuery]);

	useEffect(() => {
		if (!curationId) return;

		if (artworksByCuration.artworks.length > 0) {
			setArtworks(artworksByCuration.artworks);
		}
		if (artworksByCuration.curation) {
			setTitle('#' + artworksByCuration.curation.name);
		}
	}, [artworksByCuration]);

	return (
		<CenterLayout>
			<MobileCategorySelector
				selectedCategory={category}
				onChange={(category) =>
					history.push(`/listing?cat=${category}`)
				}
			/>

			<SubTitleRow>
				{searchQuery ? (
					<QueryTitle>{searchQuery}</QueryTitle>
				) : (
					<Title>{title}</Title>
				)}

				<RightSection>
					<OrderBySelector
						orderBy={orderBy}
						onChange={(orderby) => setOrderBy(orderby)}
					/>
					<FilterSelector
						onChange={(priceStart, priceEnd) => {
							setPriceStart(priceStart);
							setPriceEnd(priceEnd);
						}}
					/>
				</RightSection>
			</SubTitleRow>

			<Curation.CurationList>
				{artworks.map((artwork) => (
					<Curation.Artwork
						key={artwork.id}
						onClick={() =>
							history.push(`/products/detail/${artwork.id}`)
						}
					>
						<Curation.Category>
							{artwork.category}
						</Curation.Category>
						<Curation.Thumbnail $thumbnail={artwork.thumbnail} />
						<Curation.Artist
							$isVerified={artwork.artist.isVerified}
						>
							{artwork.artist.name}
						</Curation.Artist>
						<Curation.ArtworkTitle>
							{artwork.name}
						</Curation.ArtworkTitle>
						<Curation.PriceRow>
							<div className='extra-row'>
								{artwork.discountPrice > 0 && (
									<div className='price'>
										{artwork.price.toLocaleString()}
									</div>
								)}

								{artwork.discountPrice > 0 && (
									<div className='discount-rate'>{'xx%'}</div>
								)}
							</div>
							{(
								artwork.discountPrice || artwork.price
							).toLocaleString()}
							원
						</Curation.PriceRow>
					</Curation.Artwork>
				))}
			</Curation.CurationList>

			{totalPage > ARTWORK_PER_PAGE && (
				<Paginator
					pageSize={ARTWORK_PER_PAGE}
					totalPage={totalPage}
					handlePageChange={(page) => setPage(page.selected + 1)}
				/>
			)}
		</CenterLayout>
	);
};
export default Listing;

const RightSection = styled.div`
	display: flex;
	align-items: center;
	height: 21px;

	${(p) => p.theme.device.md} {
		height: 28px;
		padding: 6px 16px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #bebebe;
		border-radius: 17px;
	}
`;
const Title = styled.div`
	${(p) => p.theme.device.md} {
		color: #fff;
	}
`;
const QueryTitle = styled.div`
	font: normal normal bold 37px/45px Pretendard;
	letter-spacing: -0.37px;
	color: #111111;
	margin-bottom: 43px;
`;
