import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 94px;
	padding-top: 75px;
	padding-bottom: 94px;

	${(props) => props.theme.device.md} {
		padding-top: 36px;
		gap: 20px;
	}
`;
const Artwork = styled.div`
	position: relative;
	display: flex;
	gap: 110px;
	${(props) => props.theme.device.md} {
		flex-direction: column;
		margin-top: 0;
		gap: 0;
		padding: 86px 0 40px 0;
	}
`;
const Status = styled.div`
	position: absolute;
	top: 20px;
	left: 20px;
	width: 121px;
	padding-top: 8px;
	height: 35px;
	text-align: center;
	vertical-align: middle;
	background: ${(p) => (p.$isActive ? '#4568dc' : '#777777')};
	border-radius: 10px;
	font: normal normal 500 17px/20px Pretendard;
	letter-spacing: -0.17px;
	color: #ffffff;

	${(props) => props.theme.device.md} {
		width: unset;
		background: none;
		font: normal normal bold 22px/26px Pretendard;
		letter-spacing: -0.22px;
		color: #1a1a1a;
		left: 0;
		top: 41px;
	}
`;
const ThumbnailWrapper = styled.div``;
const Image = styled.img`
	display: inline-block;
	width: 477px;
	height: 477px;
	border-radius: 10px;
	${(props) => props.theme.device.md} {
		width: 100%;
		height: 366px;
	}
`;
const ActionsWrapper = styled.div`
	width: 477px;
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
	${(props) => props.theme.device.md} {
		width: 100%;
		margin-top: 20px;
	}
`;
const ViewButton = styled.button`
	width: 228px;
	height: 51px;
	background: #ffffff;
	border: 1px solid #777777;
	border-radius: 10px;
	line-height: 21px;
	text-align: center;
	font-weight: medium;
	font-size: 18px;
	letter-spacing: -0.45px;
	color: #000000;

	${(props) => props.theme.device.md} {
		width: 175px;
		height: 42px;
		font-weight: 500;
		font-size: 15px;
		letter-spacing: -0.15px;
		color: #1a1a1a;
	}
`;
const MyWalletButton = styled.button`
	width: 228px;
	height: 51px;
	background: #242424;
	border-radius: 10px;
	line-height: 21px;
	text-align: center;
	font-weight: medium;
	font-size: 18px;
	letter-spacing: -0.45px;
	color: #ffffff;

	${(props) => props.theme.device.md} {
		width: 175px;
		height: 42px;
		font-weight: 500;
		font-size: 15px;
		letter-spacing: -0.15px;
	}
`;
const DescriptionWrapper = styled.div`
	padding-top: 40px;
	width: 516px;
	${(props) => props.theme.device.md} {
		padding-top: 26px;
		width: 100%;
	}
`;
const DescriptionHeaderWrapper = styled.div``;
const Category = styled.div`
	line-height: 29px;
	font-weight: bold;
	font-size: 24px;
	letter-spacing: -0.24px;
	color: #000000;
	${(props) => props.theme.device.md} {
		font: normal normal bold 15px/18px Pretendard;
		letter-spacing: -0.15px;
		color: #1a1a1a;
	}
`;
const Title = styled.div`
	line-height: 38px;
	font-weight: bold;
	font-size: 30px;
	letter-spacing: -0.3px;
	color: #111111;

	${(props) => props.theme.device.md} {
		font: normal normal 600 20px/25px Pretendard;
		letter-spacing: -0.2px;
		color: #1a1a1a;
	}
`;

const DescriptionBodyWrapper = styled.div`
	margin-top: 40px;
	padding-bottom: 23px;
	border-bottom: 1px solid #bebebe;

	${(props) => props.theme.device.md} {
		border-bottom: none;
	}
`;
const AuthorBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
`;
const PaymentBox = styled.div`
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	gap: 13px;

	${(props) => props.theme.device.md} {
		margin-top: 29px;
		border-top: 1px solid #bebebe;
		padding-top: 25px;
	}
`;

const Label = styled.label`
	width: 110px;
	font-weight: medium;
	font-size: 17px;
	letter-spacing: 0px;
	color: #000000;
	flex-shrink: 0;

	${(props) => props.theme.device.md} {
		width: 95px;
		font: normal normal 600 15px/18px Pretendard;
		letter-spacing: -0.15px;
		color: #bebebe;
	}
`;
const Row = styled.div`
	display: flex;
	line-height: 20px;
	font-size: 17px;
	letter-spacing: 0px;
	color: #666666;
	${(props) => props.theme.device.md} {
		line-height: 18px;
		font-weight: 500;
		font-size: 15px;
		letter-spacing: -0.15px;
		color: #1a1a1a;
	}
`;
const OwnerRow = styled(Row)`
	${(props) => props.theme.device.md} {
		flex-direction: column;
		gap: 8px;
	}
`;

const DescriptionFooter = styled.div`
	padding-top: 22px;
	${(props) => props.theme.device.md} {
		display: none;
	}
`;
const DescriptionMobileFooter = styled.div`
	display: none;
	${(props) => props.theme.device.md} {
		display: block;
		width: 100%;
		padding: 26px 24px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #bebebe;
		border-radius: 10px;

		.sell-type {
			font: normal normal 600 15px/18px Pretendard;
			letter-spacing: -0.15px;
			color: #1a1a1a;
			margin-bottom: 20px;
		}
		.amount-price {
			display: flex;
			justify-content: flex-end;
		}
		.amount {
			font: normal normal 500 15px/24px Pretendard;
			letter-spacing: -0.15px;
			color: #777777;
			margin-right: 11px;
		}
		.price {
			font: normal normal bold 20px/24px Pretendard;
			letter-spacing: -0.2px;
			color: #1a1a1a;
		}
	}
`;

const Actions = Object.assign(ActionsWrapper, { ViewButton, MyWalletButton });
const Thumbnail = Object.assign(ThumbnailWrapper, {
	Image,
	Actions,
});

const DescriptionHeader = Object.assign(DescriptionHeaderWrapper, {
	Category,
	Title,
});
const DescriptionBody = Object.assign(DescriptionBodyWrapper, {
	AuthorBox,
	PaymentBox,
});
const Description = Object.assign(DescriptionWrapper, {
	Header: DescriptionHeader,
	Body: DescriptionBody,
	Footer: DescriptionFooter,
	MobileFooter: DescriptionMobileFooter,
	Label,
	Row,
	OwnerRow,
});

export default Object.assign(Wrapper, {
	Artwork,
	Description,
	Status,
	Thumbnail,
});
