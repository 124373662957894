import React from 'react';
import styled from 'styled-components';

const ResistrationLayout = ({ children, name, step, steps, hideSteps }) => {
	const currentStepIdx = steps.findIndex((op) => op.step === step);

	return (
		<Wrapper>
			<NavContainer>
				<Title>
					안녕하세요!
					<br />
					<span>{name || '회원'}님</span>
				</Title>
				{!hideSteps && (
					<StepWrapper>
						{steps.map((stepOp, i) => {
							return (
								<Step key={i} $active={i <= currentStepIdx}>
									<div className='indicator'>{i + 1}</div>
									{stepOp.label}
								</Step>
							);
						})}
						<div className='line' />
					</StepWrapper>
				)}
			</NavContainer>
			<ContentContainer>{children}</ContentContainer>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 1200px;
	margin: 40px auto 60px;
	${(props) => props.theme.device.md} {
		width: 100%;
		margin: 0;
	}
`;
const StepWrapper = styled.ul`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 160px;
	margin-top: 45px;

	& > .line {
		position: absolute;
		top: 20px;
		width: 100%;
		height: 1px;
		border-bottom: 1px dashed #bebebe;
		z-index: -10;
	}

	${(props) => props.theme.device.md} {
		gap: 50px;
		margin-top: 35px;

		& > .line {
			top: 13px;
			border-bottom: 1px solid #bebebe;
		}
	}
`;
const Step = styled.li`
	text-align: center;
	font: normal normal 400 18px/21px Pretendard;
	font-weight: ${(p) => (p.$active ? '600' : '400')};
	letter-spacing: -0.18px;
	color: #000000;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	& > .indicator {
		width: 40px;
		height: 40px;
		background: ${(p) => (p.$active ? '#4568dc' : '#BEBEBE')};
		border-radius: 50%;
		letter-spacing: -0.18px;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 11px;
	}

	${(props) => props.theme.device.md} {
		font: normal normal 500 11px/14px Pretendard;
		letter-spacing: -0.11px;

		& > .indicator {
			width: 28px;
			height: 28px;
			font: normal normal 400 13px/16px Pretendard;
			letter-spacing: -0.13px;
			margin-bottom: 9px;
		}
	}
`;
const NavContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;
const Title = styled.div`
	font: normal normal 400 22px/26px Pretendard;
	letter-spacing: -0.11px;
	color: #666666;
	span {
		font: normal normal bold 37px/45px Pretendard;
		letter-spacing: -0.37px;
		color: #111111;
	}

	${(props) => props.theme.device.md} {
		display: none;
	}
`;
const ContentContainer = styled.div`
	padding-top: 95px;
	padding-bottom: 300px;
	${(props) => props.theme.device.md} {
		width: 100%;
		padding: 46px 24px 158px 24px;
	}
`;
export default ResistrationLayout;
