import { useLocation } from 'react-router-dom';

import API from 'lib/api';
import { login, setNickname, setEmail } from 'lib/storage';
import { useEffect } from 'react';

const SNSLoginCallBack = () => {
	const { search } = useLocation();

	useEffect(() => {
		(async () => {
			const token = new URLSearchParams(search).get('key');
			login(token);
			const {
				response: { data },
			} = await API.get('/user/');
			setNickname(data.nickname);
			setEmail(data.username);
			window.location.href = '/';
		})();
	}, []);
	return <div />;
};

export default SNSLoginCallBack;
