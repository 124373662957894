import styled from 'styled-components';
import { Section } from 'components/Checkout';

const PaymentMethod = ({ paymentInfo, handleChange }) => (
	<Section label='결제 수단'>
		<Method>
			<input
				type='radio'
				id='trans'
				name='trans'
				checked={paymentInfo.paymentMethod === 'trans'}
				onChange={() => handleChange('paymentMethod', 'trans')}
			/>
			<label htmlFor='trans'>실시간 계좌이체</label>
		</Method>

		<Method>
			<input
				type='radio'
				id='vbank'
				name='vbank'
				checked={paymentInfo.paymentMethod === 'vbank'}
				onChange={() => handleChange('paymentMethod', 'vbank')}
			/>
			<label htmlFor='vbank'>가상계좌</label>
		</Method>

		{/* <Method>
			<input
				type='radio'
				id='kakaopay'
				name='kakaopay'
				checked={paymentInfo.paymentMethod === 'kakaopay'}
				onChange={() => handleChange('paymentMethod', 'kakaopay')}
			/>
			<label htmlFor='kakaopay'>카카오페이</label>
		</Method> */}

		{/* <Method>
			<input
				type='radio'
				id='paypal'
				name='paypal'
				checked={paymentInfo.paymentMethod === 'paypal'}
				onChange={() => handleChange('paymentMethod', 'paypal')}
			/>
			<label htmlFor='paypal'>
				Paypal <span>(해외카드 전용)</span>
			</label>
		</Method> */}
	</Section>
);
export default PaymentMethod;

const Method = styled.div`
	display: flex;
	align-items: center;
	& > label {
		font: normal normal 500 20px/24px Pretendard;
		letter-spacing: -0.4px;
		color: #111111;
		margin-left: 22px;

		& > span {
			color: #bebebe;
		}
	}

	& > input[type='radio'] {
		width: 24px;
		height: 24px;
	}
	& + & {
		margin-top: 34px;
	}

	${(p) => p.theme.device.md} {
		padding-left: 8px;
		& > label {
			font: normal normal 500 16px/19px Pretendard;
			letter-spacing: -0.16px;
			color: #1a1a1a;
			margin-left: 14px;
		}
		& > input[type='radio'] {
			width: 16px;
			height: 16px;
		}
		& + & {
			margin-top: 19px;
		}
	}
`;
