/**
 * Collections 관련 styled Component들.
 */

import styled from 'styled-components';

export const CollectionWrapper = styled.article`
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	${(p) => p.theme.device.md} {
		gap: 10px;
		justify-content: space-between;
	}
`;
export const Collection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 384px;
	height: 483px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 50px #0000000d;
	border-radius: 15px;
	padding: 0 64px;
	padding-top: 77px;
	padding-bottom: 30px;
	cursor: pointer;

	${(p) => p.theme.device.md} {
		/* width: 178px; */
		width: calc((100% - 24px) / 2);
		height: 240px;
		border-radius: 10px;
		padding: 0 30px;
		padding-top: 37px;
		padding-bottom: 8px;
	}
`;
export const Thumbnail = styled.div`
	flex-shrink: 0;
	background: #cccccc 0% 0% no-repeat padding-box;
	background-image: url(${(p) => p.$thumbnail});
	background-size: cover;
	background-position: center center;
	width: 125px;
	height: 125px;
	border-radius: 50%;
	${(p) => p.theme.device.md} {
		width: 91px;
		height: 91px;
	}
`;
export const ArtistName = styled.div`
	font: normal normal 600 25px/30px Pretendard;
	letter-spacing: -0.25px;
	color: #000000;
	margin-top: 17px;

	${(p) => p.theme.device.md} {
		font: normal normal 600 17px/20px Pretendard;
		letter-spacing: -0.34px;
		color: #1a1a1a;
		margin-top: 10px;
	}
`;
export const Description = styled.div`
	width: 100%;
	font: normal normal medium 16px/24px Pretendard;
	letter-spacing: -0.24px;
	color: #777777;
	margin-top: 31px;
	/* white-space: normal; */
	overflow: hidden;
	text-overflow: ellipsis;

	${(p) => p.theme.device.md} {
		margin-top: 10px;
		height: 34px;
		font: normal normal normal 13px/18px Pretendard;
		letter-spacing: -0.13px;
		color: #777777;
		overflow: hidden;
	}
`;
