import styled from 'styled-components';
import { Link as _Link } from 'react-router-dom';

import CertifiedSVG from 'assets/svg/certified.svg';

// TODO: Layout 공통으로 이름 바꿀것. -> components/common/LayoutComps/CenterLayout으로 대체할것.
// export const CurationWrapper = styled.article`
// 	width: 1200px;
// 	padding-top: 106px;
// 	margin-bottom: 170px;

// 	${(p) => p.theme.device.md} {
// 		width: 100%;
// 		padding: 0 32px;
// 		padding-top: 38px;
// 		margin-bottom: 78px;
// 	}
// `;

export const CurationSection = styled.div`
	/* width: 100%; */
`;
export const CurationTitleRow = styled.div`
	display: flex;
	justify-content: space-between;
	line-height: 26px;
	font-weight: 600;
	font-size: 22px;
	letter-spacing: -0.22px;
	color: #111111;
	margin-bottom: 27px;

	${(p) => p.theme.device.md} {
		line-height: 20px;
		font-size: 17px;
		letter-spacing: -0.17px;
		color: #1a1a1a;
		margin-bottom: 16px;
	}
`;
export const Link = styled(_Link)`
	font-weight: 500;
	font-size: 18px;
	letter-spacing: -0.18px;
	color: #777777;

	${(p) => p.theme.device.md} {
		text-decoration: underline;
		font-size: 15px;
		letter-spacing: -0.15px;
	}
`;
export const Title = styled.div`
	line-height: 45px;
	font-weight: bold;
	font-size: 37px;
	letter-spacing: -0.37px;
	color: #111111;
	margin-bottom: 14px;
	margin-top: 99px;
	${(p) => p.theme.device.md} {
		line-height: 29px;
		font-size: 24px;
		letter-spacing: -0.24px;
		color: #1a1a1a;
		margin-bottom: 16px;
		margin-top: 60px;
	}
`;
export const CurationList = styled.div`
	min-height: 493px;
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	${(p) => p.theme.device.md} {
		flex-direction: column;
		width: 100%;
	}
`;
export const Artwork = styled.div`
	flex-basis: 384px;
	width: 384px;
	position: relative;
	margin-bottom: 82px;
	cursor: pointer;
	${(p) => p.theme.device.md} {
		width: 100%;
		flex-basis: 350px;
		margin-bottom: 50px;
	}
`;
export const Thumbnail = styled.div`
	width: 100%;
	height: 384px;
	background: #cccccc 0% 0% no-repeat padding-box;
	border-radius: 15px;
	margin-bottom: 30px;
	background-image: url(${(p) => p.$thumbnail});
	background-size: cover;
	background-position: center center;
	${(p) => p.theme.device.md} {
		width: 100%;
		height: 350px;
		border-radius: 10px;
		margin-bottom: 20px;
	}
`;
export const Category = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	min-width: 121px;
	height: 35px;
	background: #77777780;
	border-radius: 10px;
	top: 10px;
	left: 10px;
	font-weight: 500;
	font-size: 17px;
	letter-spacing: -0.17px;
	color: #ffffff;
	padding: 0 46px;

	${(p) => p.theme.device.md} {
		top: 9px;
		left: 9px;
		min-width: 68px;
		height: 22px;
		font-size: 14px;
		letter-spacing: -0.14px;
		padding: 0 24px;
	}
`;
export const Artist = styled.div`
	line-height: 22px;
	font-weight: 500;
	font-size: 19px;
	letter-spacing: -0.19px;
	color: #1a1a1a;
	margin-bottom: 8px;

	&::after {
		background: ${(p) =>
			p.$isVerified ? `url(${CertifiedSVG}) no-repeat 0px 0px` : 'none'};
		content: '';
		display: inline-block;
		height: 17px;
		width: 17px;
		margin-left: 5px;
		${(p) => p.theme.device.md} {
			margin-left: 3px;
		}
	}

	${(p) => p.theme.device.md} {
		line-height: 18px;
		font-size: 15px;
		letter-spacing: -0.15px;
		margin-bottom: 18px;
	}
`;
export const ArtworkTitle = styled.div`
	font: normal normal 600 25px/30px Pretendard;
	letter-spacing: -0.25px;
	color: #000000;

	${(p) => p.theme.device.md} {
		font: normal normal 600 20px/25px Pretendard;
		letter-spacing: -0.2px;
		color: #1a1a1a;
	}
`;
export const PriceRow = styled.div`
	display: flex;
	line-height: 31px;
	font-weight: bold;
	font-size: 26px;
	letter-spacing: -0.26px;
	color: #1a1a1a;
	margin-top: 25px;

	${(p) => p.theme.device.md} {
		flex-direction: column;
		align-items: flex-end;
		line-height: 21px;
		font-size: 18px;
		letter-spacing: -0.18px;
	}

	.extra-row {
		display: flex;
		${(p) => p.theme.device.md} {
			line-height: 16px;
		}

		.price {
			text-decoration: line-through;
			font-weight: 500;
			font-size: 16px;
			letter-spacing: -0.16px;
			color: #bebebe;
			margin-right: 6px;

			${(p) => p.theme.device.md} {
				margin-right: 11px;
				font-size: 13px;
				letter-spacing: -0.13px;
			}
		}
		.discount-rate {
			font-weight: 500;
			font-size: 16px;
			letter-spacing: -0.16px;
			color: #2f5fff;
			margin-right: 9px;
			${(p) => p.theme.device.md} {
				margin-right: 0;
				font-size: 12px;
				letter-spacing: -0.12px;
			}
		}
	}
`;
