import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import { ReactComponent as ArrowSVG } from 'assets/svg/chevron-left.svg';
import * as Curation from 'components/common/ArtworkComps';
import useCurations from 'hooks/useCurations';
import useArtworksByCuration from 'hooks/useArtworksByCuration';
import { getDiscountRate } from 'lib/util';

const ArtworksByCuration = ({ curationId }) => {
	const history = useHistory();
	const { artworks } = useArtworksByCuration(curationId);

	return (
		<>
			<PcCurationList>
				<Swiper
					spaceBetween={24}
					slidesPerView={3}
					modules={[Navigation]}
					navigation={{
						nextEl: `.curation-${curationId}-next`,
						prevEl: `.curation-${curationId}-prev`,
					}}
				>
					{artworks.map((artwork) => {
						const discounrRate = getDiscountRate(artwork);
						return (
							<SwiperSlide key={artwork.id}>
								<Curation.Artwork
									onClick={() =>
										history.push(
											`/products/detail/${artwork.id}`,
										)
									}
								>
									<Curation.Category>
										{artwork.category}
									</Curation.Category>
									<Curation.Thumbnail
										$thumbnail={artwork.thumbnail}
									></Curation.Thumbnail>
									<Curation.Artist>
										{artwork.artist.name}
									</Curation.Artist>
									<Curation.ArtworkTitle>
										{artwork.name}
									</Curation.ArtworkTitle>
									<Curation.PriceRow>
										{discounrRate > 0 && (
											<div className='extra-row'>
												<div className='price'>
													{artwork.price.toLocaleString()}
												</div>
												<div className='discount-rate'>
													{discounrRate}%
												</div>
											</div>
										)}
										{(
											artwork.price -
											artwork.discountPrice
										).toLocaleString()}
										원
									</Curation.PriceRow>
								</Curation.Artwork>
							</SwiperSlide>
						);
					})}
				</Swiper>
				<ArrowSVG className={`curation-${curationId}-prev nft-prev`} />
				<ArrowSVG className={`curation-${curationId}-next nft-next`} />
			</PcCurationList>

			<MobileCurationList>
				<Swiper
					spaceBetween={32}
					slidesPerView={1}
					pagination={{
						dynamicBullets: true,
					}}
					modules={[Pagination]}
				>
					{artworks.map((artwork) => {
						const discounrRate = getDiscountRate(artwork);
						return (
							<SwiperSlide key={artwork.id}>
								<Curation.Artwork
									onClick={() =>
										history.push(
											`/products/detail/${artwork.id}`,
										)
									}
								>
									<Curation.Category>
										{artwork.category}
									</Curation.Category>
									<Curation.Thumbnail
										$thumbnail={artwork.thumbnail}
									/>
									<Curation.Artist>
										{artwork.artist.name}
									</Curation.Artist>
									<Curation.ArtworkTitle>
										{artwork.name}
									</Curation.ArtworkTitle>
									<Curation.PriceRow>
										{discounrRate > 0 && (
											<div className='extra-row'>
												<div className='price'>
													{artwork.price.toLocaleString()}
												</div>
												<div className='discount-rate'>
													{discounrRate}%
												</div>
											</div>
										)}
										{(
											artwork.price -
											artwork.discountPrice
										).toLocaleString()}
										원
									</Curation.PriceRow>
								</Curation.Artwork>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</MobileCurationList>
		</>
	);
};

const CurationSection = () => {
	const curations = useCurations();

	return (
		<>
			{curations.map((curation) => (
				<Curation.CurationSection key={curation.id}>
					<Curation.CurationTitleRow>
						#{curation.name}
						<Curation.Link to={`listing?curation=${curation.id}`}>
							전체보기
						</Curation.Link>
					</Curation.CurationTitleRow>

					<ArtworksByCuration curationId={curation.id} />
				</Curation.CurationSection>
			))}
		</>
	);
};
export default CurationSection;

const PcCurationList = styled.div`
	display: block;
	position: relative;

	& > svg {
		position: absolute;
		top: 180px;
		cursor: pointer;

		&.nft-prev {
			transform: rotate(90deg);
			left: -37px;
		}
		&.nft-next {
			transform: rotate(270deg);
			right: -37px;
		}
	}
	${(p) => p.theme.device.md} {
		display: none;
	}
`;
const MobileCurationList = styled.div`
	display: none;
	${(p) => p.theme.device.md} {
		display: block;
	}
`;
