import * as React from 'react';

function SvgComponent(props) {
	return (
		<svg
			data-name={2}
			xmlns='http://www.w3.org/2000/svg'
			width={9.4}
			height={6.1}
			viewBox='0 0 9.4 6.1'
			{...props}
		>
			<path
				data-name='\uD328\uC2A4 36'
				d='M4.7 6.1L0 1.4 1.4 0l3.3 3.3L8 0l1.4 1.4z'
				fill='#ddd'
			/>
		</svg>
	);
}

export default SvgComponent;
