import { useEffect } from 'react';
import { callback } from '../../lib/checkout';
import {
	// BrowserRouter as Router,
	// Link,
	useLocation,
} from 'react-router-dom';
// import axios from 'axios';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default () => {
	let query = useQuery();

	useEffect(() => {
		const success = query.get('imp_success');
		const impUID = query.get('imp_uid');
		const merchantUID = query.get('merchant_uid');
		const amount = query.get('amount');
		const paymentMethod = query.get('pay_method');

		if (!success) {
			alert('결제에 실패했습니다. 고객센터에 문의바랍니다.');
			window.location.href = '/';
			return;
		}

		callback({
			success: true,
			imp_uid: impUID,
			merchant_uid: merchantUID,
			error_msg: '',
			paid_amount: Number(amount),
			pay_method: paymentMethod,
		});
	}, []);

	// const runCallback = async () => {
	//   const impUID = query.get('imp_uid');
	//   const merchantUID = query.get('merchant_uid');

	//   const getToken = await axios({
	//     url: "https://api.iamport.kr/users/getToken",
	//     method: "post", // POST method
	//     headers: { "Content-Type": "application/json" }, // "Content-Type": "application/json"
	//     data: {
	//       imp_key: "9871252565388725", // REST API 키
	//       imp_secret: "9871252565388725" // REST API Secret
	//     }
	//   });
	//   const { access_token } = getToken.data.response; // 인증 토큰

	//   // imp_uid로 아임포트 서버에서 결제 정보 조회
	//   const getPaymentData = await axios({
	//     url: `https://api.iamport.kr/payments/${impUID}`, // imp_uid 전달
	//     method: "get", // GET method
	//     headers: { "Authorization": access_token } // 인증 토큰 Authorization header에 추가
	//   });
	//   const paymentData = getPaymentData.data.response; // 조회한 결제 정보

	//   callback({ imp_uid: impUID, merchant_uid: merchantUID, success: true, error_msg: '', ...paymentData });
	// };

	return <></>;
};
