import styled from 'styled-components';
import moment from 'moment';

import { ReactComponent as ViewSVG } from 'assets/svg/eye.svg';

const NoticeRow = ({
	id,
	title,
	nickname,
	created,
	viewCount,
	handleClick,
}) => (
	<>
		<DesktopWrapper onClick={() => handleClick(id)}>
			<Title>{title}</Title>
			<InfoRow>
				<Creator>{nickname}</Creator>
				<Created>{moment(created).format('YY.MM.DD')}</Created>
				<ViewCount>
					<ViewSVG />
					{viewCount}
				</ViewCount>
			</InfoRow>
		</DesktopWrapper>

		<MobileWrapper onClick={() => handleClick(id)}>
			<Creator>{nickname}</Creator>
			<Title>{title}</Title>
			<InfoRow>
				<Created>{moment(created).format('YY.MM.DD')}</Created>
				<ViewCount>
					<ViewSVG />
					{viewCount}
				</ViewCount>
			</InfoRow>
		</MobileWrapper>
	</>
);
export default NoticeRow;

const DesktopWrapper = styled.div`
	cursor: pointer;
	height: 122px;
	padding: 32px 0;
	border-bottom: 0.5px solid #bebebe;
	${(props) => props.theme.device.md} {
		display: none;
	}
`;
const MobileWrapper = styled.div`
	display: none;
	${(props) => props.theme.device.md} {
		display: block;
		width: 100%;
		height: auto;
		line-height: 15px;
		font-size: 13px;
		padding: 42px 0;
		border-bottom: 1px solid #dddddd;
	}
`;
const Title = styled.div`
	line-height: 24px;
	font-weight: bold;
	font-size: 20px;
	letter-spacing: -0.2px;
	color: #000000;

	${(props) => props.theme.device.md} {
		line-height: 21px;
		font-size: 18px;
		letter-spacing: -0.18px;
		color: #1a1a1a;
		margin-top: 12px;
	}
`;
const InfoRow = styled.div`
	display: flex;
	line-height: 19px;
	font-size: 16px;
	letter-spacing: 0px;
	color: #777777;
	margin-top: 15px;

	${(props) => props.theme.device.md} {
		line-height: 16px;
		font-size: 13px;
		margin-top: 11px;
	}
`;
const Creator = styled.div`
	font-weight: medium;
	letter-spacing: -0.16px;
	color: #000000;
	padding-right: 35px;
	border-right: 1px solid #dddddd;

	${(props) => props.theme.device.md} {
		letter-spacing: 0px;
		color: #1a1a1a;
		padding: 0;
		border-right: none;
	}
`;
const Created = styled.div`
	padding-left: 35px;
	padding-right: 38px;
	border-right: 1px solid #dddddd;

	${(props) => props.theme.device.md} {
		letter-spacing: 0px;
		color: #1a1a1a;
		padding: 0;
		padding-right: 8px;
		border-right: none;
	}
`;
const ViewCount = styled.div`
	padding-left: 38px;
	& > svg {
		margin-right: 5px;
	}

	${(props) => props.theme.device.md} {
		padding-left: 12px;
	}
`;
