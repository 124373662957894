import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import api from 'lib/api';

const GalleryDetail = () => {
	const { id } = useParams();

	const [gallery, setGallery] = useState(null);

	const sources = useMemo(
		() =>
			gallery
				? gallery.code
						.match(/<iframe [^>]*src="[^"]*"[^>]*>/gm)
						.map((x) => x.replace(/.*src="([^"]*)".*/, '$1'))[0]
				: '',
		[gallery],
	);

	useEffect(() => {
		if (id < 0) return;
		(async () => {
			try {
				const {
					response: {
						data: { data },
					},
				} = await api.get(`/gallery/${id}/`);
				setGallery(data);
			} catch (e) {
				setGallery(null);
			}
		})();
		window.scrollTo(0, 0);
	}, [id]);

	return (
		<Wrapper>
			<Title>{gallery?.name}</Title>
			<Showroom>
				<iframe
					title={gallery?.name}
					allowFullScreen={true}
					src={sources}
					width='100%'
					height='100%'
					frameBorder={0}
					scrolling={'no'}
				/>
			</Showroom>
		</Wrapper>
	);
};
export default GalleryDetail;

const Wrapper = styled.section`
	width: 1200px;
	padding: 83px 0 252px 0;
	${(p) => p.theme.device.md} {
		width: 100%;
		padding: 35px 24px 148px 24px;
	}
`;
const Title = styled.div`
	font: normal normal 600 22px/26px Pretendard;
	letter-spacing: -0.22px;
	color: #111111;

	${(p) => p.theme.device.md} {
		font: normal normal 600 20px/24px Pretendard;
		letter-spacing: -0.2px;
		color: #1a1a1a;
	}
`;
const Showroom = styled.div`
	margin-top: 23px;
	width: 100%;
	height: 1200px;
	background: #cccccc 0% 0% no-repeat padding-box;
	border-radius: 15px;

	${(p) => p.theme.device.md} {
		/* width: 100%; */
		height: 366px;
		background: #bebebe 0% 0% no-repeat padding-box;
		border-radius: 10px;
	}
`;
