import { useCallback, useEffect, useState } from 'react';

import useCurations from 'hooks/useCurations';
import api from 'lib/api';

const useArtworksByCuration = (curationId) => {
	const curations = useCurations();
	const [curation, setCuration] = useState(null);
	const [artworks, setArtworks] = useState([]);

	const getCurationArtworks = useCallback(async () => {
		if (curations.length === 0 || !curationId) return;

		const {
			response: {
				data: { data },
			},
		} = await api.get(`/curations/${curationId}/artworks/`);
		setArtworks(data);
		setCuration(
			curations.find((curation) => curation.id === Number(curationId)),
		);
	}, [curationId, curations]);

	useEffect(() => {
		(async () => await getCurationArtworks())();
	}, [getCurationArtworks]);

	return { artworks, curation };
};
export default useArtworksByCuration;
