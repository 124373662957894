import styled from 'styled-components';
import NaverLogoSVG from 'assets/svg/naver';
import KaKaoLogoSVG from 'assets/svg/kakao';

const SocialLogin = () => {
	const handleNaverLogin = () => {
		window.location.href = `${process.env.REACT_APP_BASE_URL}/users/naver/login/`;
	};

	const handleKakaoLogin = () => {
		window.location.href = `${process.env.REACT_APP_BASE_URL}/users/kakao/login/`;
	};

	return (
		<Wrapper>
			<Button $color='#f7e600'>
				<KaKaoLogoSVG onClick={() => handleKakaoLogin()} />
			</Button>

			<Button $color='#09ce20'>
				<NaverLogoSVG onClick={() => handleNaverLogin()} />
			</Button>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	gap: 24px;

	${(props) => props.theme.device.md} {
		gap: 17px;
		padding-bottom: 128px;
	}
`;
const Button = styled.button`
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 78px;
	height: 78px;
	border-radius: 50px;
	background-color: ${(p) => p.$color};

	${(props) => props.theme.device.md} {
		width: 55px;
		height: 56px;
	}
`;

export default SocialLogin;
